import { GoAButton, GoAInputText, GoATable } from '@abgov/react-components';
import * as React from 'react';
import { FlightCrewEligibility, FlightExpenses } from '../../model/IFlightExpense';
import Select from "react-select";

interface IEditFlightCrewEligibilityProps {
  flightExpenses: FlightExpenses | undefined;
  onChange: (value: FlightExpenses) => void;
}

const providerOptions = [
  { label: 'GOA Provided', value: 'GOA Provided' },
  { label: 'Vendor Provided', value: 'Vendor Provided' },
  { label: 'Not Applicable', value: 'Not Applicable' },
];
const rolesOptions = [
  { label: 'Pilot', value: 'Pilot' },
];

const EditFlightCrewEligibility: React.FunctionComponent<IEditFlightCrewEligibilityProps> = ({ flightExpenses, onChange, ...props }) => {
  const [formValues, setFormValues] = React.useState(new FlightExpenses(flightExpenses));





  function onPropertyChange(idx: number, value: Partial<FlightCrewEligibility>) {
    let newValue = new FlightCrewEligibility(formValues.flightCrewEligibility[idx]);
 
    newValue = new FlightCrewEligibility({ ...newValue, ...value });
    let rows = [...formValues.flightCrewEligibility];
    rows[idx] = newValue;
    let newFlightExpense = new FlightExpenses({ ...formValues, flightCrewEligibility: rows });
    newFlightExpense.flightFuelUsage.updateTimestamp=null;
    setFormValues(newFlightExpense);
 
    //send on change to parent component "FlightReportSummary"
    onChange(newFlightExpense);
  }

  async function handleAddRow() {
    formValues.flightCrewEligibility.push(new FlightCrewEligibility());
    setFormValues(formValues);

    //send on change to parent component "FlightReportSummary"
    onChange(formValues);
  }


  return (
    <>
      <div className="row accordion flight-crew-eligibility">
        <div>
          <div>
            <h3 className="insideHeader expense-section-header">
              Flight Crew Eligibility
            </h3>
          </div>
          <div className="content">
            <div className="white-background paddingBottom20px">
              <GoATable width="100%">
                <thead>
                  <tr style={{ position: "sticky", top: 0 }}>
                    <th className="width15">Name</th>
                    <th className="width15">Role</th>
                    <th className="width15">Breakfast</th>
                    <th className="width15">Lunch</th>
                    <th className="width15">Dinner</th>
                    <th className="width10">Lodging</th>
                    <th className="width10">Vehicle</th>
                  </tr>
                </thead>

                <tbody>
                  {formValues.flightCrewEligibility?.map(
                    (record: FlightCrewEligibility, idx: number) => (
                      <tr key={idx}>
                        <td>
                          <GoAInputText
                            name="name"
                            value={record.name}
                            onChange={(name, value) => {
                              onPropertyChange(idx, { name: value });
                            }}
                          />
                        </td>
                        <td>
                          <Select
                            name="roles"
                            options={rolesOptions}
                            className="width100"
                            value={rolesOptions.find(t => t.value === record.role) || null}
                            onChange={(value) => {
                              onPropertyChange(idx, { role: value?.value });
                            }}
                          />

                        </td>
                        <td>
                          <Select
                            name="breakfast"
                            options={providerOptions}
                            className="width100"
                            value={providerOptions.find(t => t.value === record.breakfast) || null}
                            onChange={(value) => {
                              onPropertyChange(idx, { breakfast: value?.value });
                            }}
                          />

                        </td>
                        <td>
                          <Select
                            name="lunch"
                            options={providerOptions}
                            className="width100"
                            value={providerOptions.find(t => t.value === record.lunch) || null}
                            onChange={(value) => {
                              onPropertyChange(idx, { lunch: value?.value });
                            }}
                          />
                        </td>
                        <td>
                          <Select
                            name="dinner"
                            options={providerOptions}
                            className="width100"
                            value={providerOptions.find(t => t.value === record.dinner) || null}
                            onChange={(value) => {
                              onPropertyChange(idx, { dinner: value?.value });
                            }}
                          /></td>
                        <td>
                          <Select
                            name="lodging"
                            options={providerOptions}
                            className="width100"
                            value={providerOptions.find(t => t.value === record.lodging) || null}
                            onChange={(value) => {
                              onPropertyChange(idx, { lodging: value?.value });
                            }}
                          />
                        </td>
                        <td>
                          <Select
                            name="vehicle"
                            options={providerOptions}
                            className="width100"
                            value={providerOptions.find(t => t.value === record.vehicle) || null}
                            onChange={(value) => {
                              onPropertyChange(idx, { vehicle: value?.value });
                            }}
                          /></td>
                      </tr>
                    )
                  )
                  }
                  <tr>
                    <td>
                      <GoAButton type="secondary" onClick={() => handleAddRow()}>
                        Add New +
                      </GoAButton>
                    </td>
                  </tr>
                </tbody>

              </GoATable>

            </div>

          </div>
        </div>
      </div>
    </>
  );
};


export default EditFlightCrewEligibility;
