import { httpAviationReporting } from "../http-common";
import { IPaginationResult } from "../model/search/IPaginationResult";
import { ISearch } from "../model/search/ISearch";
import { FlyingHours, IFlyingHours } from "../model/IFlyingHours";

//Search
const getSearch = async (objISearch: ISearch,flightReportId:number) => {
    const params = JSON.stringify({
      search: objISearch.search,
      sortBy: objISearch.sortBy,
      sortOrder: objISearch.sortOrder,
      filterBy: {
        columnName: "",
        columnValue: ""
      },
      paginationInfo: {
        perPage: objISearch.pagination.perPage,
        page: objISearch.pagination.page,
      },
    });
  
    const data = await httpAviationReporting.post<IPaginationResult<IFlyingHours>>(
      `/flying-hours/get/${flightReportId}`,
      params,
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("api_token")}`,
        },
      }
    );
   
    return data;
  };

  const getById = async (id: string) => {
    const data = await httpAviationReporting.post<IFlyingHours>(
      `/flying-hours/get/${id}`,
      "",
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("api_token")}`,
        },
      }
    );
  
    return data;
  };

  const createUpdate = async (arrFlyingHours:Array<FlyingHours>, flightReportId:number) => {
    const params = JSON.stringify(arrFlyingHours);
    
    const data = await httpAviationReporting.put<Array<FlyingHours>>(
      `/flying-hours/create-update/${flightReportId}`,
      params,
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("api_token")}`,
        },
      }
    );
    return data;
  
  };

  // const create = async (arrFlyingHours:FlyingHours[]) => {
  //   const params = JSON.stringify(arrFlyingHours);
  
  //   const data = await httpAviationReporting.post<FlyingHours[]>(
  //     "/flying-hours/create",
  //     params,
  //     {
  //       headers: {
  //         Authorization: `Bearer ${sessionStorage.getItem("api_token")}`,
  //       },
  //     }
  //   );
  //   return data;
  
  // };
  
  export const FlyingHoursService = {
    getSearch,
    getById,
    createUpdate,
  };

