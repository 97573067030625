import { httpDomainService } from "../../http-common";
import { ICrewType } from "../../model/domainServices/ICrewType";
import { IPaginationResult } from "../../model/search/IPaginationResult";
import { ISearch } from "../../model/search/ISearch";

//Search
const getSearch = async (objISearch: ISearch) => {
    const params = JSON.stringify({
      search: objISearch.search,
      sortBy: objISearch.sortBy,
      sortOrder: objISearch.sortOrder,
      filterBy: {
        columnName: "",
        columnValue: ""
      },
      paginationInfo: {
        perPage: objISearch.pagination.perPage,
        page: objISearch.pagination.page,
      },
    });
  
    const data = await httpDomainService.post<IPaginationResult<ICrewType>>(
      "/crew_types/get",
      params,
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("domainService_token")}`,
        },
      }
    );
    
    return data;
  };

  const getById = async (id: string) => {
    const data = await httpDomainService.post<ICrewType>(
      `/crew_types/get/${id}`,
      "",
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("domainService_token")}`,
        },
      }
    );
    return data;
  };
  
  export const CrewTypeService = {
    getSearch,
    getById,
  };


export const getCrewType = (crewTypeStateResult:IPaginationResult<ICrewType>, crewTypeId:string) => {
  if(crewTypeStateResult != null)
  {
    const result = crewTypeStateResult.data.find(value => value.crewTypeId === crewTypeId);
    return result;
  }
  else{
    return undefined;
  }
}

  export const bindCrewTypeDropdown = (crewTypePaginationResult:IPaginationResult<ICrewType>): {label:string,value:string}[] => {
    const options = [];
    
    if(crewTypePaginationResult != null)
    {
      for (let i = 0; i < crewTypePaginationResult.data.length; i++) {
        let obj = {label: crewTypePaginationResult.data[i].code + " - " + crewTypePaginationResult.data[i].description, value: crewTypePaginationResult.data[i].crewTypeId};
        options.push(obj);
      }
    }

    return options;
  }