import {
  GoAAppHeader,
  GoADropdown,
  GoADropdownItem,
} from "@abgov/react-components";
import * as React from "react";
import { isValidURL } from "../utils/commonMethods";
import { useAuthUser } from "./Auth";

interface IAppHeaderProps {}

const AppHeader: React.FunctionComponent<IAppHeaderProps> = (props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [url, setUrl] = React.useState("/");

  const { isLoggedIn, username, logoutUrl } = useAuthUser();

  // React.useEffect(() => {
  //   if(sessionStorage.getItem("username") != null && sessionStorage.getItem("username") !== "")
  //   {
  //     //navigate("/dashboard");
  //   }
  //   else{
  //     //setUrl("/");
  //   }
  // }, [navigate]);

  function onDropdownChange(name: string, value: string | string[]) {
    console.log("onDropdownChange name", name);
    console.log("onDropdownChange value", value);
    if (isValidURL(value.toString())) {
      console.log("isValidURL", value);
      window.location.href = value.toString();
    }
  }

  return (
    <>
      <div>
        <GoAAppHeader url={url} heading="Aviation reporting">
          <div>
            <nav>
              {/* <NavLink to="/">Home</NavLink> */}
              {/* <NavLink to="/test">Test</NavLink> */}
              {/* <NavLink to="/domainTables">Domain Tables</NavLink>
            <NavLink to="/rateType">Rate type</NavLink> */}
            </nav>
            <div>
              {isLoggedIn ? (
                <GoADropdown
                  name="drpUserInfo"
                  value={username}
                  onChange={onDropdownChange}
                  leadingIcon="person-circle"
                >
                  <GoADropdownItem value={username} label={username} />
                  <GoADropdownItem
                    value={"https://apim-dev.lerusystems.com" + logoutUrl}
                    label="Logout"
                  />
                </GoADropdown>
              ) : (
                <></>
              )}
            </div>
          </div>
        </GoAAppHeader>
      </div>
    </>
  );
};

export default AppHeader;
