import { GoATable } from '@abgov/react-components';
import * as React from 'react';
import { IFlyingHours } from '../../model/IFlyingHours';
import { IPaginationResult } from '../../model/search/IPaginationResult';

interface IViewFlightCrewEligibilityProps {
  flightCrewEligibilityPaginationResult: IPaginationResult<IFlyingHours> |any| undefined;
}

const ViewFlightCrewEligibility: React.FunctionComponent<IViewFlightCrewEligibilityProps> = ({flightCrewEligibilityPaginationResult,...props}) => {
  return (
    <>
      <div className="row accordion flight-crew-eligibility">
        <div>
          <div>
            <h3 className="insideHeader expense-section-header">
              Flight Crew Eligibility
            </h3>
          </div>
          <div className="content">
            <div className="white-background paddingBottom20px">
              <GoATable width="100%">
                <thead>
                  <tr style={{ position: "sticky", top: 0 }}>
                    <th className="width15">Name</th>
                    <th className="width15">Role</th>
                    <th className="width15">Breakfast</th>
                    <th className="width15">Lunch</th>
                    <th className="width15">Dinner</th>
                    <th className="width10">Lodging</th>
                    <th className="width10">Vehicle</th>
                  </tr>
                </thead>

                { <tbody>
                  {flightCrewEligibilityPaginationResult &&
                  flightCrewEligibilityPaginationResult !== null ? (
                    flightCrewEligibilityPaginationResult.map(
                      (record: any, idx: number) => (
                        <tr key={idx}>
                          <td>
                            {record.name
                              }
                          </td>
                          <td>
                            {record.role}
                          </td>
                          <td>
                            {record.breakfast}
                          </td>
                          <td>
                            {record.lunch}
                          </td>
                          <td>{record.dinner}</td>
                          <td>
                            {record.lodging}
                          </td>
                          <td>{"$" + record.vehicle}</td>
                        </tr>
                      )
                    )
                  ) : (
                    <></>
                  )}
                </tbody> }
              </GoATable>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewFlightCrewEligibility;
