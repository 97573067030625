import moment from "moment";
import { IFilter } from "../model/search/IFilter";
import { IPagination } from "../model/search/IPagination";
import { ISearch } from "../model/search/ISearch";
import { CostingActivityService } from "../services/domainServices/CostingActivityService";
import { CrewTypeService } from "../services/domainServices/CrewTypeService";
import { FireNumberService } from "../services/domainServices/FireNumberService";
import { RateTypeService } from "../services/domainServices/RateTypeService";
import { RateUnitService } from "../services/domainServices/RateUnitService";

//Fire number
async function getFireNumbers(searchValue:string = "")
{
  let objIPagination: IPagination = {
    perPage: 20,
    page: 1,
  };

  let objISearch: ISearch = {
    search: searchValue,
    pagination: objIPagination,
  };

    var response = await FireNumberService.getSearch(objISearch);
    return response;
}

//Crew type
async function getCrewTypes(searchValue:string = "")
{
  let objIPagination: IPagination = {
    perPage: 25,
    page: 1,
  };

  let objIFilter: IFilter = {
    columnName: "",
    columnValue: "",
  };

  let objISearch: ISearch = {
    search: searchValue,
    sortBy: "",
    sortOrder: "",
    filterBy:objIFilter,
    pagination: objIPagination,
  };

  var response = await CrewTypeService.getSearch(objISearch);
  return response;
    
}

//Costing activity
async function getCostingActivities(searchValue:string = "FS"){
  let objIPagination: IPagination = {
    perPage: 20,
    page: 1,
  };

  let objISearch: ISearch = {
    search: searchValue,
    pagination: objIPagination,
  };

  var response = await CostingActivityService.getSearch(objISearch);
  return response;
}

//Rate type
async function getRateTypes(searchValue:string = "")
{
  let objIPagination: IPagination = {
    perPage: 20,
    page: 1,
  };

  let objIFilter: IFilter = {
    negotiated:true,
    columnName: "activeDate",
    columnValue: moment(new Date()).format("yyyy-MM-DD"),
  };

  let objISearch: ISearch = {
    search: searchValue,
    sortBy: "",
    sortOrder: "",
    filterBy:objIFilter,
    pagination: objIPagination,
  };

  var response = await RateTypeService.getSearch(objISearch);
  return response;
}

//Rate unit
async function getRateUnits(searchValue:string = "")
{
  let objIPagination: IPagination = {
    perPage: 20,
    page: 1,
  };

  let objIFilter: IFilter = {
    columnName: "activeDate",
    columnValue: moment(new Date()).format("yyyy-MM-DD"),
  };

  let objISearch: ISearch = {
    search: searchValue,
    sortBy: "",
    sortOrder: "",
    filterBy:objIFilter,
    pagination: objIPagination,
  };

  var response = await RateUnitService.getSearch(objISearch);
  return response;
}

export const FlyingDetailCRUD = {
    getFireNumbers,
    getCostingActivities,
    getRateTypes,
    getCrewTypes,
    getRateUnits,
  };