import { httpDomainService } from "../http-common";
import { IVendor } from "../model/IVendor";
import { IPaginationResult } from "../model/search/IPaginationResult";
import { ISearch } from "../model/search/ISearch";

//Search
const getSearch = async (objISearch: ISearch) => {
  const params = JSON.stringify({
    search: objISearch.search,
    sortBy: objISearch.sortBy,
    sortOrder: objISearch.sortOrder,
    filterBy: {
      columnName: "",
      columnValue: "",
      reportDateFrom: objISearch.filterBy?.reportDateFrom,
      reportDateTo: objISearch.filterBy?.reportDateTo,
    },
    paginationInfo: {
      perPage: objISearch.pagination.perPage,
      page: objISearch.pagination.page,
    },
  });

  const data = await httpDomainService.post<IPaginationResult<IVendor>>(
    "/vendor/get",
    params,
    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("domainService_token")}`,
      },
    }
  );

  return data;
};

const getById = async (id: any) => {
    const data = await httpDomainService.post<IVendor>(
      `/vendor/get/${id}`,
      "",
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("domainService_token")}`,
        },
      }
    );
    
    return data;
};

const getByStackholderId = async (stakeholderId: any) => {
  const data = await httpDomainService.post<IVendor>(
    `/vendor/getVendorByStakeholderId/${stakeholderId}`,
    "",
    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("domainService_token")}`,
      },
    }
  );
  
  return data;
};

export const VendorService = {
  getSearch,
  getById,
  getByStackholderId
};
