import { VendorService } from "../services/VendorService";

export const getVendor = async (vendorId: string) => {
    var vendorData;
      await VendorService.getById(vendorId)
        .then((response: any) => {
          //Get Vendor
          vendorData = response.data;
        })
        .catch((e: Error) => {
          console.log(e);
          vendorData = null;
        });
        
        return vendorData;
  }