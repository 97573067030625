import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useEffect, useState } from "react";
import { bffURL } from "../Keycloak/BackendForFrontend";

const claimsKeys = {
  claim: ["claims"],
};

const config = {
  headers: {
    "X-CSRF": "1",
  },
  withCredentials: true,
};

const fetchClaims = async () =>
  axios.get(`${bffURL}/bff/user`, config).then((res) => res.data);

function useClaims() {
  return useQuery(
    claimsKeys.claim,
    async () => {
      const delay = new Promise((resolve) => setTimeout(resolve, 550));
      return Promise.all([fetchClaims(), delay]).then(([claims]) => claims);
    },
    {
      retry: false,
    }
  );
}

function useAuthUser() {
  const { data: claims, isLoading } = useClaims();

  const logoutUrl = claims?.find(
    (claim: any) => claim.type === "bff:logout_url"
  ).value;
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const nameDict =
    claims?.find((claim: any) => claim.type === "name") ||
    claims?.find((claim: any) => claim.type === "sub");
  const username = nameDict?.value;
  const hasResourceRole = (resource: string, role: string): boolean => {
    if (!claims) return false;
    const resourceAccess = JSON.parse(
      claims?.find((claim: any) => claim.type === "resource_access").value
    );
    const roles = resourceAccess[resource]?.roles || [];
    return roles.indexOf(role) !== -1;
  };

  useEffect(() => {
    setIsLoggedIn(!!username);
  }, [username]);

  // extract the roles from the 'resource_access' claim
  let roles = [];
  const resRaw = claims?.find((claim: any) => claim.type === "resource_access");
  if (resRaw) {
    const resObj = JSON.parse(resRaw.value);
    const rolesObj: any = Object.values(resObj)[0]; // bet the first client object.. // TODO: I think we should use a client name instead of getting the first client blindly
    roles = rolesObj.roles;
  }

  return {
    username,
    logoutUrl,
    isLoading,
    isLoggedIn,
    roles,
    hasResourceRole,
  };
}

export { useAuthUser };
