import moment from "moment";

  //Date filter
  export const getDateWithMonthOffset = (offset: number) => {
    const d = new Date();
    d.setMonth(d.getMonth() + offset);
    return d;
  }

  export const addDays = (value: Date, offset: number) => {
    const d = value;
    d.setDate(d.getDate() + offset);

    return d;
  }

  export const minusDays = (value: Date, offset: number) => {
    const d = value;
    d.setDate(d.getDate() - offset);

    return d;
  }

  export const getTimeFormat = (value:string):string => {
      if(moment(value).isValid())
      {
        var time = moment(value).format("HH:mm");
        return time !== "00:00" ? time : "";
      }
      else{
        return value;
      }
  }