import { httpDomainServiceAuthentication } from "../http-common";

const getAuthenticate = async () => {
    const data = await httpDomainServiceAuthentication.post<string>(
      "/authentication/authenticate",
      {
        username: "andrew.mitchell",
        password: "password",
      }
    );
  
    return data;
  };
  
  export const DomainService = {
    getAuthenticate,
  };