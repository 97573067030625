import * as React from "react";
import { useParams } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import PageLoader from "../../components/PageLoader";
import { ICorporateRegion } from "../../model/ICorporateRegion";
import { FlightReport } from "../../model/IFlightReport";
import { IPaginationResult } from "../../model/search/IPaginationResult";
import { getCorporateRegion } from "../../services/CorporateRegionDomainService";
import { FlightReportService } from "../../services/FlightReportService";
import { getContractRegistration} from "../../services/HiringContractService";
import { VendorService } from "../../services/VendorService";
import { aircraftDetailsState, corporateRegionState } from "../../state/DomainService.state";
import { crewTypeState, rateUnitState } from "../../state/FlyingHours.state";
import { convertObjectFromOneSingleArrayValue } from "../../utils/commonMethods";
import { getTimeFormat } from "../../utils/dateHandler";
import FlightReportDetailHeader from "./FlightReportDetailHeader";
import FlightReportSummary from "./FlightReportSummary";
import FlightReportTitle from "./FlightReportTitle";
import { FlightReportCRUD } from '../../operations/FlightReportCRUD';

interface IFlightReportDetailProps {
  corporateRegions?:IPaginationResult<ICorporateRegion> | undefined
}

const FlightReportDetail : React.FunctionComponent<IFlightReportDetailProps> = ({corporateRegions, ...props}) => {

  const { id } = useParams();

  const [flightReport, setFlightReport] = React.useState(new FlightReport());
  //Loader
  const [isLoading, setIsLoading] = React.useState(false);

  //Loader
  const [isEditing, setIsEditing] = React.useState(false);


  const corporateRegionPaginationResult = useRecoilValue(corporateRegionState);
  const setAircraftDetails = useSetRecoilState(aircraftDetailsState);
  //const setVendorDetails = useSetRecoilState(vendorDetailsState);

  const crewTypeStateResult = useRecoilValue(crewTypeState);
  const rateUnitStateResult = useRecoilValue(rateUnitState);
  
  React.useEffect(() => {
    if(id === "new")
    {
      setFlightReport(new FlightReport());
      setIsEditing(true);
    }
    else if (id) {
      getFlightReport(id);
      setIsEditing(false);
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const getFlightReport = async (flightReportId: string) => {
    setIsLoading(true);

      await FlightReportService.getById(flightReportId)
        .then(async (response: any) => {
          //get aircraft registration
          var aircraftDetails = await FlightReportCRUD.getHiringAircraft(response.data.data.flightReportDate);
          
          if(aircraftDetails)
            setAircraftDetails(aircraftDetails);

          // //Get vendor
          var vendorObj = await getVendor(response.data.data.vendorId);
          const vendorResult = convertObjectFromOneSingleArrayValue(vendorObj as unknown as []);

          //Get corporate region
          if(response.data.data.corporateRegionId)
            var corporateRegion = getCorporateRegion(corporateRegionPaginationResult,response.data.data.corporateRegionId);

          //Get contract registration
          if(response.data.data.contractRegistrationId)
            var contractRegistrationObj = getContractRegistration(aircraftDetails,response.data.data.contractRegistrationId)
          
          //Get flying registration
          if(response.data.data.flyingRegistrationId)
            var flyingRegistrationObj = getContractRegistration(aircraftDetails,response.data.data.flyingRegistrationId)

          //get TimeUp & TimeDown
          response.data.data.timeUpDisplay = getTimeFormat(response.data.data?.timeUp);
          response.data.data.timeDownDisplay = getTimeFormat(response.data.data?.timeDown);
          //Set flight report with corporate region
          setFlightReport(new FlightReport({...response.data.data,corporateRegion:corporateRegion, vendor: vendorResult, 
                                          contractRegistration:contractRegistrationObj,flyingRegistration:flyingRegistrationObj}));

          if(response.data.data.status === "Signed off")
            setIsEditing(true);
          
          setIsLoading(false);
        })
        .catch((e: Error) => {
          console.log(e);
          setIsLoading(false);
        });
  
  };

 async function getVendor(vendorId: string) {
    var response = await VendorService.getById(vendorId);
    return response.data;
  }

  function onSaveAndUpdate(value: FlightReport) {
    if(value != null && value !== undefined)
    {
      setFlightReport((p)=> {return new FlightReport(value)});
      setIsEditing(true);
    }
  }

  function onEdit(value:boolean)
  {
    if(flightReport.status !== "Signed off")
    {
      setIsEditing(value);
    }
    else{
      setIsEditing(true);
    }
  }

  return (
    <>
    
      <div >
        <PageLoader visible={isLoading} />

        <div className="white-background contentPadding detailPadding ">
          <div>
              <FlightReportTitle flightReport={flightReport} paramId={id} onChange={onSaveAndUpdate} />
          </div>

          <div className="statusHeader">
            <FlightReportDetailHeader flightReport={flightReport} onUpdate={onSaveAndUpdate} isEditing={isEditing} />
          </div>
        </div>

        <div>
          <div className="sub-section">
            {!isLoading &&
              <FlightReportSummary flightReport={flightReport} crewTypeStateResult={crewTypeStateResult} rateUnitStateResult={rateUnitStateResult}
                        onUpdate={onSaveAndUpdate} onEdit={onEdit} />}
          </div>
        </div>
      </div>
    </>
  );
};

export default FlightReportDetail;
