import { httpAviationReporting} from "../http-common";
import { FlightExpenses } from "../model/IFlightExpense";

const getById = async (id: any) => {
  
    const data = await httpAviationReporting.post<any>(
      `/expense/get/${id}`,
      "",
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("api_token")}`,
        },
      }
    );
    
    return data;
};

const createUpdate = async (flightExpenses:FlightExpenses, flightReportId:number) => {
  const params = JSON.stringify(flightExpenses);
  console.log(params);
  const data = await httpAviationReporting.put<FlightExpenses>(
    `/expense/create-update/${flightReportId}`,
    params,
    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("api_token")}`,
      },
    }
  );
  return data;

};

export const ExpenseService = {
  getById,
  createUpdate,
};