import { httpDomainService } from "../../http-common";
import { IRateUnit } from "../../model/domainServices/IRateUnit";
import { IPaginationResult } from "../../model/search/IPaginationResult";
import { ISearch } from "../../model/search/ISearch";

//Search
const getSearch = async (objISearch: ISearch) => {
    const params = JSON.stringify({
      search: objISearch.search,
      sortBy: objISearch.sortBy,
      sortOrder: objISearch.sortOrder,
      filterBy: {
        columnName: "",
        columnValue: ""
      },
      paginationInfo: {
        perPage: objISearch.pagination.perPage,
        page: objISearch.pagination.page,
      },
    });
  
    const data = await httpDomainService.post<IPaginationResult<IRateUnit>>(
      "/rate_units/get",
      params,
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("domainService_token")}`,
        },
      }
    );
    
    return data;
  };

  const getById = async (id: string) => {
    const data = await httpDomainService.post<IRateUnit>(
      `/rate_units/get/${id}`,
      "",
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("domainService_token")}`,
        },
      }
    );
  
    return data;
  };

  const getByOracleId = async (oracleId: number) => {
    const data = await httpDomainService.post<IRateUnit>(
      `/rate_units/get/${oracleId}`,
      "",
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("domainService_token")}`,
        },
      }
    );
  
    return data;
  };
  
  export const RateUnitService = {
    getSearch,
    getById,
    getByOracleId,
  };

  export const getRateUnit = (rateUnitStateResult:IPaginationResult<IRateUnit>, rateUnitId:string) => {
    if(rateUnitStateResult != null)
    {
      const result = rateUnitStateResult.data.find(value => value.rateUnitId === rateUnitId);
      return result;
    }
    else{
      return undefined;
    }
  }
  
  export const bindRateUnitDropdown = (rateUnitPaginationResult:IPaginationResult<IRateUnit>): {label:string,value:string}[] => {
    const options = [];
    
    if(rateUnitPaginationResult != null)
    {
      for (let i = 0; i < rateUnitPaginationResult.data.length; i++) {
        let obj = {label: rateUnitPaginationResult.data[i].type, value: rateUnitPaginationResult.data[i].rateUnitId};
        options.push(obj);
      }
    }

    return options;
  }