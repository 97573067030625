import * as React from 'react';
import ViewDoubleCrew from './ViewDoubleCrew';
import ViewExpenseDetails from './ViewExpenseDetails';
import ViewFlightCrewEligibility from './ViewFlightCrewEligibility';
import ViewFuel from './ViewFuel';
import EditFuel from './EditFuel';
import EditDoubleCrew from './EditDoubleCrew';
import EditFlightCrewEligibility from './EditFlightCrewEligibility';
import EditExpenseDetails from './EditExpenseDetails';
import { FlightExpenses } from '../../model/IFlightExpense';
import { IContractRates } from '../../model/HiringContract/IContractRates';
import { IPaginationResult } from '../../model/search/IPaginationResult';

interface IExpenseSummaryProps {
  flightExpenses: FlightExpenses | undefined;
  contractRatesPaginationResult:IPaginationResult<IContractRates> | undefined;
  fireNumberOptions:{label:string,value:number}[];
  crewTypeOptions:{label:string,value:string}[];
  costingActivityOptions:{label:string,code:string,value:string}[];
  rateTypeOptions:{label:string,value:string}[];
  rateUnitOptions:{label:string,value:string}[];
  onChange: (value: FlightExpenses) => void;
  isEdit:boolean;
}

const ExpenseSummary: React.FunctionComponent<IExpenseSummaryProps> = ({isEdit,contractRatesPaginationResult,
  fireNumberOptions,crewTypeOptions,costingActivityOptions,rateTypeOptions,rateUnitOptions,onChange,...props
}) => {
  const { flightExpenses } = props;
  const [, setUpdateFlightExpenses] = React.useState(flightExpenses);
  //Loader

  function onFormValuesChange(value: FlightExpenses) {
    if (value != null && value !== undefined) {
      // if (value.flightReportDate && value.corporateRegionId) setIsUpdate(true);
      // else setIsUpdate(false);

      setUpdateFlightExpenses(value);
      onChange(value);
    }
  }




  return (
    <>
      <div className="expense-section white-background">
        
        {isEdit ? (

          <div className="row">
            <div className="width100">
              <div className="column width50">
                <EditFuel onChange={onFormValuesChange} flightExpenses={flightExpenses} />
              </div>
              <div className="right width50 paddingLeft20px">
                <EditDoubleCrew onChange={onFormValuesChange} flightExpenses={flightExpenses} />
              </div>
            </div>
          </div>
        ) : (
          <div className="row">
            <div className="width100">
              <div className="column width50">

                <ViewFuel fuelData={flightExpenses?.flightFuelUsage} />
              </div>
              <div className="right width50 paddingLeft20px">

                <ViewDoubleCrew doubleCrewData={flightExpenses?.flightFuelUsage} />
              </div>
            </div>
          </div>
        )}

        {isEdit ? (

          <EditFlightCrewEligibility
            onChange={onFormValuesChange}
            flightExpenses={flightExpenses}
          />
        ) : (
          <div className="paddingTop20px">
            <ViewFlightCrewEligibility
              flightCrewEligibilityPaginationResult={flightExpenses?.flightCrewEligibility}
            />
          </div>
        )}

        {isEdit ? (
          <EditExpenseDetails contractRatesPaginationResult={contractRatesPaginationResult}
          fireNumberOptions={fireNumberOptions} crewTypeOptions={crewTypeOptions} costingActivityOptions={costingActivityOptions}
          rateTypeOptions={rateTypeOptions} rateUnitOptions={rateUnitOptions} onChange={onFormValuesChange}
          flightExpenses={flightExpenses} />
        ) : (

          <div className="paddingTop20px">
            <ViewExpenseDetails expenseDetails={flightExpenses?.expenseDetails} />
          </div>
        )}



      </div>
    </>
  );
};

export default ExpenseSummary;
