import {
  GoAButton,
  GoAFormItem,
  GoAInput,
  GoAInputDate,
} from "@abgov/react-components";
import moment from "moment";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import PageHeader from "../../components/PageHeader";
import PageLoader from "../../components/PageLoader";
import { ICorporateRegion, IForestArea } from "../../model/ICorporateRegion";
import { IFlightReport } from "../../model/IFlightReport";
import { IFilter } from "../../model/search/IFilter";
import { IPagination } from "../../model/search/IPagination";
import { IPaginationResult } from "../../model/search/IPaginationResult";
import { ISearch } from "../../model/search/ISearch";
import { bindMutltiSelectDropdown, CorporateRegionDomainService } from "../../services/CorporateRegionDomainService";
import { FlightReportService } from "../../services/FlightReportService";
import { addDays, getDateWithMonthOffset } from "../../utils/dateHandler";
import { MultiSelect } from "react-multi-select-component";
import { useSetRecoilState } from "recoil";
import { corporateRegionState } from "../../state/DomainService.state";
import FlyingHoursState from "../../components/stateComponent/FlyingHoursState";
import { Tab, Tabs } from "../../components/Tabs";
import FlightReportRequireActionList from "./FlightReportRequireActionList";
import FlightReportAll from "./FlightReportAll";
import { useAuthUser } from "../../components/Auth";

interface IFlightReportListProps {}

const FlightReportList: React.FunctionComponent<IFlightReportListProps> = (props) => {
  // Navigation
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const navigate = useNavigate();
  
  const [paginationResults, setPaginationResult] =
    React.useState<IPaginationResult<IFlightReport>>();

  //#region "Corporate region"
  const [corporateRegionPaginationResult, setCorporateRegionPaginationResult] = React.useState<IPaginationResult<ICorporateRegion>>();
  //const [domainServiceResult, setDomainServiceResult] = React.useState<IDomainService>();
  
  //Loader
  const [loading, setIsLoading] = React.useState(true);
  const [searchValue, setSearchValue] = React.useState("");
  
  //Pagination
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [pageUsers, setPageFlightReports] = React.useState<any>([]);
  // page number
  const [page, setPage] = React.useState(1);
  //count per page
  const [perPage, setPerPage] = React.useState(10);
  const [previousSelectedPerPage, setPreviousSelectedPerPage] = React.useState(10);

    //Sorting
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [sortCol, setSortCol] = React.useState("flightReportDate");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [sortDir, setSortDir] = React.useState(-1);
  const [isSorting, setIsSorting] = React.useState(false);

  //const sortPaginationResults = React.useMemo(() => sortedPaginationResults(), [sortedPaginationResults]);

  //Filter date
  const [startDate, setStartDate] = React.useState("");
  const [startDateError, setStartDateError] = React.useState(false);
  const [endDate, setEndDate] = React.useState("");
  const [endDateError, setEndDateError] = React.useState(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [maxDate, setMaxDate] = React.useState(getDateWithMonthOffset(1));

  const [minEndDate, setMinEndDate] = React.useState(new Date(1950, 1, 1));
  const [showEndDate, setShowEndDate] = React.useState(true);

  const[searchInputKeyPress,setSearchInputKeyPress] = React.useState("");

  const [forestAreaSelected, setForestAreaSelected] = React.useState([]);
  const [forestAreaOptions, setForestAreaOptions] = React.useState([]);
  //Recoil state
  const setcorporateRegionState = useSetRecoilState(corporateRegionState);

  const { hasResourceRole } = useAuthUser();

    //onKeyPress handler function
    const keyPressHandler = (event: React.KeyboardEvent<HTMLInputElement> | any) => {
      //Do something you like with "event"
        setSearchInputKeyPress("");
        setSearchInputKeyPress(event.key);
    };

    React.useEffect(()=>{

      const element = document.getElementsByName('flightReportSearch');
      element[0].addEventListener('keypress', keyPressHandler);
  
      if(sessionStorage.getItem("api_token") == null || sessionStorage.getItem("api_token") === undefined || sessionStorage.getItem("api_token") === ""
        || sessionStorage.getItem("domainService_token") == null || sessionStorage.getItem("domainService_token") === undefined || sessionStorage.getItem("domainService_token") === "")
      {
        setTimeout(()=>{getCorporateRegions();},200);
        setTimeout(()=>{onRefreshFlightReport();},200);
      }
      else{
        getCorporateRegions();
        onRefreshFlightReport();
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])


  React.useEffect(() => {
    onRefreshFlightReport();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, perPage, endDate, forestAreaSelected, searchValue, sortCol, sortDir]);


  function onRefreshFlightReport(){
    getFlightReports();
    setPageFlightReports(paginationResults?.data.slice(0, perPage));
  };

  //Get flight reports data
  const getFlightReports = async () => {
    setIsLoading(true);

    let strSearchValue = "";
    let sortOrder = "";
    //let sortOrder = sortDir === -1 ? "ASC" : "DESC";
    let startDt = startDate === null || startDate === "" ? null : moment(startDate).format("yyyy-MM-DD");
    let endDt = endDate === null || startDate === "" ? null : moment(endDate).format("yyyy-MM-DD");
    let objForestArea: IForestArea = {
      corporateRegionId: forestAreaSelected != null || forestAreaSelected !== undefined ? forestAreaSelected.map((forestArea)=>{ return forestArea["value"] as string}) : []
      // corporateRegionId:[
      //   "6d0136ad-f1f8-454c-80de-2b4042010e72",
      //   "929ab41f-0e54-480b-aebf-7b598fa5cc2e"
      // ]
    };


    let objIPagination: IPagination = {
      perPage: perPage,
      page: page,
    };

    let objIFilter: IFilter = {
      columnName: "",
      columnValue: "",
      reportDateFrom:startDt,
      reportDateTo:endDt,
      corporateRegion:objForestArea
    };

    let objISearch: ISearch = {
      search: strSearchValue,
      sortBy: sortCol,
      sortOrder: sortOrder,
      filterBy: objIFilter,
      pagination: objIPagination,
    };

    setTimeout(() => {
      FlightReportService.getSearch(objISearch)
        .then((response: any) => {
          setPaginationResult(p => {
            return sortingData(response.data);
            });

          setIsLoading(false);
        })
        .catch((e: Error) => {
          setIsLoading(false);
          sessionStorage.setItem("api_token", "");
          navigate("/");
        });
      }, 200);
  }

  function sortingData(paginationResult: IPaginationResult<IFlightReport>) {
    const _flightReports = [...(paginationResult.data || [])];
    _flightReports.sort((a, b) => {
      return (
        (a[sortCol as keyof typeof paginationResults] >
        b[sortCol as keyof typeof paginationResults]
          ? -1
          : 1) * sortDir
      );
    });

    paginationResult.data = _flightReports;
    //Only for sorting applied reset pagination
    if(isSorting)
    {
      setPerPage(previousSelectedPerPage | 10);
      setPage(1);
      setPageFlightReports(paginationResults?.data.slice(0, perPage));

      //Reset value
      setIsSorting(false);
      setPreviousSelectedPerPage(perPage);
    }
    return paginationResult;
  }

  function searchFlightReports(searchParamValue: string) {
    
    if(searchInputKeyPress === "Enter")
    {
      return;
    }

    if (searchParamValue.length >= 3 && searchValue !== searchParamValue) {
      setIsLoading(true);
      setPage(1);
      setSearchValue(searchParamValue);
     } 
    else if (searchParamValue.length === 0 && searchValue !== "") {
      setIsLoading(true);
      setSearchValue("");
    }
  }

  //Corporate region
  function getCorporateRegions()
  {
    let objIPagination: IPagination = {
      perPage: 20,
      page: 1,
    };

    let objIFilter: IFilter = {
      columnName: "CRT_ID",
      columnValue: "4"
    };

    let objISearch: ISearch = {
      search: "",
      filterBy: objIFilter,
      pagination: objIPagination,
    };

      CorporateRegionDomainService.getSearch(objISearch)
        .then((response: any) => {
          //set corporate region recoil state 
          setcorporateRegionState(response.data);
          setCorporateRegionPaginationResult(response.data);
          const forestAreaResult = bindMutltiSelectDropdown(response.data) as [];
          setForestAreaOptions(forestAreaResult);
        })
        .catch((e: Error) => {
          console.log("Error",e);
        });
      
  }

  //#endregion
function onClickFlightReport(flightReportId?:number)
{
    if(flightReportId)
    {
    //navigate(`/flightReportDetail/${flightReportId}`);
    navigate(`/flightReportDetail/${flightReportId}`,{state:corporateRegionPaginationResult});
    }
    else
    {
      navigate(`/flightReportDetail/new`,{state:corporateRegionPaginationResult});
    }
}

  return (
    <>
      <FlyingHoursState />

      <PageLoader visible={loading} />

      <div className="contentPadding">
      <div className="left width100">
        <div className="left width-auto">
          <PageHeader title="Flight Report Dashboard" />
        </div>
        <div className="right width-auto header-button">
        {hasResourceRole('FlightReports', 'P_FLRPT_FLIGHTRPT_INSERT') ?
          <GoAButton type="primary" onClick={() => onClickFlightReport()}>
            Create new +
          </GoAButton>
          : <></>}
        </div>
      </div>

      <div className="header-content">
        <div className="filter-content contentInline ">
              <div className="left width25 paddingRight20px">
              <GoAFormItem label="Forest Area">
                <MultiSelect
                      options={forestAreaOptions}
                      value={forestAreaSelected}
                      onChange={(param: any)=>{setPage(1);setForestAreaSelected(param)}}
                      labelledBy="--Select--"
                    />
              </GoAFormItem>
            </div>
           <div className="left width25">
              <div className="left width50">
                <GoAFormItem label="Start Date">
                  <GoAInputDate
                    name="startDate"
                    placeholder="YYYY-MM-DD"
                    value={startDate}
                    min={new Date(1950, 1, 1)}
                    max={maxDate}
                    width="100%"
                    error={startDateError}
                    onChange={(name, value) => {
                      let dtResult = moment(value).format("yyyy-MM-DD");
                      let splitDate = dtResult.split('-');
                      
                      if(dtResult !== "Invalid date")
                      {
                        if(parseInt(splitDate[0]) < 1950)
                        {
                          setStartDateError(true);
                          endDate !== "" ? setEndDate(endDate) : setEndDate("");
                          setShowEndDate(true);
                        }
                        else if(parseInt(splitDate[2]) > 31)
                        {
                          setStartDateError(true);
                          endDate !== "" ? setEndDate(endDate) : setEndDate("");
                          setShowEndDate(true);
                        }
                        else
                        {
                          setStartDateError(false);
                          setStartDate(dtResult as string);
                          let dtObj = addDays(new Date(value), 1);
                          setMinEndDate(dtObj);
                          setShowEndDate(false);
                        }
                      }
                      else{
                        setStartDateError(true);
                        endDate !== "" ? setEndDate(endDate) : setEndDate("");
                        setShowEndDate(true);
                      }
                    }}
                  />
                </GoAFormItem>
              </div>
              <div className="left width50 paddingLeft20px">
                <GoAFormItem label="End Date">
                  <GoAInputDate
                    name="filterEndDate"
                    placeholder="YYYY-MM-DD"
                    value={endDate}
                    min={minEndDate}
                    max={maxDate}
                    width="100%"
                    error={endDateError}
                    disabled={showEndDate}
                    onChange={(name, value) => {
                      let dtResult = moment(value).format("yyyy-MM-DD");
                      let splitDate = dtResult.split('-');

                      if(dtResult !== "Invalid date")
                      {
                        if(parseInt(splitDate[0]) < 1950)
                        {
                          setEndDateError(true);
                        }
                        else if(parseInt(splitDate[2]) > 31)
                        {
                          setEndDateError(true);
                        }
                        else if(value < minEndDate )
                        {
                          setEndDateError(true);
                        }
                        else
                        {
                          setEndDateError(false);
                          value >= minEndDate ? setEndDate(value as string) : setEndDateError(true);
                        }
                      }
                      else{
                        setEndDateError(true);
                      }
                    }}
                  />
                </GoAFormItem>
              </div>
              </div>

              <div className="right width50 paddingLeft20px">
                <GoAFormItem label="Search">
                  <GoAInput
                    trailingIcon="search"
                    name="flightReportSearch"
                    placeholder="Search by aircraft registration, vendor, AO02 # or flight report #"
                    onChange={(name, value) => searchFlightReports(value)}
                    onTrailingIconClick={() => searchFlightReports(searchValue)}
                    value={""}
                    width="100%"
                  />
                </GoAFormItem>
              </div>
        </div>
      </div>

      <Tabs activeIndex={1} data-testid="approvals-tabs">
        <Tab label="Requires Action" data-testid="approvals-required-tab">
          <FlightReportRequireActionList flightReportResults={paginationResults}/>
        </Tab>
        <Tab label="All Reports" data-testid="approvals-need-attentions-tab">
          <FlightReportAll flightReportResults={paginationResults} forestAreaSelected={forestAreaSelected} startDate={startDate} endDate={endDate} searchValue={searchValue} />
        </Tab>
        
      </Tabs>

      
      </div>

      
    </>
  );
};

export default FlightReportList;
