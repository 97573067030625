import { GoABadge, GoAButton, GoAButtonGroup, GoAFormItem, GoAModal } from '@abgov/react-components';
import * as React from 'react';
import { useAuthUser } from '../../components/Auth';
import PageLoader from '../../components/PageLoader';
import FlightReportStatus from '../../model/enum/FlightReportStatus';
import { FlightReport, IFlightReport } from '../../model/IFlightReport';
import { FlightReportCRUD } from '../../operations/FlightReportCRUD';

interface IFlightReportDetailHeaderProps {
    flightReport: Partial<IFlightReport> | undefined
    isEditing?:boolean;
    onUpdate: (value: FlightReport) => void;
}

const FlightReportDetailHeader: React.FunctionComponent<IFlightReportDetailHeaderProps> = ({flightReport,isEditing,onUpdate,...props}) => {
  //Loader
  const [loading, setIsLoading] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);

  const { hasResourceRole } = useAuthUser();

  async function onSignedOff()
  {
    setShowModal(true);
  }

  async function onCancel() {
    setIsLoading(true);
    setShowModal(false);

    setIsLoading(false);
  }

  //On signed off yes submit button
  async function onSubmit() {
    setIsLoading(true);

    let newValue = new FlightReport();
    newValue =  new FlightReport({...flightReport, isFlagged:false, status:"Signed off"});

    try{
      //Update flag status
      await FlightReportCRUD.createAndUpdate(newValue);

      //pass update value to parent to FlightReportDetail
      onUpdate(newValue);

      setShowModal(false);
      
      setIsLoading(false);
    }
    catch(e:any)
    {
      console.log(e);
      setShowModal(false);
      setIsLoading(false);
    }
  }
  
  return (
    <>
      <PageLoader visible={loading} />

      <div className="form-row">
        <div className="col-50">
          <div className="contentInline detailHeader">
            <div className="left paddingLeft0px">
              <GoAFormItem label="Report Status">
                <label>{flightReport?.status === FlightReportStatus.returned ? 
                            <GoABadge type="important" content="Returned" />
                          : flightReport?.status === FlightReportStatus.signedOff ? 
                          <GoABadge type="success" content="Signed off" /> : <GoABadge type="information" content="In progress" /> }</label>
              </GoAFormItem>
            </div>
            <div className="left">
              <GoAFormItem label="Created by">
                <label>{flightReport?.createUserId}</label>
              </GoAFormItem>
            </div>
            <div className="left">
              <GoAFormItem label="Created on">
                <label>{flightReport?.createTimestamp}</label>
              </GoAFormItem>
            </div>
            <div className="left">
              <GoAFormItem label="Last Updated by">
                <label>{flightReport?.updateUserId}</label>
              </GoAFormItem>
            </div>
            <div className="left ">
              <GoAFormItem label="Last Updated on">
                <label>{flightReport?.updateTimestamp}</label>
              </GoAFormItem>
            </div>
          </div>
        </div>
        <div className="col-50">
          <div className="right">
          {hasResourceRole('FlightReports', 'FlightReportSignedOff') ?
            <GoAButton
              size="compact"
              type="primary"
              disabled={isEditing}
              onClick={() => onSignedOff()}
            >
              Sign off
            </GoAButton>
            : <></>}
          </div>
        </div>
      </div>

      <GoAModal
        heading=""
        open={showModal}
        actions={
          <GoAButtonGroup alignment="end">
            <GoAButton size="compact" type="secondary" onClick={() => onCancel()}>
              No, cancel
            </GoAButton>
            <GoAButton size="compact" type="primary" onClick={() => onSubmit()}>
              Yes, I do
            </GoAButton>
          </GoAButtonGroup>
        }
      >
        <p>Are you sure you want to sign off on this report?</p>
      </GoAModal>
    </>
  );
};

export default FlightReportDetailHeader;
