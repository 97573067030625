import { GoAFormItem, GoAGrid } from '@abgov/react-components';
import moment from 'moment';
import * as React from 'react';
import { FlightReport} from '../../model/IFlightReport';

interface IViewFlightReportSummaryProps {
    flightReport:FlightReport,
    visible?:boolean
}

const ViewFlightReportSummary: React.FunctionComponent<IViewFlightReportSummaryProps> = ({flightReport,visible,...props}) => {
  return(
    <>
        <div className="row accordion">
              <div className="column firstSection">
                <div>
                  <h3 className="insideHeader">Flight Time & Location</h3>
                </div>
                <GoAGrid minChildWidth="30ch" gap="m">
                  <div>
                    <GoAFormItem label="Date">
                      <label>
                        {moment(flightReport?.flightReportDate).format(
                          "yyyy-MM-DD"
                        )}
                      </label>
                    </GoAFormItem>
                  </div>
                  <div>
                    <GoAFormItem label="Time Up / Down">
                      <label>{flightReport?.timeUpDisplay && flightReport?.timeDownDisplay ? `${flightReport?.timeUpDisplay} - ${flightReport?.timeDownDisplay}` : "" }</label>
                    </GoAFormItem>
                  </div>
                  
                  <div>
                    <GoAFormItem label="Meter Start / Finish">
                      <label>{flightReport?.meterStart && flightReport?.meterFinish ? `${flightReport?.meterStart} - ${flightReport?.meterFinish}` : ""}</label>
                    </GoAFormItem>
                  </div>
                  <div>
                    <GoAFormItem label="Meter Difference">
                      <label>{flightReport?.meterStart && flightReport?.meterFinish ? (flightReport?.meterFinish - flightReport?.meterStart).toFixed(2) : ""}</label>
                    </GoAFormItem>
                  </div>

                  <div>
                    <GoAFormItem label="Forest Area">
                      <label>{flightReport?.corporateRegion ? flightReport?.corporateRegion.name : ""}</label>
                    </GoAFormItem>
                  </div>
                  <div>
                    <GoAFormItem label="Point of Hire">
                      <label>{flightReport?.contractRegistration?.pointOfHire}</label>
                    </GoAFormItem>
                  </div>

                  <div>
                    <GoAFormItem label="Point of Departure">
                      <label>{flightReport?.pointOfDeparture}</label>
                    </GoAFormItem>
                  </div>
                  

                  <div>
                    <GoAFormItem label="Down For Night Location">
                      <label>{flightReport?.downForNightLocation}</label>
                    </GoAFormItem>
                  </div>
                  <div>
                    <GoAFormItem label="AO02 Number">
                      <label>{flightReport?.ao02Number}</label>
                    </GoAFormItem>
                  </div>
                </GoAGrid>
              </div>

              <div className="column secondSection">
                <div>
                  <h3 className="insideHeader">Vendor & Aircraft</h3>
                </div>
                <GoAGrid minChildWidth="30ch" gap="m">
                  <div>
                    <GoAFormItem label="Contract Registration">
                      <label>{flightReport?.contractRegistration?.aircraftRegistration}</label>
                    </GoAFormItem>
                  </div>
                  <div>
                    <GoAFormItem label="Flying Registration">
                      <label>{flightReport?.flyingRegistration?.aircraftRegistration}</label>
                    </GoAFormItem>
                  </div>

                  <div className='width100 col-md-6'>
                    <GoAFormItem label="Vendor">
                      <label>{flightReport?.vendor?.name}</label>
                    </GoAFormItem>
                  </div>
                  <div>
                  </div>

                  <div>
                    <GoAFormItem label="Aircraft Type">
                      <label>{flightReport?.contractRegistration?.atType}</label>
                    </GoAFormItem>
                  </div>

                  <div>
                    <GoAFormItem label="Contract Number">
                      <label>{flightReport?.contractRegistration?.contractNumber}</label>
                    </GoAFormItem>
                  </div>

                  <div>
                    <GoAFormItem label="Hire Start Date">
                      <label>{moment(flightReport?.contractRegistration?.effectiveDate).format("yyyy-MM-DD")}</label>
                    </GoAFormItem>
                  </div>
                  <div>
                    <GoAFormItem label="Hire End Date">
                      <label>{moment(flightReport?.contractRegistration?.terminationDate).format("yyyy-MM-DD")}</label>
                    </GoAFormItem>
                  </div>
                </GoAGrid>
                <GoAGrid minChildWidth="30ch" gap="m">
                  <div>
                    <GoAFormItem label="Pilot remarks">
                      <label>{flightReport?.remark}</label>
                    </GoAFormItem>
                  </div>
                </GoAGrid>
              </div>

              <div className="column thirdSection">
                <div>
                  <h3 className="insideHeader">Finance</h3>
                </div>
                <GoAGrid minChildWidth="30ch" gap="m">
                  <div>
                    <GoAFormItem label="Daily Basing">
                      <label>{flightReport?.dailyBasingIndicator === true ? "Yes" : "No"}</label>
                    </GoAFormItem>
                  </div>
                  <div>
                    <GoAFormItem label="Unused Minimums">
                      <label>{flightReport?.unusedMinsAppliedInt  === true ? "Yes" : "No"}</label>
                    </GoAFormItem>
                  </div>
                  <div>
                    <GoAFormItem label="Double Flight Crew">
                      <label>{flightReport?.doubleCrewInd  === true ? "Yes" : "No"}</label>
                    </GoAFormItem>
                  </div>
                  <div>
                    <GoAFormItem label="No Fly Day">
                    <label>{flightReport?.noFlyDay  === true ? "Yes" : "No"}</label>
                    </GoAFormItem>
                  </div>
                </GoAGrid>
              </div>
            </div>

    </>
  );
};

export default ViewFlightReportSummary;
