import { GoAFormItem } from '@abgov/react-components';
import * as React from 'react';

interface IViewFuelProps {
  fuelData:any;
}

const ViewFuel: React.FunctionComponent<IViewFuelProps> = ({ fuelData, ...props }) => {
  return (
    <>
      <div className="row accordion fuel">
        <div>
          <div>
            <h3 className="insideHeader expense-section-header">Fuel</h3>
          </div>
          <div className="content">
            <div className='width50 col-md-6'>
              <GoAFormItem label="Provider">
                <label>{fuelData?.provider}</label>
              </GoAFormItem>
            </div>
            <div className='width50 col-md-6'>
              <GoAFormItem label="Litres">
                <label>{fuelData?.litres}</label>
              </GoAFormItem>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewFuel;
