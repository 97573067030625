import { atom } from "recoil";
import { ICostingActivity } from "../model/domainServices/ICostingActivity";
import { ICrewType } from "../model/domainServices/ICrewType";
import { IRateType } from "../model/domainServices/IRateType";
import { IRateUnit } from "../model/domainServices/IRateUnit";
import { IPaginationResult } from "../model/search/IPaginationResult";

export const crewTypeState = atom<IPaginationResult<ICrewType>>({
	key: "crewTypeState",
	default: undefined
});

export const costingActivityState = atom<IPaginationResult<ICostingActivity>>({
	key: "costingActivityState",
	default: undefined
});

export const rateTypeState = atom<IPaginationResult<IRateType>>({
	key: "rateTypeState",
	default: undefined
});

export const rateUnitState = atom<IPaginationResult<IRateUnit>>({
	key: "rateUnitState",
	default: undefined
});
