import * as React from 'react';
import PageHeader from '../../components/PageHeader';
import PageLoader from '../../components/PageLoader';
import { FlightReport, IFlightReport } from "../../model/IFlightReport";
import FlightReportFlagged from './FlightReportFlagged';

interface IFlightReportTitleProps {
    flightReport: FlightReport;
    paramId:string | undefined;
    onChange: (value: FlightReport) => void;
}

const FlightReportTitle: React.FunctionComponent<IFlightReportTitleProps> = ({flightReport,paramId,onChange,...props}) => {
    //Loader
    const [loading, setIsLoading] = React.useState(false);

    //on property values change
    function onFlagStatusChange(record:IFlightReport) {
      setIsLoading(true);

      let newValue = new FlightReport();

      newValue =  new FlightReport({...record});

      //send on change to parent component
      onChange(newValue);

      setIsLoading(false);
    }

  return(
    <>
      <PageLoader visible={loading} />

        <div className="form-row">
            <div className="space-3">
              <PageHeader title={paramId ? "Flight Report " + flightReport?.flightReportId ?? "..." : "Add new flight report" } />
            </div>
            <div className="page-header">
              <FlightReportFlagged iFlightReport={flightReport} onFlagChange={onFlagStatusChange} />
            </div>
          </div>
    </>
  );
};

export default FlightReportTitle;
