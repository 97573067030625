// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Console } from "console";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { validationEnginService } from "../services/ValidationEnginService";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { useState } from "react";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { useRecoilState, useRecoilValue } from "recoil";

export const getSetEndDate = (value: Date, offset: number) => {
  const d = value;
  d.setDate(d.getDate() + offset);
  return d;
}

export const sortingData = (paginationResult: any, sortCol: string, sortDir: any = "ASC") => {
  const _result = [...(paginationResult.data || [])];
  _result.sort((a, b) => {
    return (
      (a[sortCol as keyof typeof paginationResult] >
        b[sortCol as keyof typeof paginationResult]
        ? -1
        : 1) * sortDir
    );
  });
}

export const onlyNumericRegularExp = () => {
  return /^[0-9\b]+$/;
}

export const onlyNumbersKey = (event: React.KeyboardEvent<HTMLInputElement> | any) => {
  var ASCIICode = (event.which) ? event.which : event.keyCode

  if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57)) {
    console.log("ASCIICode", ASCIICode);
    event.preventDefault();
  }
}

export const toNumber = (value: string | number) => {
  if (typeof value === 'number') return value
  return parseInt(value.replace(/[^\d]+/g, ''));
}

export const checkLogin = () => {
  if ((sessionStorage.getItem("username") != null && sessionStorage.getItem("username") !== "") &&
    (sessionStorage.getItem("password") != null && sessionStorage.getItem("password") !== "")) {
    return true;
  }
  else {
    return false;
  }
}

export const convertObjectFromOneSingleArrayValue = (obj: []) => {
  return Object.assign({}, ...obj as unknown as []);
}

export const isValidURL = (url:string) => { 
  const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i; 
  return urlPattern.test(url); 
} 


export const validate = (ruleCode: string, elementName: string = '', eventName: string = '',value:any='') => {
  const funcStartStr = 'function validate(elementName, event, value) {const errorMessages=[]; let isValid=true;'
  const functionEndingStr = '	return errorMessages;}return validate(elementName, eventName,value);';
 
  //console.log(funcStartStr + ruleCode + functionEndingStr);
  return run(elementName, eventName, value,funcStartStr + ruleCode + functionEndingStr)
};

const run = (elementName: string, eventName: string, value:any, ruleCode: string) => {
  try {
    
    // Create a new function using the script content
    // eslint-disable-next-line no-new-func
    //console.log(ruleCode);
    // eslint-disable-next-line no-new-func
    const dynamicFunction = new Function('elementName', 'eventName','value', ruleCode);

    // Execute the function with the parameter and get the result
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return dynamicFunction(elementName, eventName,value);

    // Update the state with the script's output
    //setScriptOutput(scriptResult);
  } catch (error) {
    console.error('Error executing script:', error);
  }
};

