import moment from "moment";
import { CostingActivity } from "./domainServices/ICostingActivity";
import { ICrewType } from "./domainServices/ICrewType";
import { FireNumber } from "./domainServices/IFireNumber";
import { RateType } from "./domainServices/IRateType";
import { RateUnit } from "./domainServices/IRateUnit";

export interface IFlyingHours{
    flyingHoursId?:string,
    flightReportId?:number,

    fireNumberId?:number,
    fireNumber?:string,
    fireNumberEntity?:FireNumber | undefined,
    fireNumberList?:{label:string,value:number}[],

    crewTypeId:string,
    crewTypeEntity?:ICrewType,
    crewTypeList?:{label:string,value:string}[],

    costingActivityId:string,
    costingActivityCode?:string,
    costingActivityEntity?:CostingActivity | undefined,
    costingActivityList?:{label:string,code:string,value:string}[],

    rateTypeId:string,
    rateTypeEntity?:RateType | undefined,
    rateTypeList?:{label:string,value:string}[],

    noOfUnits:number,
    rateUnitId:string,
    rateUnitEntity?:RateUnit | undefined,
    rateUnitList?:{label:string,value:string}[],

    ratePerUnit?:any,
    cost?:any,
    internalOrder?:string,
    costCenter?:string,
    fund?:string,
    account?:string,

    createUserId?:string,
    createTimestamp?:string,
    updateTimestamp?:string | null,
    updateUserId?: string | null,   

    deleted?:boolean,
    expenseType?:string,
}

export class FlyingHours implements IFlyingHours{
    readonly flyingHoursId?:string;
    flightReportId?:number;
    
    readonly fireNumberId?:number;
    readonly fireNumber?:string;
    fireNumberEntity?:FireNumber;
    fireNumberList?:{label:string,value:number}[];

    readonly crewTypeId:string;
    crewTypeEntity?:ICrewType;
    crewTypeList?:{label:string,value:string}[];

    readonly costingActivityId:string;
    readonly costingActivityCode?:string;
    costingActivityEntity?:CostingActivity;
    costingActivityList?:{label:string,code:string,value:string}[];

    rateTypeId:string;
    rateTypeEntity?:RateType;
    rateTypeList?:{label:string,value:string}[];

    readonly noOfUnits:number;

    rateUnitId:string;
    rateUnitEntity?:RateUnit;
    rateUnitList?:{label:string,value:string}[];

    ratePerUnit?:any;
    readonly cost:any;
    readonly internalOrder?:string;
    readonly costCenter?:string;
    readonly fund?:string;
    readonly account?:string;

     createUserId?:string ;
     createTimestamp?:string;
     updateTimestamp?:string | null;
     updateUserId?: string | null;  

    readonly deleted?:boolean;
    expenseType?:string;

    constructor(from?: Partial<IFlyingHours>) {
        this.flyingHoursId = from?.flyingHoursId ?? "00000000-0000-0000-0000-000000000000";
        this.flightReportId = from?.flightReportId;

        this.fireNumberId = from?.fireNumberId;
        this.fireNumber = from?.fireNumber ?? "";
        this.fireNumberEntity= from?.fireNumberEntity ?? undefined;
        this.fireNumberList = from?.fireNumberList ?? undefined;
        
        this.crewTypeId = from?.crewTypeId ?? "";
        this.crewTypeEntity= from?.crewTypeEntity ?? undefined;
        this.crewTypeList = from?.crewTypeList ?? undefined;

        this.costingActivityId = from?.costingActivityId ?? "";
        this.costingActivityCode = from?.costingActivityCode ?? "";
        this.costingActivityEntity= from?.costingActivityEntity ?? undefined;
        this.costingActivityList = from?.costingActivityList ?? undefined;

        this.rateTypeId = from?.rateTypeId ?? "";
        this.rateTypeEntity= from?.rateTypeEntity ?? undefined;
        this.rateTypeList = from?.rateTypeList ?? undefined;

        this.noOfUnits = from?.noOfUnits ?? 0;

        this.rateUnitId = from?.rateUnitId ?? "";
        this.rateUnitEntity= from?.rateUnitEntity ?? undefined;
        this.rateUnitList = from?.rateUnitList ?? undefined;

        this.ratePerUnit = from?.ratePerUnit ?? 0;
        
        this.cost = this.calculateCost(from?.noOfUnits,from?.ratePerUnit);

        this.internalOrder = from?.internalOrder ?? "";
        this.costCenter = from?.costCenter ?? "";
        this.fund = from?.fund ?? "";
        this.account = from?.account ?? "";

        this.createUserId=from?.createUserId ?? "";
        this.createTimestamp=this.dateFormat(from?.createTimestamp) ?? null;
        this.updateTimestamp = moment(from?.updateTimestamp).isValid() ? moment(from?.updateTimestamp).format("yyyy-MM-DD") : null;
        this.updateUserId=from?.updateUserId ?? null;  
    
        this.deleted = from?.deleted ?? false;
        this.expenseType = from?.expenseType ?? "";
    }

    dateFormat(value?: string): string
      {
        if (!value) return "";

        return moment(value).isValid() ? moment(value).format("yyyy-MM-DD") : value;
      }

    calculateCost(noOfUnits: any,ratePerUnit:any): number
    {
      if(noOfUnits && ratePerUnit)
        return noOfUnits * ratePerUnit;
      else
        return 0;
    }
}