import { GoAButton, GoAInputNumber, GoAInputText, GoATable } from '@abgov/react-components';
import * as React from 'react';
import Select from "react-select";
import { ExpenseDetail, FlightExpenses } from '../../model/IFlightExpense';
import { RateUnitService, bindRateUnitDropdown } from '../../services/domainServices/RateUnitService';
import { FlyingDetailCRUD } from '../../operations/FlyingDetailCRUD';
import { IContractRates } from '../../model/HiringContract/IContractRates';
import { IPaginationResult } from '../../model/search/IPaginationResult';
import { getContractRates } from '../../services/ContractRateService';
import { RateTypeService, bindRateTypeDropdown } from '../../services/domainServices/RateTypeService';
import PageLoader from '../../components/PageLoader';
import { bindCostingActivityDropdown } from '../../services/domainServices/CostingActivityService';
import moment from 'moment';

interface IEditExpenseDetailsProps {
  flightExpenses: FlightExpenses | undefined;
  contractRatesPaginationResult:IPaginationResult<IContractRates> | undefined;
  fireNumberOptions:{label:string,value:number}[];
  crewTypeOptions:{label:string,value:string}[];
  costingActivityOptions:{label:string,code:string,value:string}[];
  rateTypeOptions:{label:string,value:string}[];
  rateUnitOptions:{label:string,value:string}[];
  onChange: (value: FlightExpenses) => void;
}

const EditExpenseDetails: React.FunctionComponent<IEditExpenseDetailsProps> = ({ flightExpenses,contractRatesPaginationResult,
  fireNumberOptions,crewTypeOptions,costingActivityOptions,rateTypeOptions,rateUnitOptions, onChange, ...props }) => {
  //  console.log(rateTypeOptions);
   
  const [formValues, setFormValues] = React.useState(new FlightExpenses(flightExpenses));
  const [loading, setIsLoading] = React.useState(false);
  function onPropertyChange(idx: number, value: Partial<ExpenseDetail>) {
    let newValue = new ExpenseDetail(formValues.expenseDetails[idx]);

    newValue = new ExpenseDetail({ ...newValue, ...value });
    let rows = [...formValues.expenseDetails];
    rows[idx] = newValue;
    let newFlightExpense = new FlightExpenses({ ...formValues, expenseDetails: rows });
    newFlightExpense.flightFuelUsage.updateTimestamp=null;
    setFormValues(newFlightExpense);

    //send on change to parent component "FlightReportSummary"
    onChange(newFlightExpense);
  }
  
  //Costing activity
  async function getCostingActivities(idx:number = -1, searchValue:string = ""){
    try {
      var costingActivityResponse: any = await FlyingDetailCRUD.getCostingActivities(searchValue);
      if (costingActivityResponse.status)
      {
        const costingActivityResult = bindCostingActivityDropdown(costingActivityResponse.data);
        
        if(idx !== -1 && formValues.expenseDetails[idx])
        {
          onDrodownSearch(idx,{costingActivityList:costingActivityResult});
        }
      }
    }
    catch (Error) {
      console.log("Error", Error);
    }
}

//Rate type
async function getRateTypes(idx:number = -1, searchValue:string = "")
{
  try {
    var rateTypeResponse: any = await FlyingDetailCRUD.getRateTypes(searchValue);
    if (rateTypeResponse.status)
    {
      const rateTypeResult = bindRateTypeDropdown(rateTypeResponse.data);
      
      if(idx !== -1 && formValues.expenseDetails[idx])
      {
        onDrodownSearch(idx,{rateTypeList:rateTypeResult});
      }
    }
  }
  catch (Error) {
    console.log("Error", Error);
  }
}


  async function handleAddRow() {
    setIsLoading(true);

    const expenseDetail = new ExpenseDetail();
    expenseDetail.createTimestamp = moment(new Date()).format("yyyy-MM-DD");
    expenseDetail.createUserId = sessionStorage.getItem("username")?.toString();
    expenseDetail.updateTimestamp = null;
    expenseDetail.expenseType ="Flight expense";
    
    //Dropdown
    expenseDetail.fireNumberList = fireNumberOptions;
    expenseDetail.costingActivityList = costingActivityOptions;
    expenseDetail.rateTypeList = rateTypeOptions;
    expenseDetail.rateUnitList = rateUnitOptions;

    if(contractRatesPaginationResult)
    {
      var rateTypeId = contractRatesPaginationResult.data ? contractRatesPaginationResult.data[0].crtyId : 0;
      var rateUnitId = contractRatesPaginationResult.data ? contractRatesPaginationResult.data[0].cruId : 0;
      if(rateTypeId)
      {
        var rateType : any = await RateTypeService.getByOracleId(rateTypeId);
        var contractRates = getContractRates(contractRatesPaginationResult, rateTypeId);

        expenseDetail.rateTypeId = rateType ? rateType.data.rateTypeId : undefined;
        expenseDetail.ratePerUnit = contractRates ? contractRates?.ratePerUnit : 0;
      }

      if(rateUnitId)
      {
        var rateUnit : any = await RateUnitService.getByOracleId(rateUnitId);

        expenseDetail.rateUnitId = rateUnit ? rateUnit?.data?.rateUnitId : undefined;
      }
    }
    formValues.expenseDetails.push(expenseDetail);
    setFormValues(formValues);

    //send on change to parent component "FlightReportSummary"
    onChange(formValues);
    setIsLoading(false);
  }

  //Rate unit
  async function getRateUnits(idx: number = -1, searchValue: string = "") {
    try {
      
      var rateUnitResponse: any = await FlyingDetailCRUD.getRateUnits(searchValue);
      if (rateUnitResponse.status) {
        const rateUnitResult = bindRateUnitDropdown(rateUnitResponse.data);

        if (idx !== -1 && formValues.expenseDetails) {
          onDrodownSearch(idx, { rateUnitList: rateUnitResult });
        }
      }
    }
    catch (Error) {
      console.log("Error", Error);
    }
  }

  function onDrodownSearch(idx: number, value: Partial<ExpenseDetail>) {
    let newValue = new ExpenseDetail(formValues.expenseDetails[idx]);

    newValue = new ExpenseDetail({ ...newValue, ...value });
    let rows = [...formValues.expenseDetails];
    rows[idx] = newValue;

    setFormValues(formValues);
  }

  return (
    <>
     <PageLoader visible={loading} />
      <div>
        <GoATable width="100%">
          <thead>
            <tr style={{ position: "sticky", top: 0 }}>
              <th className="width15">Fire number</th>
              <th className="width15">Activity</th>
              <th className="width15">Rate type</th>
              <th className="width8">No.of units</th>
              <th className="width10">Unit</th>
              <th>Rate per unit</th>
              <th>Cost</th>
              <th className="width8">Internal order</th>
              <th className="width8">Cost center</th>
              <th className="width8">Fund</th>
              <th className="width8">Account</th>
            </tr>
          </thead>

          <tbody style={{ position: "sticky", top: 0 }}>
            {flightExpenses?.expenseDetails?.map(
              (_record: any, idx: number) => (
                <tr key={idx}>
                  <td>
                        <Select
                          name={"selFireNmbers"+idx}
                          options={fireNumberOptions}
                          placeholder="--Select--"
                          className="width100"
                          //value={fireNumberOptions.find(t => t.value === _record?.fireNumberId) }
                          value={fireNumberOptions.find((t: { value: any; }) => t.value === _record?.fireNumberId) }
                          onChange={async (value:any) =>{
                            onPropertyChange(idx,{fireNumberId:value.value,fireNumber:value.label});
                          }}
                          isSearchable={true}
                        />
                  </td>
                  <td>
                  <Select
                          name= {"selCostingActivity" + idx}
                          options={costingActivityOptions}
                          placeholder="--Select--"
                          className="width100"
                          value={costingActivityOptions.find((t: { value: any; }) => t.value === _record?.costingActivityId)}
                          onChange={async (value:any) =>{
                            onPropertyChange(idx,{costingActivityId:value.value,costingActivityCode:value.code});
                          }}
                          onInputChange={(value:any)=>{
                            if(value)
                              getCostingActivities(idx,value);
                          }}
                          isSearchable={true}
                        />
                  </td>
                  <td>
                  
                  <Select
                          name={"selRateType"+idx}
                          options={rateTypeOptions}
                          placeholder="--Select--"
                          className="width100"
                          value={rateTypeOptions.find((t: { value: any; }) => t.value === _record?.rateTypeId) }
                          onChange={async (value:any) =>{
                            if(value)
                            {
                              var rateType : any = await RateTypeService.getById(value.value);
                            
                              if(rateType && contractRatesPaginationResult)
                                var ratePerUnit = getContractRates(contractRatesPaginationResult,rateType?.data?.oracleId)
  
                              onPropertyChange(idx,{rateTypeId:value.value,ratePerUnit:ratePerUnit?.ratePerUnit})
                            }
                          }}
                          onInputChange={(value:any)=>{
                            if(value)
                              getRateTypes(idx,value);
                          }}
                          isSearchable={true}
                        />
                  </td>
                  <td><GoAInputNumber
                          name="txtNoOfUnits"
                          value={_record?.noOfUnits}
                          min={0}
                          max={99}
                          step={1}
                          width="100%"
                          onChange={(name: any, value: any) => {
                            onPropertyChange(idx,{noOfUnits:value});
                          }}
                        /></td>
                  <td>
                    <Select
                          name={"selRateUnit"+idx}
                          options={_record?.rateUnitList}
                          placeholder="--Select--"
                          className="width100"
                          value={rateUnitOptions.find((t: { value: any; }) => t.value === _record?.rateUnitId) }
                          onChange={async (value:any) =>{
                            onPropertyChange(idx,{rateUnitId:value.value})
                          }}
                          onInputChange={(value:any)=>{
                            if(value)
                              getRateUnits(idx,value);
                          }}
                          isSearchable={true}
                        />
                  </td>
                  <td>{_record?.ratePerUnit > 0 && contractRatesPaginationResult && contractRatesPaginationResult.data.find(value => value.crtyId === _record?.rateTypeEntity?.oracleId) ?  
                          "$"+_record?.ratePerUnit
                          : <GoAInputText
                          name="txtRatePerUnit"
                          value={_record?.ratePerUnit ? _record?.ratePerUnit : ""}
                          width="100%"
                          maxLength={10}
                          onChange={(name, value) => {onPropertyChange(idx,{ratePerUnit: value})}}
                          /> }
                  </td>
                  <td>
                  {"$"+_record?.cost}
                  </td>
                  <td><GoAInputText
                    name="internalOrder"
                    width="100%"
                    value={_record.internalOrder}
                    onChange={(name, value) => {
                      onPropertyChange(idx, { internalOrder: value });
                    }}
                  /></td>
                  <td><GoAInputText
                    name="costCentre"
                    width="100%"
                    value={_record.costCenter}
                    onChange={(name, value) => {
                      onPropertyChange(idx, { costCenter: value });
                    }}
                  /></td>
                  <td><GoAInputText
                    name="fund"
                    width="100%"
                    value={_record.fund}
                    onChange={(name, value) => {
                      onPropertyChange(idx, { fund: value });
                    }}
                  /></td>
                  <td><GoAInputText
                    name="account"
                    width="100%"
                    value={_record.account}
                    onChange={(name, value) => {
                      onPropertyChange(idx, { account: value });
                    }}
                  /></td>
                </tr>
              )
            )
            }
            <tr>
              <td>
                <GoAButton type="secondary" onClick={() => handleAddRow()}>
                  Add New +
                </GoAButton>
              </td>
            </tr>
          </tbody>
        </GoATable>
      </div>
    </>
  );
};

export default EditExpenseDetails;
