
import moment from "moment";
import { ICorporateRegion } from "./ICorporateRegion";
import { IHiringContract } from "./HiringContract/IHiringContract";
import { IVendor } from "./IVendor";

export interface IFlightReport{
    flightReportId:number,
    oracleId?:number,
    ao02Number:string,
    flightReportDate:string,
    timeUp:any,
    timeDown:any,

    timeUpDisplay:string,
    timeDownDisplay:string,

    meterFinish:any,
    meterStart:any,

    pointOfDeparture?:string,
    downForNightLocation?:string,
    dailyBasingIndicator?:boolean,
    unusedMinsAppliedInt?:boolean,
    doubleCrewInd?:boolean,
    noFlyDay:boolean,
    remark:string,

    corporateRegionId:string,
    corporateRegion?:ICorporateRegion | undefined

    vendorId?:any,
    vendor?:IVendor | undefined,

    contractRegistrationId?:any,
    contractRegistration?:IHiringContract | undefined,
    flyingRegistrationId?:any,
    flyingRegistration?:IHiringContract | undefined,

    //aircraftId:number,
    // registration:string,
    status?:string,
    isFlagged:boolean,

    createUserId?:string,
    createTimestamp?:string,
    updateTimestamp?:string,
    updateUserId?: string,   
} 

export class FlightReport implements IFlightReport, IErrorState<Partial<IFlightReport>>{
    readonly flightReportId: number = 0;
    // oracleId?: number | undefined;
    readonly ao02Number: string;
    readonly flightReportDate: string;
    readonly timeUp: any=null;
    readonly timeDown: any=null;

    timeUpDisplay: string='';
    timeDownDisplay: string='' ;

    readonly meterFinish: any;
    readonly meterStart: any;
    readonly pointOfDeparture?: string;
    readonly downForNightLocation?: string;
    readonly dailyBasingIndicator?: boolean ;
    readonly unusedMinsAppliedInt?: boolean;
    readonly doubleCrewInd?: boolean;
    readonly noFlyDay: boolean ;
    readonly remark: string;

    readonly corporateRegionId: string;
    corporateRegion?: ICorporateRegion;
    
    readonly vendorId: any ;
    vendor?: IVendor;

    readonly contractRegistrationId?: any ;
    readonly contractRegistration?:IHiringContract;
    readonly flyingRegistrationId?:any;
    readonly flyingRegistration?:IHiringContract;

    // aircraftId: number = 0;
    // registration: string = '';
    readonly status: string;
    readonly isFlagged:boolean = false;

     createUserId?: string;
     createTimestamp?: string;
     updateTimestamp?: string;
     updateUserId?: string;

    //readonly HiringContracts?: IHiringContract[]; 

    constructor(from?: Partial<IFlightReport>) {
        this.flightReportId = from?.flightReportId ?? 0;
        this.ao02Number = from?.ao02Number ?? "";
        this.flightReportDate = from?.flightReportDate ? this.dateFormat(from?.flightReportDate) : "";

        this.timeUp = from?.timeUp ?? this.setTimeUpDown(from?.timeUpDisplay) ?? null;
        this.timeDown = from?.timeDown ?? this.setTimeUpDown(from?.timeDownDisplay) ?? null;

        this.timeUpDisplay = from?.timeUpDisplay ?? this.getTimeFormat(from?.timeUp) ?? "";
        this.timeDownDisplay = from?.timeDownDisplay ?? this.getTimeFormat(from?.timeDown) ?? "";

        this.meterFinish= from?.meterFinish ?? null;
        this.meterStart= from?.meterStart ?? null;
        this.pointOfDeparture= from?.pointOfDeparture ?? "";
        this.downForNightLocation= from?.downForNightLocation ?? "";
        this.dailyBasingIndicator= from?.dailyBasingIndicator ?? false;
        this.unusedMinsAppliedInt= from?.unusedMinsAppliedInt ?? false;
        this.doubleCrewInd= from?.doubleCrewInd ?? false;
        this.noFlyDay= from?.noFlyDay ?? false;
        this.remark= from?.remark ?? "";
        
        this.corporateRegionId= from?.corporateRegionId ?? "";
        this.corporateRegion= from?.corporateRegion ?? undefined;
        this.vendorId= from?.vendorId ?? null;
        this.vendor= from?.vendor ?? undefined;

        this.contractRegistrationId= from?.contractRegistrationId?? null;
        this.contractRegistration = from?.contractRegistration ?? undefined;
        this.flyingRegistrationId = from?.flyingRegistrationId ?? null;
        this.flyingRegistration= from?.flyingRegistration ?? undefined;
    

        //this.aircraftId= from?.aircraftId ?? "";
        //this.registration= from?.corporateRegionId ?? "";
        //this.status= this.setStatus(from?.status);
        this.status= from?.status ?? "";
        this.isFlagged = from?.isFlagged ?? false;

        this.createUserId= from?.createUserId ?? "";
        this.createTimestamp= this.dateFormat(from?.createTimestamp);
        this.updateTimestamp= this.dateFormat(from?.updateTimestamp);
        this.updateUserId= from?.updateUserId ?? "";
        
      }

      get errorState(){
        return {flightReportDate:!this.flightReportDate ?  true : false }
      }

      setTimeUpDown(value?: string):string{
        if(this.flightReportDate)
        {
          var momentObj = moment(this.flightReportDate + value, 'YYYY-MM-DDLT');
          // conversion
          return moment(momentObj).format("YYYY-MM-DDTHH:mm:ss");
        }
        else
        {
          return "";
        }
      }

      timeFormat(value?: string): string {
        if (!value) return "";

        return moment(value).isValid() ? moment(value).format("HH:mm") : value;
       
        //     Intl.DateTimeFormat
        //     //var result = //format the value
        // return value;
      }

      getTimeFormat (value:string):string {
        if(value)
        {
          if(moment(value).isValid())
          {
            var time = moment(value).format("HH:mm");
            return time !== "00:00" ? time : "";
          }
          else{
            return value;
          }
        }
        else{
          return "";
        }
    }

      dateFormat(value?: string): string
      {
        if (!value) return "";

        return moment(value).isValid() ? moment(value).format("yyyy-MM-DD") : value;
      }

      // setUpdateTimestamp(){
      //     this.updateTimestamp = moment(new Date()).format("yyyy-MM-DD");
      // }

      setStatus(value?: string):string{
        if(this.flightReportId === 0)
         return "Created"
        else
         return ""
      }

      // setCreateUserId(value?:string):string{
      //   if()
      // }

      // setCorporateRegion(corporateRegionId:string)
      // {
      //   return getCorporateRegion(corporateRegionPaginationResult,corporateRegionId);
        
      // }
    
}

export type ErrorState<T> = {
	[property in keyof T]: boolean;
};

export interface IErrorState<T> {
	errorState?: ErrorState<T>;
}


// function setTimeUpTimeDown(FlightReport obj)
// {
//   //If timeup & timedown do not change
//   if(flightReport.timeUp !== null)
//   {
//     momentObj = moment(flightReport.flightReportDate + flightReport.timeUp, 'YYYY-MM-DDLT');
//     // conversion
//     dateTime = moment(momentObj).format("YYYY-MM-DDTHH:mm:ss");
//     newValue =  new FlightReport({...flightReport,  timeUp:dateTime});
//   }

//   if(flightReport.timeDown !== null)
//   {
//     momentObj = moment(flightReport.flightReportDate + flightReport.timeDown, 'YYYY-MM-DDLT');
//     // conversion
//     dateTime = moment(momentObj).format("YYYY-MM-DDTHH:mm:ss");
//     newValue =  new FlightReport({...flightReport,  timeDown:dateTime});
//   }
// }





