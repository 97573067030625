import moment from "moment";
import { FlightReport } from "../model/IFlightReport";
import { IFilter } from "../model/search/IFilter";
import { IPagination } from "../model/search/IPagination";
import { ISearch } from "../model/search/ISearch";
import { FlightReportService } from "../services/FlightReportService";
import { HiringContractService } from "../services/HiringContractService";

const getFlightReportById = async (flightReportId: number) => {
  await FlightReportService.getById(flightReportId);
};

const createAndUpdate = async (flightReport:FlightReport) => {

  if (flightReport?.flightReportId) {
    //update last updated by & last updated on timestamp
    flightReport.updateTimestamp = moment(new Date()).format("yyyy-MM-DD");
    flightReport.updateUserId = sessionStorage.getItem("username")?.toString();

    return await FlightReportService.update(flightReport)
      
  } else {
      //update created by & created on timestamp
      flightReport.createTimestamp = moment(new Date()).format("yyyy-MM-DD");
      flightReport.createUserId = sessionStorage.getItem("username")?.toString();

      return await FlightReportService.create(flightReport);
  }

};

//#region "Hiring Contract"
async function getHiringAircraft(reportDate:string)
{
  let objIPagination: IPagination = {
    perPage: 20,
    page: 1,
  };

  let objIFilter: IFilter = {
    columnName: "reportDate",
    columnValue: moment(reportDate).format("yyyy-MM-DD")
  };

  let objISearch: ISearch = {
    search: "",
    filterBy: objIFilter,
    pagination: objIPagination,
  };

  var response = await HiringContractService.getSearch(objISearch)
  return response.data.data;
}

export const FlightReportCRUD = {
  getFlightReportById,
  createAndUpdate,
  getHiringAircraft,
};
