import * as React from "react";
import PageLoader from "../../components/PageLoader";
import { DomainService } from "../../services/DomainService";
import { FlightReportService } from "../../services/FlightReportService";
import FlightReportList from "../flightReport/FlightReportList";

interface IDashboardProps {}

const Dashboard: React.FunctionComponent<IDashboardProps> = (props) => {

  //Loader
  const [loading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    setIsLoading(true);

    (async () => {
      await aviationReportingAuthenticate();
      await domainServiceAuthenticate();

      setIsLoading(false);
    })();
  }, []);

  async function aviationReportingAuthenticate() {
    await FlightReportService.getAuthenticate()
      .then((res) => {
        sessionStorage.setItem("api_token", res.data);
      })
      .catch((err) => {
        console.log("error", err);
      });
  }

  async function domainServiceAuthenticate() {
    await DomainService.getAuthenticate()
      .then((res) => {
        sessionStorage.setItem("domainService_token", res.data);
      })
      .catch((err) => {
        console.log("error", err);
      });
  }

  return (
    <>
      {/* <div className="content-Container">
        <PageHeader title="Aviation reporting" />
      </div> */}

      <PageLoader visible={loading} />

      {!loading && <FlightReportList />}

      {/* <Tabs activeIndex={1} data-testid="approvals-tabs">
        <Tab label="Requires Action" data-testid="approvals-required-tab">
          
        </Tab>
        <Tab label="All Reports" data-testid="approvals-need-attentions-tab">
          
        </Tab>
        
      </Tabs> */}
    </>
  );
};

export default Dashboard;
