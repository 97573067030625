const getRules = async (feature:string, api:string) => {
    const apiUrl = `https://wmtt-rules-engine.azurewebsites.net/api/v1.0/rules-engine/rules?feature=${feature}&api=${api}&isFrontEndValidation=true`;

    try {
        const response = await fetch(apiUrl, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("api_token")}`,
            },
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        return await response.json();
    } catch (error) {
        console.error('Fetch error:', error);
    }
}

export const validationEnginService = {
    getRules
};
