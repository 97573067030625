/* eslint-disable @typescript-eslint/no-unused-vars */
import { GoATable, GoAButton, GoAIcon, GoABlock, GoADropdown, GoADropdownItem, GoASpacer, GoAPagination, GoATableSortHeader, GoABadge } from '@abgov/react-components';
import moment from 'moment';
import * as React from 'react';
import { useAuth } from 'react-oidc-context';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import PageLoader from '../../components/PageLoader';
import FlightReportStatus from '../../model/enum/FlightReportStatus';
import { ICorporateRegion, IForestArea } from '../../model/ICorporateRegion';
import { IFlightReport } from '../../model/IFlightReport';
import { IFilter } from '../../model/search/IFilter';
import { IPagination } from '../../model/search/IPagination';
import { IPaginationResult } from '../../model/search/IPaginationResult';
import { ISearch } from '../../model/search/ISearch';
import { hasResourceRole } from '../../services/authentication/AuthenticationService';
import { bindMutltiSelectDropdown, CorporateRegionDomainService, getCorporateRegion } from '../../services/CorporateRegionDomainService';
import { FlightReportService } from '../../services/FlightReportService';
import { corporateRegionState } from '../../state/DomainService.state';
import { getDateWithMonthOffset } from '../../utils/dateHandler';
import FlightReportFlagged from './FlightReportFlagged';

interface IFlightReportAllProps {
    flightReportResults:IPaginationResult<IFlightReport> | undefined,
    forestAreaSelected:never[],
    startDate:string,
    endDate:string,
    searchValue:string,
}

const FlightReportAll: React.FunctionComponent<IFlightReportAllProps> = ({flightReportResults,forestAreaSelected,startDate,endDate,searchValue,...props}) => {
  
  const auth = useAuth();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const navigate = useNavigate();
  
  const [paginationResults, setPaginationResult] =
    React.useState<IPaginationResult<IFlightReport>>();

  //#region "Corporate region"
  const [corporateRegionPaginationResult, setCorporateRegionPaginationResult] = React.useState<IPaginationResult<ICorporateRegion>>();
  
  //Loader
  const [loading, setIsLoading] = React.useState(true);
  
  //Pagination
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [pageUsers, setPageFlightReports] = React.useState<any>([]);
  // page number
  const [page, setPage] = React.useState(1);
  //count per page
  const [perPage, setPerPage] = React.useState(10);
  const [previousSelectedPerPage, setPreviousSelectedPerPage] = React.useState(10);

    //Sorting
  const [sortCol, setSortCol] = React.useState("flightReportDate");
  const [sortDir, setSortDir] = React.useState(-1);
  const [isSorting, setIsSorting] = React.useState(false);

  //const sortPaginationResults = React.useMemo(() => sortedPaginationResults(), [sortedPaginationResults]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [maxDate, setMaxDate] = React.useState(getDateWithMonthOffset(1));

  const [minEndDate, setMinEndDate] = React.useState(new Date(1950, 1, 1));
  const [showEndDate, setShowEndDate] = React.useState(true);

  const[searchInputKeyPress,setSearchInputKeyPress] = React.useState("");

  //Recoil state
  const setcorporateRegionState = useSetRecoilState(corporateRegionState);

    //onKeyPress handler function
    const keyPressHandler = (event: React.KeyboardEvent<HTMLInputElement> | any) => {
      //Do something you like with "event"
        setSearchInputKeyPress("");
        setSearchInputKeyPress(event.key);
    };

    React.useEffect(()=>{
      

      const element = document.getElementsByName('flightReportSearch');
      element[0].addEventListener('keypress', keyPressHandler);
  
      if(sessionStorage.getItem("api_token") == null || sessionStorage.getItem("api_token") === undefined || sessionStorage.getItem("api_token") === ""
        || sessionStorage.getItem("domainService_token") == null || sessionStorage.getItem("domainService_token") === undefined || sessionStorage.getItem("domainService_token") === "")
      {
        setTimeout(()=>{getCorporateRegions();},200);
        setTimeout(()=>{onRefreshFlightReport();},200);
      }
      else{
        getCorporateRegions();
        onRefreshFlightReport();
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])


  React.useEffect(() => {
    onRefreshFlightReport();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, perPage, endDate, forestAreaSelected, searchValue, sortCol, sortDir]);


  function onRefreshFlightReport(){
    getFlightReports();
    setPageFlightReports(paginationResults?.data.slice(0, perPage));
  };

  //Get flight reports data
  const getFlightReports = async () => {
    setIsLoading(true);
    
    let strSearchValue = searchValue.toLowerCase();
    let sortOrder = sortDir === -1 ? "ASC" : "DESC";
    let startDt = startDate === null || startDate === "" ? null : moment(startDate).format("yyyy-MM-DD");
    let endDt = endDate === null || startDate === "" ? null : moment(endDate).format("yyyy-MM-DD");
    let objForestArea: IForestArea = {
      corporateRegionId: forestAreaSelected != null || forestAreaSelected !== undefined ? forestAreaSelected.map((forestArea)=>{ return forestArea["value"] as string}) : []
    };


    let objIPagination: IPagination = {
      perPage: perPage,
      page: page,
    };

    let objIFilter: IFilter = {
      columnName: "",
      columnValue: "",
      reportDateFrom:startDt,
      reportDateTo:endDt,
      corporateRegion:objForestArea
    };

    let objISearch: ISearch = {
      search: strSearchValue,
      sortBy: sortCol,
      sortOrder: sortOrder,
      filterBy: objIFilter,
      pagination: objIPagination,
    };
    
    setTimeout(() => {
      FlightReportService.getSearch(objISearch, auth.user?.access_token as string)
        .then((response: any) => {
          //debugger;
          setPaginationResult(p => {
            return sortingData(response.data);
            });

          setIsLoading(false);
        })
        .catch((e: Error) => {
          setIsLoading(false);
          sessionStorage.setItem("api_token", "");
          navigate("/");
        });
      }, 200);
  }

  function sortingData(paginationResult: IPaginationResult<IFlightReport>) {
    const _flightReports = [...(paginationResult.data || [])];
    _flightReports.sort((a, b) => {
      return (
        (a[sortCol as keyof typeof paginationResults] >
        b[sortCol as keyof typeof paginationResults]
          ? -1
          : 1) * sortDir
      );
    });

    paginationResult.data = _flightReports;
    //Only for sorting applied reset pagination
    if(isSorting)
    {
      setPerPage(previousSelectedPerPage | 10);
      setPage(1);
      setPageFlightReports(paginationResults?.data.slice(0, perPage));

      //Reset value
      setIsSorting(false);
      setPreviousSelectedPerPage(perPage);
    }
    return paginationResult;
  }

  function searchFlightReports(searchParamValue: string) {
    
    if(searchInputKeyPress === "Enter")
    {
      return;
    }

    if (searchParamValue.length >= 3 && searchValue !== searchParamValue) {
      setIsLoading(true);
      setPage(1);
      //setSearchValue(searchParamValue);
     } 
    else if (searchParamValue.length === 0 && searchValue !== "") {
      setIsLoading(true);
      //setSearchValue("");
    }
  }

  //Pagination change page
  function changePage(newPage: any) {
    setIsLoading(true);
    const offset = (newPage - 1) * perPage;
    const _flightReports = paginationResults?.data.slice(
      offset,
      offset + perPage
    );

    setPerPage(perPage);
    setPage(newPage);
    setPageFlightReports(_flightReports);
  }

  function changePerPage(name: any, value: any) {
    setIsLoading(true);
    const newPerPage = parseInt(value, 10);
    const offset = (page - 1) * newPerPage;

    const _flightReports = paginationResults?.data.slice(
      offset,
      offset + newPerPage
    );

    setPageFlightReports(_flightReports);
    //setSearchValue("");
    setPerPage(p => {
      return newPerPage;
      });
    setPage(page);
  }

  function sortData(sortBy: string, sortDir: number) {
    setSortCol(sortBy);
    setSortDir(sortDir);

    setPreviousSelectedPerPage(perPage);
    setPerPage(paginationResults?.paginationInfo.total || 30);
    setPage(1);
    setIsSorting(true);
  }

  //Corporate region
  function getCorporateRegions()
  {
    let objIPagination: IPagination = {
      perPage: 20,
      page: 1,
    };

    let objIFilter: IFilter = {
      columnName: "CRT_ID",
      columnValue: "4"
    };

    let objISearch: ISearch = {
      search: "",
      filterBy: objIFilter,
      pagination: objIPagination,
    };

      CorporateRegionDomainService.getSearch(objISearch)
        .then((response: any) => {
          //set corporate region recoil state 
          setcorporateRegionState(response.data);
          setCorporateRegionPaginationResult(response.data);
          const forestAreaResult = bindMutltiSelectDropdown(response.data) as [];
          //setForestAreaOptions(forestAreaResult);
        })
        .catch((e: Error) => {
          console.log("Error",e);
        });
      
  }

  //#endregion
function onClickFlightReport(flightReportId?:number)
{
    if(flightReportId)
    {
    //navigate(`/flightReportDetail/${flightReportId}`);
    navigate(`/flightReportDetail/${flightReportId}`,{state:corporateRegionPaginationResult});
    }
    else
    {
      navigate(`/flightReportDetail/new`,{state:corporateRegionPaginationResult});
    }
}

//on property values change
function onFlagStatusChange(objFlightReport:IFlightReport) {
  setIsLoading(true);

  if(paginationResults?.data)
  {
    // 1. Find the flightReport with the provided id
    const currentPaginationResultsIndex = paginationResults?.data.findIndex((value) => value.flightReportId === objFlightReport.flightReportId);
    // 2. Mark the flightReport as complete
    const updatedTodo = {...paginationResults?.data[currentPaginationResultsIndex], isFlagged: objFlightReport.isFlagged};

    // 3. Update the flightReport list with the updated flightReport
    const newPaginationResults = [
      ...paginationResults?.data.slice(0, currentPaginationResultsIndex),
      updatedTodo,
      ...paginationResults?.data.slice(currentPaginationResultsIndex + 1)
    ];
    paginationResults.data = newPaginationResults;
    setPage(1);
    setPaginationResult({...paginationResults});

  }

  setIsLoading(false);
}


  return(
    <>

      
        <PageLoader visible={loading} />

        <div>
        <div className="divTable">
          <GoATable onSort={sortData} width="100%">
            <thead>
              <tr>
                <th>
                {
        
        !auth.isLoading && hasResourceRole("FlightReports","P_FLRPT_FLIGHTRPT_REA",auth.user?.access_token as string) ? "YES" : "NO"
      }
                </th>
                <th>
                  <GoATableSortHeader name="isFlagged">
                  </GoATableSortHeader >
                </th>
                <th>Forest Area</th>
                <th>
                  <GoATableSortHeader name="flightReportDate">
                    Flight Date
                  </GoATableSortHeader>
                </th>
                <th>AC Registration</th>
                <th>Aircraft Vendor</th>
                <th>
                  <GoATableSortHeader name="flightReportId">
                    Flight Report #
                  </GoATableSortHeader>
                </th>
                <th>
                  <GoATableSortHeader name="ao02Number">
                    AO02 #
                  </GoATableSortHeader>
                </th>
                <th>Total flying hours</th>
                <th>
                  <GoATableSortHeader name="status">
                    Status
                  </GoATableSortHeader>
                </th>
              </tr>
            </thead>

            <tbody style={{ position: "sticky", top: 0 }} className='table-body'>
              {paginationResults?.data && paginationResults.data.length > 0 ? (
                paginationResults.data.map((record:any, index:any) => (
                  <tr key={record.flightReportId}>
                    <td>
                      <FlightReportFlagged iFlightReport={record} onFlagChange={onFlagStatusChange} />
                    </td>
                    <td>{corporateRegionPaginationResult?.data && corporateRegionPaginationResult.data.length > 0 ? 
                        (getCorporateRegion(corporateRegionPaginationResult, record?.corporateRegionId as string))?.name.toString()
                         : ""}</td>
                    <td>
                      {moment(record.flightReportDate).format("yyyy-MM-DD")}
                    </td>
                    <td></td>
                    <td></td>
                    {/* <td style={{ padding: "0 1rem 0 1rem" }}></td> */}
                    <td>
                      <GoAButton
                        {...{ style: '"padding: 0 10px 0 10px;height: 90px;"' }}
                        size="compact"
                        type="tertiary"
                        onClick={() => onClickFlightReport(record?.flightReportId)}
                      >
                        {record.flightReportId}
                      </GoAButton>
                    </td>
                    <td>{record.ao02Number}</td>
                    <td></td>
                    <td>{record.status === FlightReportStatus.returned ? 
                            <GoABadge type="important" content="Returned" />
                          : record.status === FlightReportStatus.signedOff ? 
                          <GoABadge type="success" content="Signed off" /> : <GoABadge type="information" content="In progress" /> }</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={6} className="centertext">
                    No data avaliable
                  </td>
                </tr>
              )}
            </tbody>
          </GoATable>
        </div>

        <div className={paginationResults?.data && paginationResults?.data.length > 0 ? "visible pagination" : "not-visible pagination"}>
          <GoABlock alignment="center">
            <GoABlock mb="m" alignment="center" gap="m">
              <span style={{ whiteSpace: "nowrap" }}>Show</span>
              <div className="dropdown-list">
              <GoADropdown name="selPerPage" onChange={changePerPage} value="10" width="8ch">
                <GoADropdownItem value="10"></GoADropdownItem>
                <GoADropdownItem value="20"></GoADropdownItem>
                <GoADropdownItem value="30"></GoADropdownItem>
              </GoADropdown>
              </div>
              <span style={{ whiteSpace: "nowrap" }}>
                of {paginationResults?.paginationInfo.total} items
              </span>
            </GoABlock>
            <GoASpacer hSpacing="fill" />
            <GoAPagination
              itemCount={paginationResults?.paginationInfo.total || 10}
              perPageCount={perPage}
              pageNumber={page}
              onChange={changePage}
            />
          </GoABlock>
        </div>
        </div>
    </>
  );
};

export default FlightReportAll;
