import { atom } from "recoil";
import { ICorporateRegion } from "../model/ICorporateRegion";
import { IHiringContract } from "../model/HiringContract/IHiringContract";
import { IVendor } from "../model/IVendor";
import { IPaginationResult } from "../model/search/IPaginationResult";

export const corporateRegionState = atom<IPaginationResult<ICorporateRegion>>({
	key: "corporateRegionState",
	default: undefined
});

export const aircraftDetailsState = atom<IHiringContract[]>({
	key: "aircraftDetailsState",
	default: undefined
});

export const vendorDetailsState = atom<IVendor>({
	key: "vendorDetailsState",
	default: undefined
});
