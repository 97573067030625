import { GoAButton, GoAButtonGroup, GoAModal, GoATab, GoATabs } from "@abgov/react-components";
import moment from "moment";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import PageLoader from "../../components/PageLoader";
import { FlightReport } from "../../model/IFlightReport";
import { getCorporateRegion } from "../../services/CorporateRegionDomainService";
// import { getCorporateRegion } from "../../services/CorporateRegionDomainService";
import { FlightReportService } from "../../services/FlightReportService";
import { aircraftDetailsState, corporateRegionState } from "../../state/DomainService.state";
// import { getVendor } from "../../utils/VendorCommonMethods";
import EditFlightReportSummary from "./EditFlightReportSummary";
import EditFlyingDetail from "../flyingDetails/EditFlyingDetail";
import ViewFlightReportSummary from "./ViewFlightReportSummary";
import ViewFlyingDetail from "../flyingDetails/ViewFlyingDetail";
import { FlyingHours, IFlyingHours } from "../../model/IFlyingHours";
import { FlyingHoursService } from "../../services/FlyingHoursService";
import { IPaginationResult } from "../../model/search/IPaginationResult";
import { IPagination } from "../../model/search/IPagination";
import { IFilter } from "../../model/search/IFilter";
import { ISearch } from "../../model/search/ISearch";

import { bindCrewTypeDropdown, CrewTypeService } from "../../services/domainServices/CrewTypeService";
import { bindCostingActivityDropdown, CostingActivityService } from "../../services/domainServices/CostingActivityService";
import { bindRateTypeDropdown, RateTypeService } from "../../services/domainServices/RateTypeService";
import { validationEnginService } from "../../services/ValidationEnginService";
import { validate } from "../../utils/commonMethods";
import { bindRateUnitDropdown, RateUnitService } from "../../services/domainServices/RateUnitService";
import { getVendor } from "../../utils/VendorCommonMethods";
import { getContractRegistration, HiringContractService } from "../../services/HiringContractService";
import { convertObjectFromOneSingleArrayValue } from "../../utils/commonMethods";
import { bindFireNumberDropdown, FireNumberService } from "../../services/domainServices/FireNumberService";
import ExpenseSummary from "../expense/ExpenseSummary";
import { IHiringContract } from "../../model/HiringContract/IHiringContract";
import { IContractRates } from "../../model/HiringContract/IContractRates";
import { ICrewType } from "../../model/domainServices/ICrewType";
import { IRateUnit } from "../../model/domainServices/IRateUnit";
import { ExpenseService } from "../../services/ExpensesService";
import { useState } from "react";
import { FlightExpenses } from "../../model/IFlightExpense";
import CalloutVariant from "../../model/enum/CalloutVariant";
import { getTimeFormat } from "../../utils/dateHandler";
import { FlyingDetailCRUD } from "../../operations/FlyingDetailCRUD";
import axios from "axios";
import { IValidationError } from "../../model/errors/IValidationError";
 

interface IFlightReportSummaryProps {
  flightReport: FlightReport;
  crewTypeStateResult: IPaginationResult<ICrewType>;
  rateUnitStateResult: IPaginationResult<IRateUnit>;
  onEdit: (value: boolean) => void;
  onUpdate: (value: FlightReport) => void;
}

const FlightReportSummary: React.FunctionComponent<
  IFlightReportSummaryProps
> = ({ flightReport, crewTypeStateResult, rateUnitStateResult, onEdit, onUpdate }) => {
  const [isChildDataValid, setIsChildDataValid] = useState(true);
  const [errorItems, setErrorItems] = useState<JSX.Element[]>([]);
  //export declare type CalloutVariant = "information" | "important" | "emergency" | "success" | "event";

  const handleChildDataValidity = (errorMessages: []) => {
    if(errorMessages)
    {
      setIsChildDataValid(errorMessages.length === 0);
      setErrorItems(errorMessages.map((item, index) => (
        <li key={index} style={{ color: 'red' }}>
          {item}
        </li>
      )));
    }
  };


  const navigate = useNavigate();
  const [isView, setIsView] = React.useState(true);
  const [isEdit, setIsEdit] = React.useState(false);
  const [isUpdate, setIsUpdate] = React.useState(false);

  const [tempFlightReport, setTempFlightReport] = React.useState(
    new FlightReport(flightReport)
  );
  const [updateFlightReport, setUpdateFlightReport] = React.useState(
    new FlightReport(flightReport)
  );

  const [showModal, setShowModal] = React.useState(false);
  const [showNotificationMessage, setShowNotificationMessage] = React.useState(false);
  const [notificationMessage, setNotificationMessage]= useState("");
  const [modalHeading] = React.useState("");
  const [calloutVariant, setCalloutVariant] = useState(CalloutVariant.success);

  //Loader
  const [loading, setIsLoading] = React.useState(false);

  const corporateRegionPaginationResult = useRecoilValue(corporateRegionState);

  //Flying hours
  const [isFlyingHoursEdit, setIsFlyingHoursEdit] = React.useState(false);
  const [isFlyingHoursUpdate, setIsFlyingHoursUpdate] = React.useState(false);
  //Flight Expenses
  const [isFlightExpensesUpdate, setIsFlightExpensesUpdate] = React.useState(false);
  const [isFlightExpensesEdit, setIsFlightExpensesEdit] = React.useState(false);
  const [tempFlyingHours, setTempFlyingHours] = React.useState<Array<FlyingHours>>([]);
  const [updateFlyingHours, setUpdateFlyingHours] = React.useState<FlyingHours[]>([]);
  
  //Drop down 
  const [fireNumberOptions,setFireNumberOptions] = React.useState<{label:string,value:number}[]>([]);
  const [costingActivityOptions, setCostingActivityOptions] = React.useState<{label:string,code:string,value:string}[]>([]);
  const [rateTypeOptions, setRateTypeOptions] = React.useState<{label:string,value:string}[]>([]);
  const [crewTypeOptions, setCrewTypeOptions] = React.useState<{label:string,value:string}[]>([]);
  const [rateUnitOptions, setRateUnitOptions] = React.useState<{label:string,value:string}[]>([]);

  const [flyingHoursPaginationResult, setFlyingHoursPaginationResult] = React.useState<IPaginationResult<IFlyingHours>>();

  //Contract Rates
  const [contractRatesPaginationResult, setContractRatesPaginationResult] = React.useState<IPaginationResult<IContractRates>>();

  const [expensesData, setExpenseData] = React.useState<FlightExpenses>();
  const [updateFlightExpenses, setUpdateFlightExpenses] = React.useState(expensesData);
  //Recoil state
  const aircraftDetailsStateResult = useRecoilValue(aircraftDetailsState);

  const [ruleCode, setRuleCode] = React.useState('');
  const feature = 'AviationReporting';
  const api = 'CreateFlightReport';

  React.useEffect(() => {
    if (flightReport?.flightReportId === 0) {
      setIsUpdate(false);
      setIsView(false);
      setIsEdit(true);
    }
    else {
      (async () => {
        getOnRender();
      })();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flightReport]);

  React.useEffect(() => {
    if (!ruleCode) {
      validationEnginService.getRules(feature, api)
        .then((response: any) => {
          setRuleCode(response.frontEndCode)
        })
        .catch((e: Error) => {
          console.log("Error", e);
        });
    }
  });

  async function getOnRender()
  {
      setIsLoading(true);
      await getContractRates();
      await getFlyingHours();
      await getExpenses();
  }

  //#region "Flight report"

  function onFormValuesChange(value: FlightReport) {
    if (value != null && value !== undefined) {
      if (value.flightReportDate && value.corporateRegionId) setIsUpdate(true);
      else setIsUpdate(false);

      setUpdateFlightReport(value);
    }
  }

  async function onSubmit() {
    handleChildDataValidity(validate(ruleCode, "btnSave"));

    if (!isChildDataValid)
      return;

    setIsLoading(true);

    if (updateFlightReport?.flightReportId > 0) {
      //update last updated by & last updated on timestamp
      updateFlightReport.updateTimestamp = moment(new Date()).format(
        "yyyy-MM-DD"
      );
      updateFlightReport.updateUserId = sessionStorage
        .getItem("username")
        ?.toString();

      await FlightReportService.update(updateFlightReport as FlightReport)
        .then(async (response: any) => {
          //Get vendor
          var vendorObj = await getVendor(response.data.data.vendorId);
          const vendorResult = convertObjectFromOneSingleArrayValue(vendorObj as unknown as []);

          //Get corporate region
          var corporateRegion = getCorporateRegion(
            corporateRegionPaginationResult,
            response.data.data.corporateRegionId
          );

          //Get contract registration
          if (response.data.data.contractRegistrationId)
            var contractRegistrationObj = getContractRegistration(aircraftDetailsStateResult, response.data.data.contractRegistrationId)

          //Get flying registration
          if (response.data.data.flyingRegistrationId)
            var flyingRegistrationObj = getContractRegistration(aircraftDetailsStateResult, response.data.data.flyingRegistrationId)

          //response.data.data.flightReportId = updateFlightReport.flightReportId;
          setUpdateFlightReport(
            new FlightReport({
              ...response.data.data,
              corporateRegion: corporateRegion,
              vendor: vendorResult,
              contractRegistration: contractRegistrationObj,
              flyingRegistration: flyingRegistrationObj
            })
          );

          //pass update value to parent to FlightReportDetail
          onUpdate(updateFlightReport);

          setCalloutVariant(CalloutVariant.success);
          setNotificationMessage("Flight Report summary saved!");
          setShowNotificationMessage(true);
          setIsEdit(false);
          setIsView(true);

          setIsLoading(false);
        })
        .catch((error) => {
          setError(error);
        });
    } else {
      if (updateFlightReport) {
        //update created by & created on timestamp
        updateFlightReport.createTimestamp = moment(new Date()).format(
          "yyyy-MM-DD"
        );
        updateFlightReport.createUserId = sessionStorage
          .getItem("username")
          ?.toString();

        await FlightReportService.create(updateFlightReport)
          .then(async (response: any) => {
            if(response)
            {
              var timeUpDisplay = getTimeFormat(response.data.data?.timeUp);
              var timeDownDisplay = getTimeFormat(response.data.data?.timeDown);

              updateFlightReport.timeUpDisplay = timeUpDisplay;
              updateFlightReport.timeDownDisplay = timeDownDisplay;
              
              var createdFlightReport =new FlightReport({...updateFlightReport,flightReportId:response.data.data?.flightReportId});
              setUpdateFlightReport((p)=>{return new FlightReport(createdFlightReport)});

              //pass update value to parent to FlightReportDetail
              onUpdate(createdFlightReport);
              
              //send user notification
              setCalloutVariant(CalloutVariant.success);
              setNotificationMessage("Flight Report created!");
              setShowNotificationMessage(true);
              setIsEdit(false);
              setIsView(true);
              setIsLoading(false);
            }
          })
          .catch((error) => {
            setError(error);
          });
      }
    }
  }

  function onDiscardChanges() {
    //setTempFlightReport(new FlightReport({...tempFlightReport}));

    setUpdateFlightReport(new FlightReport({ ...tempFlightReport }));
    onUpdate(tempFlightReport);

    setIsUpdate(false);
    setShowModal(false);
  }

  function onCancel() {
    //setTempFlightReport(tempFlightReport !== undefined ? {...tempFlightReport} : undefined);

    setTempFlightReport(new FlightReport(tempFlightReport));
    onUpdate(tempFlightReport);
    setShowModal(false);
    setIsEdit(false);
    setIsView(true);
  }

  //#endregion "Flight report"

  //Flying hours
  function onFlyingHoursValuesChange(value: Array<FlyingHours>) {
    if (value != null && value !== undefined) {
      setIsFlyingHoursUpdate(true);

      setUpdateFlyingHours(value);
    }
  }

  async function onFlyingHoursSubmit() {
    setIsLoading(true);

    if (updateFlyingHours.length > 0 && flightReport?.flightReportId) {
      await FlyingHoursService.createUpdate(updateFlyingHours, flightReport?.flightReportId)
        .then(async (response: any) => {

          await getFlyingHours();

          setCalloutVariant(CalloutVariant.success);
          setNotificationMessage("Flying hours summary saved!");
          setShowNotificationMessage(true);
          setIsFlyingHoursEdit(false);

          setIsLoading(false);
        })
        .catch((error) => {
          setError(error);
        });
    }
  }

   
  //Flying Expenses
  function onFlightExpensesValuesChange(value: FlightExpenses) {
    if (value != null && value !== undefined) {
      setIsFlightExpensesUpdate(true);
      setUpdateFlightExpenses(value);
    }
  }

  async function onFlightExpensesSubmit() {
    setIsLoading(true);

    if (updateFlightExpenses && updateFlightExpenses) {
      await ExpenseService.createUpdate(updateFlightExpenses,flightReport?.flightReportId)
        .then(async (response: any) => {

          await getFlyingHours();

          setCalloutVariant(CalloutVariant.success);
          setNotificationMessage("Flying hours summary saved!");
          setShowNotificationMessage(true);
          setIsFlyingHoursEdit(false);

          setIsLoading(false);
        })
        .catch((error) => {
          setError(error);
        });
    }
  }

  //set validation error
  async function setError(error:any) {
      var validationError ="";
      
      if (axios.isAxiosError(error)) {
        if(error.response)
        {
          var validation = error.response.data as IValidationError;
          if(validation)
          {
            validation.validationResult?.forEach((record: any, index: any)=>{
              if(record)
                validationError += "<p>" + record.message + "</p>";
            })
          }
        }
      } else {
        console.error("else",error);
      }
      
      setCalloutVariant(CalloutVariant.emergency);
      setNotificationMessage(validationError);
      setShowNotificationMessage(true);

      setIsLoading(false);
  }

  async function getCrewTypeByID(crewTypeId: string) {
    try {
      var response: any = await CrewTypeService.getById(crewTypeId);
      return response.data;
    }
    catch (Error) {
      console.log("Error", Error);
    }
  }

  async function getCostingActivityByID(id: string) {
    try {
      var response: any = await CostingActivityService.getById(id);
      return response.data;
    }
    catch (Error) {
      console.log("Error", Error);
    }
  }

  async function getRateTypeByID(id: string) {
    try {
      var response: any = await RateTypeService.getById(id);
      return response.data;
    }
    catch (Error) {
      console.log("Error", Error);
    }
  }

  async function getRateUnitByID(rateUnitId: string) {
    try {
      var response: any = await RateUnitService.getById(rateUnitId);
      return response.data;
    }
    catch (Error) {
      console.log("Error", Error);
    }
  }

  async function getFireNumberByID(fireNumberId: string) {
    try {
      var response: any = await FireNumberService.getById(fireNumberId);
      if (response.status)
        return response.data.data;
    }
    catch (Error) {
      console.log("Error", Error);
    }
  }

  // #region "Flying hours"

  async function getContractRates() {
    setIsLoading(true);

    await HiringContractService.getRates(flightReport?.contractRegistration as IHiringContract, flightReport?.flightReportDate)
      .then((response: any) => {
        if (response.data) {
          setContractRatesPaginationResult(response.data);
        }
      })
      .catch((e: Error) => {
        setIsLoading(false);
      });
  }

  async function getExpenses() {
    
    ExpenseService.getById(flightReport?.flightReportId)
      .then((response: any) => {
        response.data.data.expenseDetails.forEach(async function (record: any, index: any) {
          if (record.fireNumberId) {
            var fireNumber = await getFireNumberByID(record.fireNumberId);
            record.fireNumber = fireNumber?.fireNumber;
          }
           
          if (record.costingActivityId) {
            var costingActivity = await getCostingActivityByID(record.costingActivityId);
            record.costingActivityCode = costingActivity?.code;
          }
          if (record.rateTypeId) {
            var rateType = await getRateTypeByID(record.rateTypeId);
            record.rateTypeEntity = rateType ?? undefined;
          }
          if (record.rateUnitId) {
            var rateUnit = await getRateUnitByID(record.rateUnitId);
            record.rateUnitEntity = rateUnit ?? undefined;
          }
        });
        setExpenseData(response.data.data)
      })
      .catch((e: Error) => {
        console.log("Error", e);
      });
  }


  async function getFlyingHours() {

    let objIPagination: IPagination = {
      perPage: 20,
      page: 1,
    };

    let objIFilter: IFilter = {
      columnName: "",
      columnValue: "",
    };

    let objISearch: ISearch = {
      search: "",
      sortBy: "",
      sortOrder: "",
      filterBy: objIFilter,
      pagination: objIPagination,
    };

    await FlyingHoursService.getSearch(objISearch, flightReport.flightReportId)
      .then(async (response: any) => {
        if (response.data) {
          //Fire number
          var fireNumberResponse : any = await FlyingDetailCRUD.getFireNumbers();
          const fireNumberResult = bindFireNumberDropdown(fireNumberResponse.data);
          //Crew type
          var crewTypeResponse : any = await FlyingDetailCRUD.getCrewTypes();
          const crewTypeResult = bindCrewTypeDropdown(crewTypeResponse.data);
          //Coting activity
          var costingActivityResponse : any = await FlyingDetailCRUD.getCostingActivities();
          const costingActivityResult = bindCostingActivityDropdown(costingActivityResponse.data);
          //Rate type
          var rateTypeResponse : any = await FlyingDetailCRUD.getRateTypes();
          const rateTypeResult = bindRateTypeDropdown(rateTypeResponse.data);
          //Rate unit
          var rateUnitResponse : any = await FlyingDetailCRUD.getRateUnits();
          const rateUnitResult = bindRateUnitDropdown(rateUnitResponse.data);
          
          //Set drop down option for add new
          setFireNumberOptions(fireNumberResult);
          setCrewTypeOptions(crewTypeResult);
          setCostingActivityOptions(costingActivityResult);
          setRateTypeOptions(rateTypeResult);
          setRateUnitOptions(rateUnitResult);

          //Bind data based on ID
          response.data.data?.forEach(async function (record: any, index: any) {
            if (record.fireNumberId) {
              var fireNumber = await getFireNumberByID(record.fireNumberId);
              record.fireNumberEntity = fireNumber ?? undefined;
              record.fireNumber = fireNumber?.fireNumber;
            }
            if (record.crewTypeId) {
              var crewType = await getCrewTypeByID(record.crewTypeId);
              record.crewTypeEntity = crewType ?? undefined;
            }
            if (record.costingActivityId) {
              var costingActivity = await getCostingActivityByID(record.costingActivityId);
              record.costingActivityEntity = costingActivity ?? undefined;
              record.costingActivityCode = costingActivity?.code;
            }
            if (record.rateTypeId) {
              var rateType = await getRateTypeByID(record.rateTypeId);
              record.rateTypeEntity = rateType ?? undefined;
            }
            if (record.rateUnitId) {
              var rateUnit = await getRateUnitByID(record.rateUnitId);
              record.rateUnitEntity = rateUnit ?? undefined;
            }

            //Drop down list bind
            record.fireNumberList = fireNumberResult ?? undefined;
            record.crewTypeList = crewTypeResult ?? undefined;
            record.costingActivityList = costingActivityResult ?? undefined;
            record.rateTypeList = rateTypeResult ?? undefined;
            record.rateUnitList = rateUnitResult ?? undefined;
            
            setFlyingHoursPaginationResult({ ...response.data });
          });

          //Set update flying hours records
          setUpdateFlyingHours(response.data.data);
          setTempFlyingHours(response.data.data);
          setIsLoading(false);
        }
      })
      .catch((e: Error) => {
        console.log(e);
        setIsLoading(false);
      });
  }
  //#endregion "Flying hours"

  return (
    <>
      <div className="white-background">



        {/* //view mode */}
        <div>
          <div>
            <ul>{errorItems}</ul>
          </div>
          <goa-accordion
            heading="Flight Report Summary"
            open={isView || isEdit}
          >
            <div
              style={{ display: "flex", justifyContent: "flex-end" }}
              slot="headingcontent"
            >
              {isView ? (
                <GoAButton size="compact"
                  type="tertiary"
                  {...{ style: '"height:0;z-index: 9999;"' }}
                  onClick={() => {
                    setIsUpdate(false);
                    setIsView(false);
                    setIsEdit(true);
                    onEdit(true);
                  }}
                >
                  Edit
                </GoAButton>
              ) : (
                <></>
              )}

              {/* Edit functionality button */}
              {isEdit ? (
                <GoAButton size="compact"
                  type="tertiary"
                  {...{ style: '"height:0;z-index: 9999;"' }}
                  disabled={!isChildDataValid}//{!isUpdate || !isChildDataValid}
                  onClick={() => {
                    onSubmit();
                  }}
                >
                  Save
                </GoAButton>
              ) : (
                <></>
              )}
              {isEdit ? (
                <GoAButton size="compact"
                  type="tertiary"
                  {...{ style: '"height:0;z-index: 9999;"' }}
                  onClick={() => {
                    if (flightReport?.flightReportId !== 0) {
                      if (isUpdate) {
                        setShowModal(true);
                      } else {
                        setIsEdit(false);
                        setIsView(true);
                        onEdit(false);
                      }
                    } else {
                      navigate("/dashboard");
                    }
                  }}
                >
                  Cancel
                </GoAButton>
              ) : (
                <></>
              )}
            </div>

            {isView ? (
              <ViewFlightReportSummary flightReport={updateFlightReport} />
            ) : (
              <></>
            )}

            <PageLoader visible={loading} />

            {isEdit ? (
              <EditFlightReportSummary
                ruleCode={ruleCode}
                flightReportList={flyingHoursPaginationResult}
                flightReport={updateFlightReport}
                corporateRegionPaginationResult={
                  corporateRegionPaginationResult
                }
                onChange={onFormValuesChange}
                onChildDataValidityChange={handleChildDataValidity}
              />
            ) : (
              <></>
            )}
          </goa-accordion>
        </div>
      </div>

      {/* #region "Flying hours section" */}

      <div className="flying-detail">
        <GoATabs>
          <GoATab heading="Flying hours">
            {!isFlyingHoursEdit && updateFlightReport.flightReportId > 0 ?
              <div className="right paddingRight20px">
                <GoAButton size="compact" type="tertiary"
                  onClick={() => { setIsFlyingHoursEdit(true); setIsFlyingHoursUpdate(false); }}>
                  Edit
                </GoAButton>
              </div>
              : <></>}

            {!isFlyingHoursEdit ?
              <div >
                <ViewFlyingDetail flyingHoursPaginationResult={flyingHoursPaginationResult} />
              </div>
              : <></>}


            {isFlyingHoursEdit ?
              <div className="right paddingRight20px">
                <GoAButton size="compact" type="tertiary"
                  disabled={!isFlyingHoursUpdate || !isChildDataValid}
                  onClick={() => onFlyingHoursSubmit()}>
                  Save
                </GoAButton>
                <GoAButton size="compact" type="tertiary"
                  onClick={() => {
                    setUpdateFlyingHours(tempFlyingHours);
                    setIsFlyingHoursEdit(false);
                  }}>
                  Cancel
                </GoAButton>
              </div>
              : <></>}

            {isFlyingHoursEdit ?
              <EditFlyingDetail flightReport={updateFlightReport} arrFlyingHours={updateFlyingHours} contractRatesPaginationResult={contractRatesPaginationResult}
                  fireNumberOptions={fireNumberOptions} crewTypeOptions={crewTypeOptions} costingActivityOptions={costingActivityOptions}
                  rateTypeOptions={rateTypeOptions} rateUnitOptions={rateUnitOptions}
                onChange={onFlyingHoursValuesChange} ruleCode={ruleCode} onChildDataValidityChange={handleChildDataValidity} />
              : <></>}

          </GoATab>

          {/* #endregion "Flying hours section" */}

         {/* #region "Expense section" */}

          <GoATab heading="Expenses">
          {!isFlightExpensesEdit && expensesData  ?
              <div className="right paddingRight20px">
                <GoAButton size="compact" type="tertiary"
                  onClick={() => { setIsFlightExpensesEdit(true); setIsFlightExpensesUpdate(false); }}>
                 Edit
                </GoAButton>
              </div>
              : <></>}

            {isFlightExpensesEdit ?
              <div className="right paddingRight20px">
                <GoAButton size="compact" type="tertiary"
                  disabled={!isFlightExpensesUpdate || !isChildDataValid}
                  onClick={() => onFlightExpensesSubmit()}>
                  Save
                </GoAButton>
                <GoAButton size="compact" type="tertiary"
                  onClick={() => {
                    setIsFlightExpensesEdit(false);
                  }}>
                  Cancel
                </GoAButton>
              </div>
              : <></>}
            <ExpenseSummary isEdit={isFlightExpensesEdit} flightExpenses={expensesData} contractRatesPaginationResult={contractRatesPaginationResult}
                  fireNumberOptions={fireNumberOptions} crewTypeOptions={crewTypeOptions} costingActivityOptions={costingActivityOptions}
                  rateTypeOptions={rateTypeOptions} rateUnitOptions={rateUnitOptions} onChange={onFlightExpensesValuesChange}/>
          </GoATab>

          {/* #endregion "Expense section" */}

        </GoATabs>
      </div>

     



      <GoAModal
        heading={modalHeading}
        calloutVariant={calloutVariant}
        open={showNotificationMessage}
        actions={
          <GoAButtonGroup alignment="end">
            <GoAButton onClick={() => setShowNotificationMessage(false)}>
              Okay
            </GoAButton>
          </GoAButtonGroup>
        }
      >
        <div dangerouslySetInnerHTML={{__html: notificationMessage}} />
      </GoAModal>

      <GoAModal
        heading="You have unsaved changes"
        open={showModal}
        actions={
          <GoAButtonGroup alignment="end">
            <GoAButton onClick={() => onCancel()}>Cancel</GoAButton>
            <GoAButton onClick={() => onDiscardChanges()}>
              Discard changes
            </GoAButton>
          </GoAButtonGroup>
        }
      >
        <p>Are you sure you want to discard changes?</p>
      </GoAModal>
    </>
  );
};

export default FlightReportSummary;

