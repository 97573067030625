import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from "react-oidc-context";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const oidcConfig: any = {
  authority: "https://access.adsp-dev.gov.ab.ca/auth/realms/e8a54e8b-ca4a-404a-9ba1-00b593cd3ff8",
  client_id: "wildfire-system-administration",
  //redirect_uri: "http://localhost:3000/dashboard",
  //redirect_uri: "https://wmtt-aviation-reporting-authenticate-dev.azurewebsites.net/",
  redirect_uri: "https://wmtt-aviation-reporting-authenticate-dev.azurewebsites.net/",
  pkce_method: "S256",
  extraQueryParams: {
    kc_idp_hint: "oidc"
  }
};

  

root.render(
  <AuthProvider {...oidcConfig} >
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </AuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
