
interface IFlightFuelUsage {
  flightFuelUsageId: string,
  provider: string;
  litres: string;
  doubleCrew: boolean;
  createTimestamp: string,
  createUserId: string,
  updateTimestamp?: null,
  updateUserId: string,
  deleted: boolean
}

interface IFlightCrewEligibility {
  flightCrewEligibilityId: number;
  flightReportId: string;
  name: string;
  role: string;
  breakfast: string;
  lunch: string;
  dinner: string;
  lodging: string;
  vehicle: string;
  createTimestamp: string;
  createUserId: string;
  updateTimestamp?: null;
  updateUserId: string;
  deleted: boolean;
}

interface IExpenseDetail {
  flightReportId: string;
  expenseId: string;
  fireNumberId: string;
  crewTypeOnBoardId: string;
  costingActivityId: string;
  rateTypeId: string;
  noOfUnits: string;
  ratePerUnit?: any;
  rateUnitId: string;
  cost: string;
  internalOrder: string;
  costCenter: string;
  fund: string;
  account: string;
  expenseType: string;
  fireNumber: string;
  costingActivityCode: string;
  rateUnitList?: { label: string, value: string }[];
  costingActivityList?: { label: string, code: string, value: string }[];
  rateTypeList?: { label: string, value: string }[];
  createTimestamp: string;
  createUserId?: string;
  fireNumberList?: { label: string; value: number; }[];
}

interface IFlightExpenses {
  flightFuelUsage: IFlightFuelUsage;
  flightCrewEligibility: IFlightCrewEligibility[];
  expenseDetails: IExpenseDetail[];
}



export class FlightFuelUsage implements IFlightFuelUsage {
  constructor(from?: Partial<IFlightFuelUsage>) {
    this.flightFuelUsageId = from?.flightFuelUsageId ?? '';
    this.provider = from?.provider ?? '';
    this.litres = from?.litres ?? '0';
    this.doubleCrew = from?.doubleCrew ?? false;
    this.createTimestamp = from?.createTimestamp ?? '';
    this.createUserId = from?.createUserId ?? '';
    this.updateUserId = from?.updateUserId ?? '';
    this.updateTimestamp= from?.updateTimestamp ?? null;
  }
  deleted!: boolean;
  flightFuelUsageId: string = '';
  provider: string = '';
  litres: string = '0';
  doubleCrew: boolean = false;
  createTimestamp: string = "";
  createUserId: string = "";
  updateTimestamp?: null;
  updateUserId: string = "";
}

export class FlightCrewEligibility implements IFlightCrewEligibility {
  flightCrewEligibilityId: number;
  flightReportId: string = '';
  name: string = '';
  role: string | string = '';
  breakfast: string = '';
  lunch: string = '';
  dinner: string = '';
  lodging: string = '';
  vehicle: string = '';
  createTimestamp: string;
  createUserId: string;
  updateTimestamp?: null;
  updateUserId: string;
  deleted: boolean;
  constructor(from?: Partial<FlightCrewEligibility>) {
    this.flightCrewEligibilityId = from?.flightCrewEligibilityId || 0;
    this.flightReportId = from?.flightReportId || '';
    this.name = from?.name || '';
    this.role = from?.role || '';
    this.breakfast = from?.breakfast || '';
    this.lunch = from?.lunch || '';
    this.dinner = from?.dinner || '';
    this.lodging = from?.lodging || '';
    this.vehicle = from?.vehicle || '';
    this.createTimestamp = from?.createTimestamp ?? '';
    this.createUserId = from?.createUserId ?? '';
    this.updateTimestamp = null;
    this.updateUserId = from?.updateUserId ?? '';
    this.deleted = from?.deleted ?? false;
  }
}

export class ExpenseDetail implements IExpenseDetail {
  flightReportId: string = '';
  expenseId: string = '';
  fireNumberId: string = '';
  crewTypeOnBoardId: string = '';
  costingActivityId: string = '';
  rateTypeId: string = '';
  noOfUnits: string = '';
  ratePerUnit?: any;
  rateUnitId: string = '';
  cost: string = '';
  internalOrder: string = '';
  costCenter: string = '';
  fund: string = '';
  account: string = '';
  expenseType: string = '';
  fireNumber: string = '';
  costingActivityCode: string = '';
  rateUnitList?: { label: string, value: string }[];
  costingActivityList?: { label: string, code: string, value: string }[];
  rateTypeList?: { label: string, value: string }[];
  createTimestamp: string = '';
  createUserId?: string = '';
  updateTimestamp?: null;
  fireNumberList?: { label: string; value: number; }[];

  constructor(from?: Partial<ExpenseDetail>) {
    this.flightReportId = from?.flightReportId || '';
    this.expenseId = from?.expenseId || '';
    this.fireNumberId = from?.fireNumberId || '';
    this.crewTypeOnBoardId = from?.crewTypeOnBoardId || '';
    this.costingActivityId = from?.costingActivityId || '';
    this.rateTypeId = from?.rateTypeId || '';
    this.noOfUnits = from?.noOfUnits || '';
    this.ratePerUnit = from?.ratePerUnit || '';
    this.rateUnitId = from?.rateUnitId || '';
    this.cost = from?.cost || '';
    this.internalOrder = from?.internalOrder || '';
    this.costCenter = from?.costCenter || '';
    this.fund = from?.fund || '';
    this.account = from?.account || '';
    this.expenseType = from?.expenseType || '';
    this.fireNumber = from?.fireNumber || '';
    this.costingActivityCode = from?.costingActivityCode || '';
    this.rateUnitList = from?.rateUnitList || [];
    this.rateTypeList = from?.rateTypeList || [];
    this.createTimestamp = from?.createTimestamp || '';
    this.createUserId = from?.createUserId || '';
    this.updateTimestamp = from?.updateTimestamp || null;
    this.fireNumberList = from?.fireNumberList || [];

  }
}

export class FlightExpenses implements IFlightExpenses {
  constructor(from?: Partial<IFlightExpenses>) {
    this.flightFuelUsage = from?.flightFuelUsage ?? new FlightFuelUsage();
    this.flightCrewEligibility = from?.flightCrewEligibility ?? [new FlightCrewEligibility()];
    this.expenseDetails = from?.expenseDetails ?? [new ExpenseDetail()];

  }

  flightFuelUsage: FlightFuelUsage = new FlightFuelUsage();
  flightCrewEligibility: FlightCrewEligibility[] = [new FlightCrewEligibility()];
  expenseDetails: ExpenseDetail[] = [new ExpenseDetail()];
}


