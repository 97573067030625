import { httpAviationReporting } from "../http-common";
import { IHiringContract } from "../model/HiringContract/IHiringContract";
import { IPaginationResult } from "../model/search/IPaginationResult";
import { ISearch } from "../model/search/ISearch";

//Search
const getSearch = async (objISearch: ISearch) => {
  const params = JSON.stringify({
    filterBy: {
      columnName: objISearch.filterBy?.columnName,
      columnValue: objISearch.filterBy?.columnValue,
    },
    paginationInfo: {
      perPage: objISearch.pagination.perPage,
      page: objISearch.pagination.page,
    },
  });

  const data = await httpAviationReporting.post<IPaginationResult<IHiringContract>>(
    "/aircrafts-service/hiring/contracts/getByDate",
    params,
    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("api_token")}`,
      },
    }
  );

  return data;
};

const getRates = async (IHiringContract: IHiringContract, flightReportDate:string) => {
  const params = JSON.stringify({
    aircraftRegistration:IHiringContract.aircraftRegistration,
    reportDate:flightReportDate,
    contractNumber:IHiringContract.contractNumber,
    contractId:IHiringContract.contractId,
    contractType:IHiringContract.contractType
  });

  const data = await httpAviationReporting.post<IPaginationResult<IHiringContract>>(
    "/aircrafts-service/hiring/contracts/rates",
    params,
    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("api_token")}`,
      },
    }
  );

  return data;
};

const getByName = async (objISearch: ISearch) => {
  const params = JSON.stringify({
    search: objISearch.search,
    paginationInfo: {
      perPage: objISearch.pagination.perPage,
      page: objISearch.pagination.page,
    },
  });

  const data = await httpAviationReporting.post<IPaginationResult<IHiringContract>>(
    "/aircrafts-registration/getByName",
    params,
    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("api_token")}`,
      },
    }
  );

  return data;
};

export const HiringContractService = {
  getSearch,
  getRates,
  getByName
};


export const bindSelectContractRegistration = (hiringContractResult:IHiringContract[]) : {label:string | undefined,value:number | undefined}[] => {
    var options = [];
    
    if(hiringContractResult != null)
    {
      for (let i = 0; i < hiringContractResult.length; i++) {
        let obj = {label: hiringContractResult[i].aircraftRegistration, value: hiringContractResult[i].id};
        options.push(obj);
      }
    }

    return options;
  }

//Hiring Contract - Contract registration, 
export const getContractRegistration = (hiringContracts: IHiringContract[], contractRegistrationId:number) => {
  if(hiringContracts)
  {
    var result = hiringContracts.find((value) => value.id === contractRegistrationId);
    return result;
  }
  else{
    return undefined;
  }
}
