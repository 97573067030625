import { GoAFormItem } from '@abgov/react-components';
import * as React from 'react';

interface IViewDoubleCrewProps {
  doubleCrewData:any
}

const ViewDoubleCrew: React.FunctionComponent<IViewDoubleCrewProps> = ({doubleCrewData,...props}) => {
  return(
    <>
      <div className="row accordion flight-crew">
        <div>
          <div>
            <h3 className="insideHeader expense-section-header">Double Crew</h3>
          </div>
          <div className="content">
            <div className='width100 col-md-6'>
              <GoAFormItem label="">
                <label>{doubleCrewData?.doubleCrew===false?"No":"Yes"}</label>
              </GoAFormItem>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewDoubleCrew;
