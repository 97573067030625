import { httpBff } from "../../http-common";

const getClaims = async () => {
  const data = await httpBff.get("/bff/user");

  return data;
};

export const parseToken = (token: string) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
}
export const hasResourceRole = (resource: string, role: string, token: string): boolean => {
  const tokenObj = parseToken(token);
  const resourceAccess = tokenObj.resource_access;
  if (!resourceAccess) return false;
  const roles = resourceAccess[resource || '']?.roles || [];
  
  return roles.indexOf(role) !== -1;
}

export const AuthenticationService = {
  getClaims,
};
