import React, { useEffect } from "react";
// import logo from './logo.svg';
import "./App.css";
import { BrowserRouter } from "react-router-dom";
import Layout from "./components/Layout";
import {RecoilRoot} from 'recoil';
import { useAuth } from "react-oidc-context";

function App() {
  const auth = useAuth();
  useEffect(() => {
    if (!auth.isLoading && !auth.isAuthenticated) {
      auth.signinRedirect();
    }
  }, [auth]);
  
  return (
    <RecoilRoot>
      <BrowserRouter>
        <Layout />
      </BrowserRouter>
    </RecoilRoot>
  );
}

export default App;
