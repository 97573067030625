import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import * as React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Dashboard from "../pages/dashboard/Dashboard";

import FlightReportDetail from "../pages/flightReport/FlightReportDetail";
import NotFound from "../pages/notFound/NotFound";
import Test from "../pages/test/Test";
import AppHeader from "./AppHeader";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ILayoutProps {}

const Layout: React.FunctionComponent<ILayoutProps> = (props) => {
  const location = useLocation();
  const [isBackground, setBackground] = React.useState(false);
  const queryClient = new QueryClient();

  React.useEffect(() => {
    if (
      location.pathname !== "" &&
      location.pathname.includes("/flightReportDetail")
    ) {
      setBackground(true);
    } else {
      setBackground(false);
    }
  }, [location.pathname]);

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <div className="wrapper">
          <div className="row header">
            <AppHeader />
          </div>

          <div
            className={
              isBackground === true
                ? "row container detail-Container"
                : "row container"
            }
          >
            <div className="content col-12">
              <Routes>
                <Route path="/" element={<Dashboard />} />
                <Route path="/dashboard" element={<Dashboard />} />

                {/* Aviation reporting */}
                <Route
                  path="/flightReportDetail/:id"
                  element={<FlightReportDetail />}
                />
                <Route
                  path="/flightReportDetail"
                  element={<FlightReportDetail />}
                />
                <Route path="/test" element={<Test />} />
                {/* <Route
                path="/flightReportDetail/new"
                element={<FlightReportDetail />}
              /> */}
                {/* <Route
                path="/editFlightReport/:id"
                element={<EditFlightReportSummary flightReport={null} />}
              /> */}

                {/* <Route path="/test" element={<Test />} /> */}
                {/* <Route path="/domainTables" element={<DomainTables />} />
                      <Route path="/rateType" element={ <RateType /> } />

                      <Route path="/test" element={ <Test /> } /> */}
                <Route path="*" element={<NotFound />} />
              </Routes>
            </div>
          </div>

          {/* <div className={isBackground === false ? "row col-12 footer-absolute" : "row col-12 footer"}> */}
          {/* <div className="row col-12 footer-absolute"> */}
          {/* <AppFooter />
        </div> */}
        </div>
      </QueryClientProvider>
    </>
  );
};

export default Layout;
