import {httpAviationReporting, httpAviationReportingAuthentication} from "../http-common";
import { IFlightReport } from "../model/IFlightReport";
import { IPaginationResult } from "../model/search/IPaginationResult";
import { ISearch } from "../model/search/ISearch";

// const config = {
//   withCredentials: true,
//   headers: {
//     Accept: "application/json",
//     "Content-Type": "application/json",
//     "X-CSRF": "1",
//   },
// };

const getAuthenticate = async () => {
  const data = await httpAviationReportingAuthentication.post<string>(
    "/authentication/authenticate",
    {
      username: "andrew.mitchell",
      password: "password",
    }
  );

  return data;
};

//Search
// const getSearch = async (objISearch: ISearch) => {
//   const params = JSON.stringify({
//     search: objISearch.search,
//     sortBy: objISearch.sortBy,
//     sortOrder: objISearch.sortOrder,
//     filterBy: {
//       columnName: "",
//       columnValue: "",
//       reportDateFrom:objISearch.filterBy?.reportDateFrom,
//       reportDateTo:objISearch.filterBy?.reportDateTo,
//       corporateRegion:objISearch.filterBy?.corporateRegion,
//     },
//     paginationInfo: {
//       perPage: objISearch.pagination.perPage,
//       page: objISearch.pagination.page,
//     },
//   });

//   const data = await httpAviationReporting.post<IPaginationResult<IFlightReport>>(
//     "/flight-report/get",
//     params,
//     {
//       headers: {
//         Authorization: `Bearer ${sessionStorage.getItem("api_token")}`,
//       },
//     }
//   );
  
//   return data;
// };

const getSearch = async (objISearch: ISearch, token:string = "") => {
  const params = JSON.stringify({
    search: objISearch.search,
    sortBy: objISearch.sortBy,
    sortOrder: objISearch.sortOrder,
    filterBy: {
      columnName: objISearch.filterBy?.columnName,
      columnValue: objISearch.filterBy?.columnValue,
      reportDateFrom:objISearch.filterBy?.reportDateFrom,
      reportDateTo:objISearch.filterBy?.reportDateTo,
      corporateRegions:objISearch.filterBy?.corporateRegion,
    },
    paginationInfo: {
      perPage: objISearch.pagination.perPage,
      page: objISearch.pagination.page,
    },
  });

  const data = await httpAviationReporting.post<
    IPaginationResult<IFlightReport>
  >(
    "https://apim.wildfireapps-dev.alberta.ca/flight-reports-test-api-dev/api/v1/flight-report-dashboard/get",
    params,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRF": "1",
      },
    }
  );
  
  return data;
};

const getById = async (id: any) => {
  // const data = await httpAviationReporting.post<IFlightReport>(
  //   `/flight-report/get/${id}`,
  //   "",
  //   {
  //     headers: {
  //       Authorization: `Bearer ${sessionStorage.getItem("api_token")}`,
  //     },
  //   }
  // );

  const data = await httpAviationReporting.post<
    IPaginationResult<IFlightReport>
  >(
    `https://apim.wildfireapps-dev.alberta.ca/flight-reports-test-api-dev/api/v1/flight-report/get/${id}`,
    "",
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRF": "1",
      },
    }
  );

  return data;
};

const update = async (flightReport:IFlightReport) => {
  const params = JSON.stringify(flightReport);

  const data = await httpAviationReporting.put<IFlightReport>(
    `/flight-report/update/${flightReport.flightReportId}`,
    params,
    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("api_token")}`,
      },
    }
  );
  return data;

};

const create = async (flightReport:IFlightReport) => {
  const params = JSON.stringify(flightReport);

  const data = await httpAviationReporting.post<IFlightReport>(
    "/flight-report/create",
    params,
    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("api_token")}`,
      },
    }
  );
  return data;

};

export const FlightReportService = {
  getAuthenticate,
  getSearch,
  getById,
  update,
  create,
};
