import {
  GoAFormItem,
  GoAGrid,
  GoAInputDate,
  GoAInputText,
  GoARadioGroup,
  GoARadioItem,
  GoATextArea
} from "@abgov/react-components";
import moment from "moment";
import * as React from "react";
import {
  useRecoilValue, useSetRecoilState,
} from 'recoil';
import { FlightReport } from "../../model/IFlightReport";
import { bindMutltiSelectDropdown, getCorporateRegion } from "../../services/CorporateRegionDomainService";
import { bindSelectContractRegistration, getContractRegistration } from "../../services/HiringContractService";
import { aircraftDetailsState } from "../../state/DomainService.state";
import { IPaginationResult } from "../../model/search/IPaginationResult";
import { IFlyingHours } from "../../model/IFlyingHours";
import Select from "react-select";
import { VendorService } from "../../services/VendorService";
import { validate } from "../../utils/commonMethods";
import { FlightReportCRUD } from "../../operations/FlightReportCRUD";

interface IEditFlightReportSummaryProps {
  ruleCode: string,
  flightReportList: IPaginationResult<IFlyingHours> | undefined,
  flightReport: FlightReport;
  corporateRegionPaginationResult: any;
  visible?: boolean;
  onChildDataValidityChange: any;
  onChange: (value: FlightReport) => void;
}

const EditFlightReportSummary: React.FunctionComponent<
  IEditFlightReportSummaryProps
> = ({
  ruleCode,
  flightReportList,
  flightReport,
  corporateRegionPaginationResult,
  visible,
  onChange,
  onChildDataValidityChange,
  ...props
}) => {
    //const $: JQueryStatic = jquery;

    const [formValues, setFormValues] = React.useState(new FlightReport(flightReport));
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [maxDate, setMaxDate] = React.useState(new Date());
    //const [maxDate, setMaxDate] = React.useState(getDateWithMonthOffset(1));

    const [forestAreaOptions, setForestAreaOptions] = React.useState<{ label: string, value: string }[]>([]);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [flightReportDateError, setFlightReportDateError] = React.useState(false);

    //Contract registration
    const setAircraftDetails = useSetRecoilState(aircraftDetailsState);
    const aircraftDetails = useRecoilValue(aircraftDetailsState);
  

    const [contractRegistrationOptions, setContractRegistrationOptions] = React.useState<{ label: string | undefined, value: number | undefined }[]>([]);
    //Flying registration
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [flyingRegistrationOptions, setFlyingRegistrationOptions] = React.useState<{ label: string | undefined, value: number | undefined }[]>([]);

    React.useEffect(() => {
      if (corporateRegionPaginationResult !== null) {
        const forestAreaResult = bindMutltiSelectDropdown(corporateRegionPaginationResult);
        setForestAreaOptions(forestAreaResult);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {

      setFormValues(flightReport);

      //bind Contract Registration & Flying Registration
      bindAircraftRegistration();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [flightReport])


    function bindAircraftRegistration() {
      if (aircraftDetails !== undefined) {
        const contractRegistrationResult = bindSelectContractRegistration(aircraftDetails);
        const flyingRegistrationResult = bindSelectContractRegistration(aircraftDetails);
        if (contractRegistrationResult) {
          setContractRegistrationOptions(contractRegistrationResult);
          setFlyingRegistrationOptions(flyingRegistrationResult);
        }
      }
    }

    //#endregion "Hiring Contract"

    //on property values change
    function onPropertyChange(value: Partial<FlightReport>) {
      let newValue = new FlightReport();

      newValue = new FlightReport({ ...flightReport, ...value });

      setFormValues(newValue);
      //send on change to parent component 'Flight report summary'
      onChange(newValue);
    }


    function handleKeyUp(elementName: string) {
      // Notify the parent about the validity
      onChildDataValidityChange(validate(ruleCode, elementName, "keyup"));
    }


    async function handleDateChange(elementName: string) {

      onChildDataValidityChange(validate(ruleCode, elementName, "onChange"));

      const startDateElement = document.querySelector('[name="startDate"]') as HTMLInputElement;
      const selectedDate = startDateElement.value;

      if (selectedDate) {
        let dtResult = moment(selectedDate).format("yyyy-MM-DD");
        let splitDate = dtResult.split('-');

        if (parseInt(splitDate[0]) < 1950)
          setFlightReportDateError(true);
        else if (parseInt(splitDate[2]) > 31)
          setFlightReportDateError(true);
        else {
          //Get contract registartion & flying registration based on flight report date
          var aircraftDetails = await FlightReportCRUD.getHiringAircraft(dtResult);
          setAircraftDetails(aircraftDetails);

          setFlightReportDateError(false);
          onPropertyChange({ flightReportDate: dtResult });
        }
      }
      else {
        onPropertyChange({ flightReportDate: "" });
      }
    }
    
    return (
      <>
        <div className="row accordion">
          <div className="column firstSection">
            <div>
              <h3 className="insideHeader">Flight Time & Location</h3>
            </div>

            <div className="form-row">
              <div className="col-50">
                <div>
                  <label className="label">
                    Date<span className="mandatory">*</span>
                    <em>(required)</em>
                  </label>
                </div>

                <GoAInputDate
                  name="startDate"
                  placeholder="YYYY-MM-DD"
                  value={formValues?.flightReportDate != null || formValues?.flightReportDate !== "" ? formValues?.flightReportDate : new Date()}
                  min={new Date(1950, 1, 1)}
                  max={maxDate}
                  width="100%"
                  //error={formValues.errorState.flightReportDate}
                  onChange={(name, value) => {
                    handleDateChange(name)
                  }}
                />
              </div>
              <div className="col-50">
                <GoAFormItem label="Time Up / Down">
                  <div className="left width50 paddingRight10px">
                    <GoAInputText
                      name="txtTimeUp"
                      value={formValues?.timeUpDisplay ?? ""}
                      width="100%"
                      maxLength={7}
                      onChange={(name, value) => {
                        onPropertyChange({ timeUpDisplay: value });
                        var momentObj = moment(formValues.flightReportDate + value, 'YYYY-MM-DDLT');
                        // conversion
                        onPropertyChange({ timeUp: moment(momentObj).format("YYYY-MM-DDTHH:mm:ss") });
                        handleKeyUp(name);
                      }}
                    />
                  </div>
                  <div className="left width50 paddingLeft10px">
                    <GoAInputText
                      name="txtTimeDown"
                      value={formValues?.timeDownDisplay ?? ""}
                      width="100%"
                      maxLength={7}
                      onChange={(name, value) => {
                        onPropertyChange({ timeDownDisplay: value });
                        var momentObj = moment(formValues.flightReportDate + value, 'YYYY-MM-DDLT');
                        // conversion
                        onPropertyChange({ timeDown: moment(momentObj).format("YYYY-MM-DDTHH:mm:ss") });
                        handleKeyUp(name);
                      }}
                    />
                  </div>
                </GoAFormItem>
              </div>
            </div>

            <div className="form-row">
              <div className="col-50">
                <GoAFormItem label="Meter Start / Finish">
                  <div className="left width50 paddingRight10px">
                    <GoAInputText
                      name="txtMeterStart"
                      value={formValues?.meterStart != null ? formValues?.meterStart : ""}
                      width="100%"
                      maxLength={10}
                      onChange={(name, value) => {
                        onPropertyChange({ meterStart: value });
                        handleKeyUp(name);
                      }}
                    />
                  </div>
                  <div className="left width50 paddingLeft10px">
                    <GoAInputText
                      name="txtMeterFinish"
                      value={formValues?.meterFinish != null ? formValues?.meterFinish : ""}
                      width="100%"
                      maxLength={10}
                      onChange={(name, value) => {
                        onPropertyChange({ meterFinish: value });
                        handleKeyUp(name);
                      }}
                    />
                  </div>
                </GoAFormItem>
              </div>
              <div className="col-50">
                <GoAFormItem label="Meter Difference">
                  <GoAInputText
                    name="txtMeterDifference"
                    value={formValues?.meterStart && formValues?.meterFinish ? (formValues?.meterFinish - formValues?.meterStart).toFixed(2) : ""}
                    width="100%"
                    disabled={true}
                    onChange={(name, value) => { }}
                  />
                </GoAFormItem>
              </div>
            </div>

            <div className="form-row">
              <div className="col-50">
                <div>
                  <label className="label">
                    Forest Area<span className="mandatory">*</span>
                    <em>(required)</em>
                  </label>
                </div>

                {/* <GoADropdown name="selForestArea" placeholder="--Select--"
                  value={selectedForestArea}
                  onChange={onDropdownChange}>
                  {forestAreaOptions.map(item => (<GoADropdownItem key={item.value} value={item.value} label={item.label} />))}
                </GoADropdown> */}
                <Select
                  name="selForestArea"
                  options={forestAreaOptions}
                  placeholder="--Select--"
                  className="width100"
                  value={forestAreaOptions.find(t => t.value === formValues.corporateRegion?.corporateRegionId)}
                  onChange={(value: any) => {
                    onPropertyChange({ corporateRegion: getCorporateRegion(corporateRegionPaginationResult, value.value) ?? undefined, corporateRegionId: value.value });
                    onChildDataValidityChange(validate(ruleCode, "selForestArea", "onChange", value));
                  }}
                  isSearchable={true}
                />
              </div>

              <div className="col-50">
                <GoAFormItem label="Point of Hire">
                  <GoAInputText
                    name="txtPointOfHire"
                    value={formValues?.contractRegistration?.pointOfHire ?? ""}
                    width="100%"
                    disabled={true}
                    onChange={(name, value) => { }}
                  />
                </GoAFormItem>
              </div>
            </div>

            <div className="form-row">
              <div className="col-50">
                <GoAFormItem label="Point of Departure">
                  <GoAInputText
                    name="txtPointOfDeparture"
                    value={formValues?.pointOfDeparture ?? ""}
                    width="100%"
                    maxLength={20}
                    onChange={(name, value) => {
                      onPropertyChange({ pointOfDeparture: value.slice(0, 20) });
                    }}
                  />
                </GoAFormItem>
              </div>
              <div className="col-50">
                <GoAFormItem label="Down For Night Location">
                  <GoAInputText
                    name="downForNightLocation"
                    value={formValues?.downForNightLocation ?? ""}
                    width="100%"
                    maxLength={20}
                    onChange={(name, value) => {
                      onPropertyChange({ downForNightLocation: value.slice(0, 20) });
                    }}
                  />
                </GoAFormItem>
              </div>
            </div>

            <div className="form-row">
              <div className="col-50">
                <GoAFormItem label="AO02 Number">
                  <GoAInputText
                    name="AO02Number"
                    value={formValues?.ao02Number as string}
                    width="100%"
                    maxLength={10}
                    onChange={(name, value) => {
                      onPropertyChange({ ao02Number: value.slice(0, 10) });
                    }}
                  />
                </GoAFormItem>
              </div>
            </div>

          </div>

          <div className="column secondSection">
            <div>
              <h3 className="insideHeader">Vendor & Aircraft</h3>
            </div>

            <div className="form-row">
              <div className="col-50">
                <div>
                  <label className="label">
                    Contract Registration<span className="mandatory">*</span>
                    <em>(required)</em>
                  </label>
                </div>
                <Select
                  name="selContractRegistration"
                  options={contractRegistrationOptions}
                  placeholder="--Select--"
                  className="width100"
                  isDisabled={!formValues.flightReportDate}
                  value={contractRegistrationOptions.find(t => t.value === formValues.contractRegistration?.id)}
                  onChange={async (value: any) => {
                    if(value)
                    {
                      var contractRegistration = getContractRegistration(aircraftDetails, value.value);

                      if(contractRegistration && contractRegistration.staId)
                        var vendor : any = await VendorService.getByStackholderId(contractRegistration.staId);
                      
                      onPropertyChange({ contractRegistration: contractRegistration ?? undefined, contractRegistrationId: value.value, 
                          vendor:vendor?.data[0], vendorId: vendor?.data[0].vendorId, flyingRegistration:contractRegistration ?? undefined, flyingRegistrationId:value.value });
                      onChildDataValidityChange(validate(ruleCode, "selContractRegistration", "onChange", value));}}
                    }
                  onInputChange={(value:any)=>{
                    if(value)
                      contractRegistrationOptions.find(t => t.value === value)
                  }}
                  isSearchable={true}
                />
                
              </div>
              <div className="col-50">
                <div>
                  <label className="label">
                    Flying Registration<span className="mandatory">*</span>
                    <em>(required)</em>
                  </label>
                </div>
                <Select
                  name="selFlyingRegistration"
                  options={flyingRegistrationOptions}
                  placeholder="--Select--"
                  className="width100"
                  isDisabled={!formValues.flightReportDate}
                  value={flyingRegistrationOptions.find(t => t.value === formValues.flyingRegistration?.id)}
                  onChange={(value: any) => {onPropertyChange({ flyingRegistration: getContractRegistration(aircraftDetails, value.value) ?? undefined, flyingRegistrationId: value.value });
                  onChildDataValidityChange(validate(ruleCode, "selFlyingRegistration", "onChange", value));}}
                  onInputChange={(value:any)=>{
                    if(value)
                      flyingRegistrationOptions.find(t => t.value === value)
                  }}
                  isSearchable={true}
                />

                {/* <GoADropdown name="selFlyingRegistration" placeholder="--Select--"
                  value={
                    (flyingRegistrationOptions.find(t => t.value === formValues.flyingRegistration?.id) as unknown as { label: string; value: string })?.value || ''
                  }
                  onChange={onDropdownChange}>
                  {flyingRegistrationOptions.map(item => (<GoADropdownItem key={item.value} value={item.value?.toString() ?? ''}
                    label={item.label} />))}
                </GoADropdown> */}
                
              </div>
            </div>

            <div className="form-row">
              <div className="col-75">
                <GoAFormItem label="Vendor">
                  <GoAInputText
                    name="txtVendor"
                    value={formValues?.vendor?.name ?? ""}
                    width="100%"
                    maxLength={20}
                    disabled={true}
                    onChange={(name, value) => {
                      onPropertyChange({ downForNightLocation: value.slice(0, 20) });
                    }}
                  />
                </GoAFormItem>
              </div>
            </div>


            <div className="form-row">
              <div className="col-50">
                <GoAFormItem label="Aircraft Type">
                  <GoAInputText
                    name="txtAircraftType"
                    value={formValues?.contractRegistration?.atType ?? ""}
                    width="100%"
                    disabled={true}
                    onChange={(name, value) => { }}
                  />
                </GoAFormItem>
              </div>
              <div className="col-50">
                <GoAFormItem label="Contract Number">
                  <GoAInputText
                    name="txtContractNumber"
                    value={formValues?.contractRegistration?.contractNumber ?? ""}
                    width="100%"
                    disabled={true}
                    onChange={(name, value) => { }}
                  />
                </GoAFormItem>
              </div>
            </div>

            <div className="form-row">
              <div className="col-50">
                <GoAFormItem label="Hire Start Date">
                  <GoAInputText
                    name="txtHireStartDate"
                    value={formValues?.contractRegistration ? moment(formValues?.contractRegistration.effectiveDate).format("yyyy-MM-DD") : ""}
                    width="100%"
                    disabled={true}
                    onChange={(name, value) => { }}
                  />
                </GoAFormItem>
              </div>
              <div className="col-50">
                <GoAFormItem label="Hire End Date">
                  <GoAInputText
                    name="txtHireEndDate"
                    value={formValues?.contractRegistration ? moment(formValues?.contractRegistration.terminationDate).format("yyyy-MM-DD") : ""}
                    width="100%"
                    disabled={true}
                    onChange={(name, value) => { }}
                  />
                </GoAFormItem>
              </div>
            </div>


            <div className="form-row">
              <div className="col-50">
                <GoAFormItem label="Pilot remarks">
                  <GoATextArea
                    value={formValues?.remark}
                    name="comment"
                    rows={3}
                    width="100%"
                    onChange={(name, value) => {
                      onPropertyChange({ remark: value });
                    }}
                  />
                </GoAFormItem>
              </div>
            </div>

          </div>

          <div className="column thirdSection">
            <div>
              <h3 className="insideHeader">Finance</h3>
            </div>
            <GoAGrid minChildWidth="30ch" gap="m">
              <div>
                <GoAFormItem label="Daily Basing">
                  <div className="left">
                    <GoARadioGroup name="dailyBasing" value={formValues?.dailyBasingIndicator === true ? "Yes" : "No"} orientation="horizontal"
                      onChange={(_name, value) => { let result = value === "Yes" ? true : false; onPropertyChange({ dailyBasingIndicator: result }); }}>
                      <GoARadioItem value="Yes" name={"Yes"} />
                      <GoARadioItem value="No" name={"No"} />
                    </GoARadioGroup>
                  </div>
                </GoAFormItem>
              </div>
              <div>
                <GoAFormItem label="Unused Minimums">
                  <div className="left">
                    <GoARadioGroup name="unusedMinimums" value={formValues?.unusedMinsAppliedInt === true ? "Yes" : "No"} orientation="horizontal"
                      onChange={(_name, value) => { let result = value === "Yes" ? true : false; onPropertyChange({ unusedMinsAppliedInt: result }); }}>
                      <GoARadioItem value="Yes" name={"Yes"} />
                      <GoARadioItem value="No" name={"No"} />
                    </GoARadioGroup>
                  </div>
                </GoAFormItem>
              </div>
              <div>
                <GoAFormItem label="Double Flight Crew">
                  <div className="left">
                    <GoARadioGroup name="doubleFlightCrew" value={formValues?.doubleCrewInd === true ? "Yes" : "No"} orientation="horizontal"
                      onChange={(_name, value) => { let result = value === "Yes" ? true : false; onPropertyChange({ doubleCrewInd: result }); }}>
                      <GoARadioItem value="Yes" name={"Yes"} />
                      <GoARadioItem value="No" name={"No"} />
                    </GoARadioGroup>
                  </div>
                </GoAFormItem>
              </div>
              <div>
                <GoAFormItem label="No Fly Day">
                  <div className="left">
                    <GoARadioGroup name="noFlyDay" value={formValues?.noFlyDay === true ? "Yes" : "No"} orientation="horizontal"
                      onChange={(_name, value) => { let result = value === "Yes" ? true : false; onPropertyChange({ noFlyDay: result }); }}>
                      <GoARadioItem value="Yes" name={"Yes"} />
                      <GoARadioItem value="No" name={"No"} />
                    </GoARadioGroup>
                  </div>
                </GoAFormItem>
              </div>
            </GoAGrid>
          </div>
        </div>

      </>
    );
  };

export default EditFlightReportSummary;
