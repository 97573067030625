import { IContractRates } from "../model/HiringContract/IContractRates";
import { IPaginationResult } from "../model/search/IPaginationResult";

//Contract Rates, 
export const getContractRates = (contractRatesPaginationResult: IPaginationResult<IContractRates>, rateTypeId:number) => {
    if(contractRatesPaginationResult != null)
    {
      const result = contractRatesPaginationResult.data.find(value => value.crtyId === rateTypeId);
      return result;
    }
    else{
      return undefined;
    }
}