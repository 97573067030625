import { GoAButton, GoAIcon } from '@abgov/react-components';
import * as React from 'react';
import PageLoader from '../../components/PageLoader';
import { FlightReport, IFlightReport } from '../../model/IFlightReport';
import { FlightReportCRUD } from '../../operations/FlightReportCRUD';
import { FlightReportService } from '../../services/FlightReportService';

interface IFlightReportFlaggedProps {
    iFlightReport:IFlightReport,
    onFlagChange: (value: FlightReport) => void;
}

const FlightReportFlagged: React.FunctionComponent<IFlightReportFlaggedProps> = ({iFlightReport,onFlagChange,...props}) => {
   //Loader
  const [loading, setIsLoading] = React.useState(false);
  const theme = iFlightReport.isFlagged ? "filled" : "outline";

  //on property values change
  async function onFlagStatusChange(record:IFlightReport, isFlagged:boolean) {
    setIsLoading(true);
    //Get by ID
    FlightReportService.getById(record.flightReportId)
      .then(async (response: any) => {
        response.data.data.isFlagged = isFlagged;
        var objFlightReport = response.data.data;
        
        //Update flag status
        await FlightReportCRUD.createAndUpdate(objFlightReport);
        //Update to parent component "FlightReportTitle"
        onFlagChange(objFlightReport);
        
        setIsLoading(false);
      })
      .catch((e: Error) => {
        console.log(e);
        setIsLoading(false);
      });
  }

  return(
    <>
      <PageLoader visible={loading} />

        <GoAButton size="compact" type="tertiary" onClick={() => {
            onFlagStatusChange(iFlightReport,iFlightReport.isFlagged ? false : true);
          }}>
            <GoAIcon type="flag" theme={ theme } />
          </GoAButton>
    </>
  );
};

export default FlightReportFlagged;
