import { GoATable } from '@abgov/react-components';
import * as React from 'react';
import { IFlyingHours } from '../../model/IFlyingHours';
import { IPaginationResult } from '../../model/search/IPaginationResult';

interface IViewExpenseDetailsProps {
  expenseDetails: IPaginationResult<IFlyingHours> | any | undefined;
}

const ViewExpenseDetails: React.FunctionComponent<IViewExpenseDetailsProps> = ({ expenseDetails, ...props }) => {
  console.log(expenseDetails);
  return (

    <>
      <div>
        <GoATable width="100%">
          <thead>
            <tr style={{ position: "sticky", top: 0 }}>
              <th className="width15">Fire number</th>
              <th className="width15">Activity</th>
              <th className="width15">Rate type</th>
              <th className="width8">No.of units</th>
              <th className="width10">Unit</th>
              <th>Rate per unit</th>
              <th>Cost</th>
              <th className="width8">Internal order</th>
              <th className="width8">Cost center</th>
              <th className="width8">Fund</th>
              <th className="width8">Account</th>
            </tr>
          </thead>

          <tbody>
            {
              expenseDetails?.map(
                (record: any, idx: number) => (
                  <tr key={idx}>
                    <td>
                      {record.fireNumber}
                    </td>
                    <td>
                      {record.costingActivityCode}
                    </td>
                    <td>
                      {record?.rateTypeEntity ? record?.rateTypeEntity?.type : ""}
                    </td>
                    <td>{record.noOfUnits}</td>
                    <td>
                      {record?.rateUnitEntity ? record?.rateUnitEntity?.type : ""}
                    </td>
                    <td>{record.ratePerUnit}</td>
                    <td>
                      {"$" + record.cost}
                    </td>
                    <td>{"$" + record.internalOrder}</td>
                    <td>{"$" + record.costCenter}</td>
                    <td>{record.fund}</td>
                    <td>{record.account}</td>
                  </tr>
                )
              )
            }

          </tbody>
        </GoATable>
      </div>
    </>
  );
};

export default ViewExpenseDetails;
