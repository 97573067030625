import { httpAviationReporting } from "../../http-common";
import { IFireNumber } from "../../model/domainServices/IFireNumber";
import { IPaginationResult } from "../../model/search/IPaginationResult";
import { ISearch } from "../../model/search/ISearch";

//Search
const getSearch = async (objISearch: ISearch) => {
    const params = JSON.stringify({
      search: objISearch.search,
      // sortBy: objISearch.sortBy,
      // sortOrder: objISearch.sortOrder,
      // filterBy: {
      //   columnName: "",
      //   columnValue: ""
      // },
      paginationInfo: {
        perPage: objISearch.pagination.perPage,
        page: objISearch.pagination.page,
      },
    });
  
    const data = await httpAviationReporting.post<IPaginationResult<IFireNumber>>(
      "/fire-number/get",
      params,
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("api_token")}`,
        },
      }
    );
    
    return data;
  };

  const getById = async (id: string) => {
    const data = await httpAviationReporting.post<IFireNumber>(
      `/fire-number/getById/${id}`,
      "",
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("api_token")}`,
        },
      }
    );
  
    return data;
  };
  
  export const FireNumberService = {
    getSearch,
    getById,
  };

  export const bindFireNumberDropdown = (fireNumberPaginationResult:IPaginationResult<IFireNumber>): {label:string,value:number}[] => {
    const options = [];
    
    if(fireNumberPaginationResult !== undefined)
    {
      for (let i = 0; i < fireNumberPaginationResult.data.length; i++) {
        let obj = {label: fireNumberPaginationResult.data[i].fireNumber, value: fireNumberPaginationResult.data[i].id};
        options.push(obj);
      }
    }

    return options;
  }