/* eslint-disable @typescript-eslint/no-unused-vars */
import {
    GoABlock,
    GoAButton,
    GoADropdown,
    GoADropdownItem,
    GoAFormItem,
    GoAIcon,
    GoAInput,
    GoAInputDate,
    GoAPagination,
    GoASpacer,
    GoATable,
    GoATableSortHeader,
  } from "@abgov/react-components";
  import moment from "moment";
  import * as React from "react";
  import { useNavigate } from "react-router-dom";
  import PageHeader from "../../components/PageHeader";
  import PageLoader from "../../components/PageLoader";
  import { ICorporateRegion, IForestArea } from "../../model/ICorporateRegion";
  import { IFlightReport } from "../../model/IFlightReport";
  import { IFilter } from "../../model/search/IFilter";
  import { IPagination } from "../../model/search/IPagination";
  import { IPaginationResult } from "../../model/search/IPaginationResult";
  import { ISearch } from "../../model/search/ISearch";
  import { bindMutltiSelectDropdown, CorporateRegionDomainService, getCorporateRegion } from "../../services/CorporateRegionDomainService";
  import { FlightReportService } from "../../services/FlightReportService";
  import { addDays, getDateWithMonthOffset } from "../../utils/dateHandler";
  import { MultiSelect } from "react-multi-select-component";
  import { useSetRecoilState } from "recoil";
  import { corporateRegionState } from "../../state/DomainService.state";
  import FlyingHoursState from "../../components/stateComponent/FlyingHoursState";
  import { FlightReportCRUD } from "../../operations/FlightReportCRUD";
  
  interface IFlightReportRequireActionListProps {
    flightReportResults:IPaginationResult<IFlightReport> | undefined,
  }
  
  const FlightReportRequireActionList: React.FunctionComponent<IFlightReportRequireActionListProps> = ({flightReportResults,...props}) => {
    // Navigation
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const navigate = useNavigate();
  
  const [requireActionPaginationResults, setRequireActionPaginationResults] =
    React.useState<IPaginationResult<IFlightReport>>();

  //#region "Corporate region"
  const [corporateRegionPaginationResult, setCorporateRegionPaginationResult] = React.useState<IPaginationResult<ICorporateRegion>>();
  //const [domainServiceResult, setDomainServiceResult] = React.useState<IDomainService>();
  
  //Loader
  const [loading, setIsLoading] = React.useState(true);
  const [searchValue, setSearchValue] = React.useState("");
  
  //Pagination
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [pageUsers, setPageFlightReports] = React.useState<any>([]);
  // page number
  const [page, setPage] = React.useState(1);
  //count per page
  const [perPage, setPerPage] = React.useState(10);
  const [previousSelectedPerPage, setPreviousSelectedPerPage] = React.useState(10);

    //Sorting
  const [sortCol, setSortCol] = React.useState("");
  const [sortDir, setSortDir] = React.useState(-1);
  const [isSorting, setIsSorting] = React.useState(false);

  //const sortPaginationResults = React.useMemo(() => sortedPaginationResults(), [sortedPaginationResults]);

  //Filter date
  const [startDate, setStartDate] = React.useState("");
  const [endDate, setEndDate] = React.useState("");
  const [endDateError, setEndDateError] = React.useState(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [maxDate, setMaxDate] = React.useState(getDateWithMonthOffset(1));

  const [minEndDate, setMinEndDate] = React.useState(new Date(1950, 1, 1));
  const [showEndDate, setShowEndDate] = React.useState(true);

  const[searchInputKeyPress,setSearchInputKeyPress] = React.useState("");

  const [forestAreaSelected, setForestAreaSelected] = React.useState([]);
  const [forestAreaOptions, setForestAreaOptions] = React.useState([]);
  //Recoil state
  const setcorporateRegionState = useSetRecoilState(corporateRegionState);

    //onKeyPress handler function
    const keyPressHandler = (event: React.KeyboardEvent<HTMLInputElement> | any) => {
      //Do something you like with "event"
        setSearchInputKeyPress("");
        setSearchInputKeyPress(event.key);
    };

    React.useEffect(()=>{

      const element = document.getElementsByName('flightReportSearch');
      element[0].addEventListener('keypress', keyPressHandler);
  
      if(sessionStorage.getItem("api_token") == null || sessionStorage.getItem("api_token") === undefined || sessionStorage.getItem("api_token") === ""
        || sessionStorage.getItem("domainService_token") == null || sessionStorage.getItem("domainService_token") === undefined || sessionStorage.getItem("domainService_token") === "")
      {
        setTimeout(()=>{getCorporateRegions();},200);
        setTimeout(()=>{onRefreshFlightReport();},200);
      }
      else{
        getCorporateRegions();
        onRefreshFlightReport();
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])


  React.useEffect(() => {
    onRefreshFlightReport();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, perPage, endDate, forestAreaSelected, searchValue, sortCol, sortDir]);


  function onRefreshFlightReport(){
    getFlightReports();
    setPageFlightReports(requireActionPaginationResults?.data.slice(0, perPage));
  };

  //Get flight reports data
  const getFlightReports = async () => {
    setIsLoading(true);

    let strSearchValue = searchValue.toLowerCase();
    let sortOrder = sortDir === -1 ? "ASC" : "DESC";
    let startDt = startDate === null || startDate === "" ? null : moment(startDate).format("yyyy-MM-DD");
    let endDt = endDate === null || startDate === "" ? null : moment(endDate).format("yyyy-MM-DD");
    let objForestArea: IForestArea = {
      corporateRegionId: forestAreaSelected != null || forestAreaSelected !== undefined ? forestAreaSelected.map((forestArea)=>{ return forestArea["value"] as string}) : []
    };


    let objIPagination: IPagination = {
      perPage: perPage,
      page: page,
    };

    let objIFilter: IFilter = {
      columnName: "status",
      columnValue: "In progress",
      reportDateFrom:startDt,
      reportDateTo:endDt,
      corporateRegion:objForestArea
    };

    let objISearch: ISearch = {
      search: strSearchValue,
      sortBy: sortCol,
      sortOrder: sortOrder,
      filterBy: objIFilter,
      pagination: objIPagination,
    };

 
      FlightReportService.getSearch(objISearch)
        .then((response: any) => {
          
          setRequireActionPaginationResults(p => {
            return sortingData(response.data);
            });
          

          setIsLoading(false);
        })
        .catch((e: Error) => {
          setIsLoading(false);
          sessionStorage.setItem("api_token", "");
          navigate("/");
        });
    
  }

  function sortingData(paginationResult: IPaginationResult<IFlightReport>) {
    const _flightReports = [...(paginationResult.data || [])];
    _flightReports.sort((a, b) => {
      return (
        (a[sortCol as keyof typeof requireActionPaginationResults] >
        b[sortCol as keyof typeof requireActionPaginationResults]
          ? -1
          : 1) * sortDir
      );
    });

    paginationResult.data = _flightReports;
    //Only for sorting applied reset pagination
    if(isSorting)
    {
      setPerPage(previousSelectedPerPage | 10);
      setPage(1);
      setPageFlightReports(requireActionPaginationResults?.data.slice(0, perPage));

      //Reset value
      setIsSorting(false);
      setPreviousSelectedPerPage(perPage);
    }
    return paginationResult;
  }

  function searchFlightReports(searchParamValue: string) {
    
    if(searchInputKeyPress === "Enter")
    {
      return;
    }

    if (searchParamValue.length >= 3 && searchValue !== searchParamValue) {
      setIsLoading(true);
      setPage(1);
      setSearchValue(searchParamValue);
     } 
    else if (searchParamValue.length === 0 && searchValue !== "") {
      setIsLoading(true);
      setSearchValue("");
    }
  }

  //Pagination change page
  function changePage(newPage: any) {
    setIsLoading(true);
    const offset = (newPage - 1) * perPage;
    const _flightReports = requireActionPaginationResults?.data.slice(
      offset,
      offset + perPage
    );

    setPerPage(perPage);
    setPage(newPage);
    setPageFlightReports(_flightReports);
  }

  function changePerPage(name: any, value: any) {
    setIsLoading(true);
    const newPerPage = parseInt(value, 10);
    const offset = (page - 1) * newPerPage;

    const _flightReports = requireActionPaginationResults?.data.slice(
      offset,
      offset + newPerPage
    );

    setPageFlightReports(_flightReports);
    setSearchValue("");
    setPerPage(p => {
      return newPerPage;
      });
    setPage(page);
  }

  function sortData(sortBy: string, sortDir: number) {
    setSortCol(sortBy);
    setSortDir(sortDir);

    setPreviousSelectedPerPage(perPage);
    setPerPage(requireActionPaginationResults?.paginationInfo.total || 30);
    setPage(1);
    setIsSorting(true);
  }

  //Corporate region
  function getCorporateRegions()
  {
    let objIPagination: IPagination = {
      perPage: 20,
      page: 1,
    };

    let objIFilter: IFilter = {
      columnName: "CRT_ID",
      columnValue: "4"
    };

    let objISearch: ISearch = {
      search: "",
      filterBy: objIFilter,
      pagination: objIPagination,
    };

      CorporateRegionDomainService.getSearch(objISearch)
        .then((response: any) => {
          //set corporate region recoil state 
          setcorporateRegionState(response.data);
          setCorporateRegionPaginationResult(response.data);
          const forestAreaResult = bindMutltiSelectDropdown(response.data) as [];
          setForestAreaOptions(forestAreaResult);
        })
        .catch((e: Error) => {
          console.log("Error",e);
        });
      
  }

  //#endregion
function onClickFlightReport(flightReportId?:number)
{
    if(flightReportId)
    {
    //navigate(`/flightReportDetail/${flightReportId}`);
    navigate(`/flightReportDetail/${flightReportId}`,{state:corporateRegionPaginationResult});
    }
    else
    {
      navigate(`/flightReportDetail/new`,{state:corporateRegionPaginationResult});
    }
}

//on property values change
async function onFlagStatusChange(record:IFlightReport, isFlagged:boolean) {
  setIsLoading(true);
  //Get by ID
  FlightReportService.getById(record.flightReportId)
    .then(async (response: any) => {
      response.data.data.isFlagged = isFlagged;
      var objFlightReport = response.data.data;
      
      //Update flag status
      await FlightReportCRUD.createAndUpdate(objFlightReport);
      
      if(requireActionPaginationResults?.data)
      {
        // 1. Find the flightReport with the provided id
        const currentPaginationResultsIndex = requireActionPaginationResults?.data.findIndex((value) => value.flightReportId === objFlightReport.flightReportId);
        // 2. Mark the flightReport as complete
        const updatedTodo = {...requireActionPaginationResults?.data[currentPaginationResultsIndex], isFlagged: isFlagged};

        // 3. Update the flightReport list with the updated flightReport
        const newPaginationResults = [
          ...requireActionPaginationResults?.data.slice(0, currentPaginationResultsIndex),
          updatedTodo,
          ...requireActionPaginationResults?.data.slice(currentPaginationResultsIndex + 1)
        ];
        requireActionPaginationResults.data = newPaginationResults;
        setPage(1);
      }

      setIsLoading(false);
    })
    .catch((e: Error) => {
      console.log(e);
      setIsLoading(false);
    });

}
  
    return (
      <>
        <PageLoader visible={loading} />

        <div>
        <div className="divTable">
          <GoATable onSort={sortData} width="100%">
            <thead>
              <tr>
                <th>
                  <GoATableSortHeader name="isFlagged">
                  </GoATableSortHeader >
                </th>
                <th>Forest Area</th>
                <th>
                  <GoATableSortHeader name="flightReportDate">
                    Flight Date
                  </GoATableSortHeader >
                </th>
                <th>AC Registration</th>
                <th>Aircraft Vendor</th>
                <th>
                  <GoATableSortHeader name="flightReportId">
                    Flight Report #
                  </GoATableSortHeader>
                </th>
                <th>
                  <GoATableSortHeader name="ao02Number">
                    AO02 #
                  </GoATableSortHeader>
                </th>
                <th>Total flying hours</th>
                <th>
                  <GoATableSortHeader name="status">
                    Status
                  </GoATableSortHeader>
                </th>
              </tr>
            </thead>

            <tbody style={{ position: "sticky", top: 0 }}>
              {requireActionPaginationResults?.data && requireActionPaginationResults.data.length > 0 ? (
                requireActionPaginationResults.data.map((record:any, index:any) => (
                  <tr key={record.flightReportId}>
                    {/* <td>
                      <FlightReportFlagged iFlightReport={record} onChange={onFlaggedChange}/>
                    </td> */}

                    <td>{record.isFlagged ? 
                        <GoAButton size="compact" type="tertiary" onClick={() => {
                            onFlagStatusChange(record,false);
                          }}>
                          <GoAIcon type="flag" theme="filled" />
                        </GoAButton> : 
                        <GoAButton size="compact" type="tertiary" onClick={() => {
                            onFlagStatusChange(record,true);
                          }}>
                          <GoAIcon type="flag" />
                        </GoAButton>}
                    </td>
                    <td>{corporateRegionPaginationResult?.data && corporateRegionPaginationResult.data.length > 0 ? 
                        (getCorporateRegion(corporateRegionPaginationResult, record?.corporateRegionId as string))?.name.toString()
                        : ""}</td>
                    <td>
                      {moment(record.flightReportDate).format("yyyy-MM-DD")}
                    </td>
                    <td></td>
                    <td></td>
                    {/* <td style={{ padding: "0 1rem 0 1rem" }}></td> */}
                    <td>
                      <GoAButton
                        {...{ style: '"padding: 0 10px 0 10px;height: 90px;"' }}
                        size="compact"
                        type="tertiary"
                        onClick={() => onClickFlightReport(record?.flightReportId)}
                      >
                        {record.flightReportId}
                      </GoAButton>
                    </td>
                    <td>{record.ao02Number}</td>
                    <td></td>
                    <td>{record.status}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={6} className="centertext">
                    No data avaliable
                  </td>
                </tr>
              )}
            </tbody>
          </GoATable>
        </div>

        <div className={requireActionPaginationResults?.data && requireActionPaginationResults?.data.length > 0 ? "visible pagination" : "not-visible pagination"}>
          <GoABlock alignment="center">
            <GoABlock mb="m" alignment="center" gap="m">
              <span style={{ whiteSpace: "nowrap" }}>Show</span>
              <div className="dropdown-list">
              <GoADropdown name="selPerPage" onChange={changePerPage} value="10" width="8ch">
                <GoADropdownItem value="10"></GoADropdownItem>
                <GoADropdownItem value="20"></GoADropdownItem>
                <GoADropdownItem value="30"></GoADropdownItem>
              </GoADropdown>
              </div>
              <span style={{ whiteSpace: "nowrap" }}>
                of {requireActionPaginationResults?.paginationInfo.total} items
              </span>
            </GoABlock>
            <GoASpacer hSpacing="fill" />
            <GoAPagination
              itemCount={requireActionPaginationResults?.paginationInfo.total || 10}
              perPageCount={perPage}
              pageNumber={page}
              onChange={changePage}
            />
          </GoABlock>
        </div>
        </div>
      </>
    );
  };
  
  export default FlightReportRequireActionList;
  