import moment from 'moment';
import * as React from 'react';
import { useSetRecoilState } from 'recoil';
import { IFilter } from '../../model/search/IFilter';
import { IPagination } from '../../model/search/IPagination';
import { ISearch } from '../../model/search/ISearch';
import { CostingActivityService } from '../../services/domainServices/CostingActivityService';
import { CrewTypeService } from '../../services/domainServices/CrewTypeService';
import { RateTypeService } from '../../services/domainServices/RateTypeService';
import { RateUnitService } from '../../services/domainServices/RateUnitService';
import { costingActivityState, crewTypeState, rateTypeState, rateUnitState } from '../../state/FlyingHours.state';

interface IFlyingHoursStateProps {
}

const FlyingHoursState: React.FunctionComponent<IFlyingHoursStateProps> = (props) => {

  //Recoil state
  const setCrewTypeState = useSetRecoilState(crewTypeState);
  const setCostingActivityState = useSetRecoilState(costingActivityState);
  const setRateTypeState = useSetRecoilState(rateTypeState);
  const setRateUnitState = useSetRecoilState(rateUnitState);

  React.useEffect(() => {
    async function bindFlyingHoursDropdown()
    {
      await getAllCrewType();
      await getAllCostingActivity();
      await getAllRateType();
      await getAllRateUnit();

    } 
    bindFlyingHoursDropdown();
   //await bindFlyingHoursDropdown();
  });

  

  async function getAllCrewType()
  {
    let objIPagination: IPagination = {
      perPage: 25,
      page: 1,
    };

    let objIFilter: IFilter = {
      columnName: "",
      columnValue: "",
    };

    let objISearch: ISearch = {
      search: "",
      sortBy: "",
      sortOrder: "",
      filterBy: objIFilter,
      pagination: objIPagination,
    };

    await CrewTypeService.getSearch(objISearch)
      .then((response: any) => {
        setCrewTypeState(response.data);
      })
      .catch((e: Error) => {
        console.log("Error", e);
      });

  }

  async function getAllCostingActivity()
  {
    let objIPagination: IPagination = {
      perPage: 20,
      page: 1,
    };

    let objISearch: ISearch = {
      search: "",
      pagination: objIPagination,
    };
    
    await CostingActivityService.getSearch(objISearch)
      .then((response: any) => {
        setCostingActivityState(response.data);
      })
      .catch((e: Error) => {
        console.log("Error",e);
      });
        
  }

  async function getAllRateType()
  {
    let objIPagination: IPagination = {
      perPage: 20,
      page: 1,
    };

    let objIFilter: IFilter = {
      negotiated: true,
      columnName: "activeDate",
      columnValue: moment(new Date()).format("yyyy-MM-DD"),
    };

    let objISearch: ISearch = {
      search: "",
      sortBy: "",
      sortOrder: "",
      filterBy: objIFilter,
      pagination: objIPagination,
    };

    await RateTypeService.getSearch(objISearch)
      .then((response: any) => {
        setRateTypeState(response.data);
      })
      .catch((e: Error) => {
        console.log("Error",e);
      });
  }

  async function getAllRateUnit()
  {
    let objIPagination: IPagination = {
      perPage: 20,
      page: 1,
    };

    let objIFilter: IFilter = {
      columnName: "activeDate",
      columnValue: moment(new Date()).format("yyyy-MM-DD"),
    };

    let objISearch: ISearch = {
      search: "",
      sortBy: "",
      sortOrder: "",
      filterBy: objIFilter,
      pagination: objIPagination,
    };

    await RateUnitService.getSearch(objISearch)
      .then((response: any) => {
        setRateUnitState(response.data);
      })
      .catch((e: Error) => {
        console.log("Error",e);
      });
        
  }

  return(
    <>
      
    </>
  );
};

export default FlyingHoursState;
