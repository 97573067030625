import { GoAFormItem, GoARadioGroup, GoARadioItem } from '@abgov/react-components';
import * as React from 'react';
import { FlightExpenses, FlightFuelUsage } from '../../model/IFlightExpense';

interface IEditDoubleCrewProps {
  flightExpenses: FlightExpenses | undefined;
  onChange: (value: FlightExpenses) => void;
}

const EditDoubleCrew: React.FunctionComponent<IEditDoubleCrewProps> = ({ flightExpenses, onChange, ...props }) => {
  const [formValues, setFormValues] = React.useState(new FlightExpenses(flightExpenses));
  function onPropertyChange(value: Partial<FlightFuelUsage>) {
  
    let newValue = new FlightFuelUsage({ ...formValues?.flightFuelUsage, ...value });

    let newFlightExpense = new FlightExpenses({ ...formValues, flightFuelUsage: newValue });

    setFormValues(newFlightExpense);

    //send on change to parent component
    onChange(newFlightExpense);

    //validate(elementName, "change", ruleCode);
  }
  return (
    <>

<div className="row accordion flight-crew-edit">
        <div>
          <div>
            <h3 className="insideHeader expense-section-header">Double Crew</h3>
          </div>
          <div className="content">
            <div className='width100 col-md-6'>
              <GoAFormItem label="">
              <GoARadioGroup name="doubleCrew" value={flightExpenses?.flightFuelUsage?.doubleCrew?'true':'false'}
              onChange={(_name, value) => {
                onPropertyChange({ doubleCrew: value==='true'? true :false });
              }}
              >
                <GoARadioItem value="true" label="Yes" name={''} />
                <GoARadioItem value="false" label="No" name={''} />
              </GoARadioGroup>
              </GoAFormItem>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditDoubleCrew;

