import { GoAFormItem, GoAInputText } from '@abgov/react-components';
import * as React from 'react';
import { FlightExpenses, FlightFuelUsage } from '../../model/IFlightExpense';
import Select from "react-select";

interface IEditFuelProps {
  flightExpenses: FlightExpenses | undefined;
  onChange: (value: FlightExpenses) => void;
}

const EditFuel: React.FunctionComponent<IEditFuelProps> = ({ flightExpenses, onChange, ...props }) => {
  const [formValues, setFormValues] = React.useState(new FlightExpenses(flightExpenses));
  const providerOptions = [
    { label: 'GOA Provided', value: 'GOA Provided' },
    { label: 'Vendor Provided', value: 'Vendor Provided' },
    { label: 'Not Applicable', value: 'Not Applicable' },
  ];


  //on property values change
  function onPropertyChange(value: Partial<FlightFuelUsage>) {
    let newValue = new FlightFuelUsage({ ...formValues?.flightFuelUsage, ...value });

    let newFlightExpense = new FlightExpenses({ ...formValues, flightFuelUsage: newValue });
    newFlightExpense.flightFuelUsage.updateTimestamp=null;
    setFormValues(newFlightExpense);

    //send on change to parent component
    onChange(newFlightExpense);

    //validate(elementName, "change", ruleCode);
  }

  return (
    <>
      <div className="row accordion fuel-edit">
        <div>
          <h3 className="insideHeader expense-section-header">Fuel</h3>
        </div>
        <div className="content">
          <div className='width50 col-md-6'>
            <GoAFormItem label="Provider">
              <Select
                name="providers"
                options={providerOptions}
                className="width50"
                value={providerOptions.find(t => t.value === formValues.flightFuelUsage?.provider) || null}
                onChange={(value) => {
                  onPropertyChange({ provider: value?.value });
                }}
              />
            </GoAFormItem>
          </div>
          <div className='width50 col-md-6'>
            <GoAFormItem label="Litres">
              <GoAInputText
                name="litre"
                disabled={flightExpenses?.flightFuelUsage?.provider === "GOA provided"}
                value={formValues?.flightFuelUsage?.litres}
                onChange={(name, value) => {
                  onPropertyChange({ litres: value });
                }}
              />
            </GoAFormItem>
          </div>
        </div>

      </div>
    </>
  );
};

export default EditFuel;
