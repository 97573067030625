import {
  GoAButton,
  GoAInputNumber,
  GoAInputText,
  GoATable,
} from "@abgov/react-components";
import moment from "moment";
import * as React from "react";
import Select from "react-select";
import { useRecoilValue } from "recoil";
import PageLoader from "../../components/PageLoader";
import { IContractRates } from "../../model/HiringContract/IContractRates";
import { IFlightReport } from "../../model/IFlightReport";
import { FlyingHours } from "../../model/IFlyingHours";
import { IPaginationResult } from "../../model/search/IPaginationResult";
import { FlyingDetailCRUD } from "../../operations/FlyingDetailCRUD";
import { getContractRates } from "../../services/ContractRateService";
import { bindCostingActivityDropdown, CostingActivityService } from "../../services/domainServices/CostingActivityService";
import { bindCrewTypeDropdown, getCrewType } from "../../services/domainServices/CrewTypeService";
import { FireNumberService } from "../../services/domainServices/FireNumberService";
import { bindRateTypeDropdown, RateTypeService } from "../../services/domainServices/RateTypeService";
import { bindRateUnitDropdown, RateUnitService } from "../../services/domainServices/RateUnitService";
import { crewTypeState } from "../../state/FlyingHours.state";
import { validate } from "../../utils/commonMethods";

interface IEditFlyingDetailProps {
  ruleCode:string,
  flightReport:IFlightReport,
  arrFlyingHours: FlyingHours[];
  contractRatesPaginationResult:IPaginationResult<IContractRates> | undefined;
  fireNumberOptions:{label:string,value:number}[];
  crewTypeOptions:{label:string,value:string}[];
  costingActivityOptions:{label:string,code:string,value:string}[];
  rateTypeOptions:{label:string,value:string}[];
  rateUnitOptions:{label:string,value:string}[];
  onChange: (value: Array<FlyingHours>) => void;
  onChildDataValidityChange: any;
}

const EditFlyingDetail: React.FunctionComponent<IEditFlyingDetailProps> = ({flightReport,arrFlyingHours,contractRatesPaginationResult,
  fireNumberOptions,crewTypeOptions,costingActivityOptions,rateTypeOptions,rateUnitOptions,
    onChange,onChildDataValidityChange, ruleCode,...props
}) => {
  //Loader
  const [loading, setIsLoading] = React.useState(false);

  const [rowsFlyingHours, setRowsFlyingHours] = React.useState<FlyingHours[]>([]);

    //Recoil state
    const crewTypeStateResult = useRecoilValue(crewTypeState);
    //const rateUnitStateResult = useRecoilValue(rateUnitState);

  React.useEffect(()=>{
    setIsLoading(true);

    if(arrFlyingHours)
    {
      setRowsFlyingHours(p => {return [...arrFlyingHours]});
    }

    setIsLoading(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[arrFlyingHours])

  //Fire number
  // async function getFireNumbers(idx:number = -1, searchValue:string = "")
  // {
  //   try {
  //     var fireNumberResponse: any = await FlyingDetailCRUD.getFireNumbers(searchValue);
  //     if (fireNumberResponse.status)
  //     {
  //       const fireNumberResult = bindFireNumberDropdown(fireNumberResponse);

  //       if(idx !== -1 && rowsFlyingHours)
  //       {
  //         onDrodownSearch(idx,{fireNumberList:fireNumberResult});
  //       }
  //     }
  //   }
  //   catch (Error) {
  //     console.log("Error", Error);
  //   }
  // }

  //Crew type
  async function getCrewTypes(idx:number = -1, searchValue:string = "")
  {
    try {
      var crewTypeResponse: any = await FlyingDetailCRUD.getCrewTypes(searchValue);
      if (crewTypeResponse.status)
      {
        const crewTypeResult = bindCrewTypeDropdown(crewTypeResponse.data);

        if(idx !== -1 && rowsFlyingHours)
        {
          onDrodownSearch(idx,{crewTypeList:crewTypeResult});
        }
      }
    }
    catch (Error) {
      console.log("Error", Error);
    }
  }

  //Costing activity
  async function getCostingActivities(idx:number = -1, searchValue:string = ""){
    try {
      var costingActivityResponse: any = await FlyingDetailCRUD.getCostingActivities(searchValue);
      if (costingActivityResponse.status)
      {
        const costingActivityResult = bindCostingActivityDropdown(costingActivityResponse.data);
        
        if(idx !== -1 && rowsFlyingHours)
        {
          onDrodownSearch(idx,{costingActivityList:costingActivityResult});
        }
      }
    }
    catch (Error) {
      console.log("Error", Error);
    }
}

  //Rate type
  async function getRateTypes(idx:number = -1, searchValue:string = "")
  {
    try {
      var rateTypeResponse: any = await FlyingDetailCRUD.getRateTypes(searchValue);
      if (rateTypeResponse.status)
      {
        const rateTypeResult = bindRateTypeDropdown(rateTypeResponse.data);
        
        if(idx !== -1 && rowsFlyingHours)
        {
          onDrodownSearch(idx,{rateTypeList:rateTypeResult});
        }
      }
    }
    catch (Error) {
      console.log("Error", Error);
    }
  }

  //Rate unit
  async function getRateUnits(idx:number = -1, searchValue:string = "")
  {
    try {
      var rateUnitResponse: any = await FlyingDetailCRUD.getRateUnits(searchValue);
      if (rateUnitResponse.status)
      {
        const rateUnitResult = bindRateUnitDropdown(rateUnitResponse.data);
        
        if(idx !== -1 && rowsFlyingHours)
        {
          onDrodownSearch(idx,{rateUnitList:rateUnitResult});
        }
      }
    }
    catch (Error) {
      console.log("Error", Error);
    }
  }

  async function handleAddRow(){
    setIsLoading(true);

    const flyingHour = new FlyingHours();
    flyingHour.flightReportId = flightReport.flightReportId;
    flyingHour.createTimestamp = moment(new Date()).format("yyyy-MM-DD");
    flyingHour.createUserId = sessionStorage.getItem("username")?.toString();
    flyingHour.updateTimestamp = null;
    flyingHour.expenseType ="FlyingHours";

    //Dropdown
    flyingHour.fireNumberList = fireNumberOptions;
    flyingHour.crewTypeList = crewTypeOptions;
    flyingHour.costingActivityList = costingActivityOptions;
    flyingHour.rateTypeList = rateTypeOptions;
    flyingHour.rateUnitList = rateUnitOptions;

    if(contractRatesPaginationResult)
    {
      var rateTypeId = contractRatesPaginationResult.data ? contractRatesPaginationResult.data[0].crtyId : 0;
      var rateUnitId = contractRatesPaginationResult.data ? contractRatesPaginationResult.data[0].cruId : 0;
      if(rateTypeId)
      {
        var rateType : any = await RateTypeService.getByOracleId(rateTypeId);
        var contractRates = getContractRates(contractRatesPaginationResult, rateTypeId);

        flyingHour.rateTypeId = rateType ? rateType.data.rateTypeId : undefined;
        flyingHour.rateTypeEntity = rateType.data;
        flyingHour.ratePerUnit = contractRates ? contractRates?.ratePerUnit : 0;
      }

      if(rateUnitId)
      {
        var rateUnit : any = await RateUnitService.getByOracleId(rateUnitId);

        flyingHour.rateUnitId = rateUnit ? rateUnit?.data?.rateUnitId : undefined;
        flyingHour.rateUnitEntity = rateUnit?.data;
      }
    }
     
    setRowsFlyingHours(p => {return [...rowsFlyingHours,flyingHour]});

    setIsLoading(false);
  };

  function onDrodownSearch(idx:number,value: Partial<FlyingHours>)
  {
    let newValue = new FlyingHours(rowsFlyingHours[idx]);

    newValue =  new FlyingHours({...newValue, ...value});
    let rows = [...rowsFlyingHours];
    rows[idx] = newValue;

    setRowsFlyingHours(p=> {return rows});
  }

  //on property values change
  function onPropertyChange(idx:number,value: Partial<FlyingHours>) {
    let newValue = new FlyingHours(rowsFlyingHours[idx]);

    if(newValue.flyingHoursId !== "00000000-0000-0000-0000-000000000000")
    {
      newValue.updateTimestamp = moment(new Date()).format("yyyy-MM-DD");
      newValue.updateUserId = sessionStorage.getItem("username")?.toString();
    }

    newValue =  new FlyingHours({...newValue, ...value});
    let rows = [...rowsFlyingHours];
    rows[idx] = newValue;
    setRowsFlyingHours(p=> {return rows});
    
    //send on change to parent component "FlightReportSummary"
    onChange(rows);
  }

  return (
    <>
    <PageLoader visible={loading} />
    
      <div className="white-background">
            <GoATable width="100%">
              <thead>
                <tr>
                  <th className="width10">Fire number</th>
                  <th className="width15">Crew type on board</th>
                  <th className="width15">Activity</th>
                  <th className="width8">Rate type</th>
                  <th className="width8">No.of units</th>
                  <th className="width10">Unit</th>
                  <th className="width8">Rate per unit</th>
                  <th>Cost</th>
                  <th className="width8">Internal order</th>
                  <th className="width8">Cost centre</th>
                  <th className="width8">Fund</th>
                  <th className="width8">Account</th>
                </tr>
              </thead>

              <tbody style={{ position: "sticky", top: 0 }}>
                  {rowsFlyingHours ? 
                    rowsFlyingHours.map((_record: any, idx: any) => (
                      <tr key={idx}>
                      <td>
                        <Select
                          name={"selFireNmbers"+idx}
                          options={_record?.fireNumberList}
                          placeholder="--Select--"
                          className="width100"
                          //value={fireNumberOptions.find(t => t.value === _record?.fireNumberId) }
                          value={fireNumberOptions.find((t: { value: any; }) => t.value === _record?.fireNumberId) }
                          onChange={async (value:any) =>{
                            var fireNumber : any = await FireNumberService.getById(value.value);
                            onPropertyChange(idx,{fireNumberId:value.value,fireNumber:value.label,fireNumberEntity: fireNumber?.data?.data});
                          }}
                          isSearchable={true}
                        />
                      </td>
                      <td>
                        <Select
                          name={"selCrewTypeOnBoard"+idx}
                          options={_record?.crewTypeList}
                          placeholder="--Select--"
                          className="width100"
                          value={crewTypeOptions.find((t: { value: any; }) => t.value === _record?.crewTypeId) }
                          onChange={async (value:any) =>{
                            var crewType : any = await getCrewType(crewTypeStateResult,value.value);
                            onPropertyChange(idx,{crewTypeId:value.value,crewTypeEntity:crewType})
                          }}
                          onInputChange={(value:any)=>{
                            if(value)
                              getCrewTypes(idx,value);
                          }}
                          isSearchable={true}
                        />
                      </td>
                       <td>
                        <Select
                          name= {"selCostingActivity" + idx}
                          options={_record?.costingActivityList}
                          placeholder="--Select--"
                          className="width100"
                          value={_record?.costingActivityList.find((t: { value: any; }) => t.value === _record?.costingActivityId)}
                          onChange={async (value:any) =>{
                            var costingActivity : any = await CostingActivityService.getById(value.value);
                            onPropertyChange(idx,{costingActivityId:value.value,costingActivityCode:value.code,costingActivityEntity:costingActivity.data});
                            onChildDataValidityChange(validate(ruleCode, "selCostingActivity" + idx, "onChange", value.label))
                          }}
                          onInputChange={(value:any)=>{
                            if(value)
                              getCostingActivities(idx,value);
                          }}
                          isSearchable={true}
                        />
                      </td>
                      <td>
                        <Select
                          name={"selRateType"+idx}
                          options={_record?.rateTypeList}
                          placeholder="--Select--"
                          className="width100"
                          value={_record?.rateTypeList.find((t: { value: any; }) => t.value === _record?.rateTypeId) }
                          onChange={async (value:any) =>{
                            if(value)
                            {
                              var rateType : any = await RateTypeService.getById(value.value);
                            
                              if(rateType && contractRatesPaginationResult)
                                var ratePerUnit = getContractRates(contractRatesPaginationResult,rateType?.data?.oracleId)
  
                              onPropertyChange(idx,{rateTypeId:value.value,rateTypeEntity:rateType.data,ratePerUnit:ratePerUnit?.ratePerUnit})
                            }
                          }}
                          onInputChange={(value:any)=>{
                            if(value)
                              getRateTypes(idx,value);
                          }}
                          isSearchable={true}
                        />
                      </td>
                      <td>
                       <GoAInputNumber
                          name={"txtNoOfUnits"+idx}
                          value={_record?.noOfUnits}
                          min={0}
                          max={99}
                          step={1}
                          width="100%"
                          onChange={(name, value) => {
                            onPropertyChange(idx,{noOfUnits:value});
                          }}
                        />
                      </td>
                      <td>
                        <Select
                          name={"selRateUnit"+idx}
                          options={_record?.rateUnitList}
                          placeholder="--Select--"
                          className="width100"
                          isDisabled={contractRatesPaginationResult && contractRatesPaginationResult.data.find(value => value.crtyId === _record?.rateTypeEntity?.oracleId) ? true : false}
                          value={_record?.rateUnitList.find((t: { value: any; }) => t.value === _record?.rateUnitId) }
                          onChange={async (value:any) =>{
                            var rateUnit : any = await RateUnitService.getById(value.value);
                            onPropertyChange(idx,{rateUnitId:value.value,rateUnitEntity:rateUnit.data})
                          }}
                          onInputChange={(value:any)=>{
                            if(value)
                              getRateUnits(idx,value);
                          }}
                          isSearchable={true}
                        />
                      </td>
                      <td>{_record?.ratePerUnit > 0 && contractRatesPaginationResult && contractRatesPaginationResult.data.find(value => value.crtyId === _record?.rateTypeEntity?.oracleId) ?  
                          "$"+_record?.ratePerUnit
                          : <GoAInputText
                          name={"txtRatePerUnit"+idx}
                          value={_record?.ratePerUnit ? _record?.ratePerUnit : ""}
                          width="100%"
                          maxLength={10}
                          onChange={(name, value) => {onPropertyChange(idx,{ratePerUnit: value})}}
                          /> }
                      </td>
                      <td>{"$"+_record?.cost}</td>
                      <td>
                        <GoAInputText
                          name={"txtInternalOrders"+idx}
                          value={_record?.internalOrder}
                          width="100%"
                          maxLength={20}
                          onChange={(name, value) => {onPropertyChange(idx,{internalOrder:value})}}
                        />
                      </td>
                      <td>
                        <GoAInputText
                          name={"txtCostCenter"+idx}
                          value={_record?.costCenter}
                          width="100%"
                          maxLength={20}
                          onChange={(name, value) => {onPropertyChange(idx,{costCenter:value})}}
                        />
                      </td>
                      <td>
                        <GoAInputText
                          name={"txtFund"+idx}
                          value={_record?.fund}
                          width="100%"
                          maxLength={20}
                          onChange={(name, value) => {onPropertyChange(idx,{fund:value})}}
                        />
                      </td>
                      <td>
                        <GoAInputText
                          name={"txtAccount"+idx}
                          value={_record?.account}
                          width="100%"
                          maxLength={20}
                          onChange={(name, value) => {onPropertyChange(idx,{account:value})}}
                        />
                      </td> 
                    </tr>
                  )) : 
                  <></>
                  }
                </tbody>
            </GoATable>
          </div>

          <div className="flying-hours">
            <GoAButton type="secondary" trailingIcon="add" 
              onClick={() => handleAddRow()}>
              Add new
            </GoAButton>
          </div>

    </>
  );
};

export default EditFlyingDetail;